<template>
  <div class="ui-box" :class="'ui-box-' + boxType">
    <div class="ui-box-title" :class="'title-color-' + color">
      <slot name="header">
        {{title}}
        <div v-if="subtitle !== ''" class="header-subtitle">
          {{subtitle}}
        </div>
      </slot>
    </div>
    <div class="p-2">
      <slot name="content"/>
    </div>
  </div>
</template>
<script>

import NoDragImage from "@/components/common/NoDragImage";
import {COLOR_DARK_BLUE} from "@/components/common/AppButton";
export default {
  name: 'UiBox',
  components: {NoDragImage},
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    boxType: {
      type: String,
      default: 'light',
    },
    color: {
      type: String,
      default: COLOR_DARK_BLUE,
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables.scss";

.ui-box {
  border-radius: $globalBorderRadius;
  border: $whiteBorder;
  background-color: $colorTransparentWhite;

  .ui-box-title {
    background-color: $colorUiDarkBlue;
    color: white;
    font-size: 22px;
    font-weight: 800;
    border-radius: $globalBorderRadius $globalBorderRadius 0 0;


    &.title-color-yellow {
      background-color: $colorYellow !important;
    }

    &.title-color-light-green {
      background-color: $colorLightGreen !important;
    }

    &.title-color-green {
      background-color: $colorGreen !important;
    }

    &.title-color-peach {
      background-color: $colorPeach !important;
    }

    &.title-color-pink {
      background-color: $colorPink !important;
    }

    &.title-color-red {
      background-color: $colorRed !important;
    }
  }

  &.ui-box-dark {
    background-color: #1F2D3F80 !important;
  }



  .header-subtitle {
    font-size: 18px;
    line-height: 1;
    padding-bottom: 9px;
  }

}
</style>
