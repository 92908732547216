<template>
  <div class="dev-panel-container">
    <div class="dev-panel">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DevPanel',
}
</script>

<style lang="scss" scoped>
.dev-panel-container {
  padding: 15px;
  margin-bottom: 20px;
  color: #333333;
  text-align: left;
}

.dev-panel {
  padding: 10px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
</style>
