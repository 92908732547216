const rebindMethods = ['log', 'warn', 'error', 'time', 'timeEnd'];

const console = window.console;

export function getConsole(name, color, backgroundColor = null) {
  let style;

  if (backgroundColor) {
    style = `background: ${backgroundColor}; color: ${color}`;
  } else {
    style = `color: ${color}`;
  }

  const newConsole = Object.create(console);

  rebindMethods.forEach((rebindMethod) => {
    newConsole[rebindMethod] = console[rebindMethod].bind(console, `%c[${name}]`, style);
  });

  return newConsole;
}

export function getMockConsole() {
  const newConsole = {};

  rebindMethods.forEach((rebindMethod) => {
    newConsole[rebindMethod] = () => {};
  });

  return newConsole;
}