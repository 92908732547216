import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';

export default {
  getHighscoreListDay() {
    return this.get(`/vidha-build-better/day`);
  },
  getHighscoreListWeek() {
    return this.get(`/vidha-build-better/week`);
  },
  getHighscoreListMonth() {
    return this.get(`/vidha-build-better/month`);
  },
  submitHighscore(data) {

    const uid = uuidv4();

    const score = data.score;
    const secret = process.env.VUE_APP_API_SECRET;
    const apiKey = process.env.VUE_APP_API_KEY;

    const message = `${secret}_${uid}_${score}`;
    const verificationCode = CryptoJS.SHA256(message).toString();

    data.uid = uid;
    data.verificationCode = verificationCode;

    return this.post('/vidha-build-better', data, {
      headers: {
        'X-Api-Key': apiKey
      }
    });
  }
};
