<template>
  <div
      class="time-wrapper d-flex justify-content-center vidha-ui-small-info"
      :class="timerClass"
      @click="onTimerClick"
  >
    <no-drag-image src="@/assets/ui/icon_time.svg" />
    {{ formattedTime }}
  </div>
</template>

<script>
import NoDragImage from "@/components/common/NoDragImage";
import {getFormattedTime} from "../../utils/dataUtil";
import {mapState} from "vuex";

export default {
  name: 'TimeView',
  components: { NoDragImage },
  emits: ['timer-finished'],
  data() {
    return {
      secondsLeft: 180,
      intervalId: null,
      isRunning: false,
      isDev: false,
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState([
      'gameActionsDisabled',
    ]),
    formattedTime() {
      return getFormattedTime(this.secondsLeft)
    },
    timerClass() {
      const timerClass = [];

      if (!this.isRunning) {
        timerClass.push('stopped');
      }

      if (!this.isDev) {
        timerClass.push('non-clickable');
      }

      return timerClass;
    }
  },
  mounted() {
    const isDev = parseInt(process.env.VUE_APP_IS_DEV, 10) > 0;
    this.isDev = isDev;
  },
  methods: {
    startTimer() {
      this.$root.stopSound('countDown');

      if (this.intervalId) return;
      this.isRunning = true;
      this.intervalId = setInterval(() => {
        if (this.secondsLeft > 0) {
          this.secondsLeft--;
          if (this.secondsLeft === 10) {
            this.$root.playSound('countDown');
          }
        } else {
          this.stopTimer();
          this.$emit('timer-finished');
        }
      }, 1000);
    },
    onTimerClick() {
      if (this.isDev) {
        this.toggleTimer();
      }
    },
    stopTimer() {
      this.$root.stopSound('countDown');

      clearInterval(this.intervalId);
      this.intervalId = null;
      this.isRunning = false;
    },
    toggleTimer() {
      if (this.disabled || this.gameActionsDisabled) {
        return;
      }

      if (this.isRunning) {
        this.stopTimer();
      } else {
        this.startTimer();
      }
    },
    setTime(newSeconds) {
      this.stopTimer();
      this.secondsLeft = newSeconds;
    },
    getTime() {
      return this.secondsLeft;
    },
  },
  beforeDestroy() {
    this.stopTimer();
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables.scss";

.time-wrapper {
  margin-bottom: 5px;

  &.non-clickable {
    cursor: default !important;

    &:active {
      background-color: $colorUiDarkBlue;
    }
  }

  &.stopped {
    background-color: #4F4F4F !important;
  }
}
</style>
