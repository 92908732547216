<template>
  <div class="weather-wrapper">


    <div class="highlight-wrapper">
      <div v-if="isGameView" class="highlight highlight-old">
        <no-drag-image src="@/assets/house/highlight_old.svg" />
      </div>
      <div class="highlight highlight-new" :style="highlightNewStyle">
        <no-drag-image src="@/assets/house/highlight_new.svg" />
      </div>
    </div>

    <div class="clouds-wrapper">
      <div class="cloud cloud-left-back" :class="cloudClass">
        <no-drag-image src="@/assets/house/weather/clouds_left_back.svg" />
      </div>
      <div class="cloud cloud-right-back" :class="cloudClass">
        <no-drag-image src="@/assets/house/weather/clouds_right_back.svg" />
      </div>
      <div class="cloud sun" :class="cloudClass">
        <no-drag-image src="@/assets/house/weather/sun.svg" />
      </div>
      <div class="cloud cloud-right-front" :class="cloudClass">
        <no-drag-image src="@/assets/house/weather/clouds_right_front.svg" />
      </div>
      <div class="cloud cloud-left-front" :class="cloudClass">
        <no-drag-image src="@/assets/house/weather/clouds_left_front.svg" />
      </div>
    </div>

    <div class="gloom" :style="gloomStyle"/>
  </div>
</template>
<script>

import NoDragImage from "@/components/common/NoDragImage";
import {mapState} from "vuex";
import GameData from "@/data/GameData";
import {ROUTER_NAME_GAME, ROUTER_NAME_HIGHSCORE, ROUTER_NAME_HOME} from "@/enum/RouterViewNames";
import {mapValue} from "@/utils/mathUtil";

export default {
  name: 'Weather',
  components: {NoDragImage},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  computed: {
    ...mapState ([
      'co2',
      'currentRoundIndex'
    ]),
    levelStarted() {
      return this.currentRoundIndex >= 0;
    },
    highlightNewStyle() {
      if (!this.isGameView || !this.levelStarted) {
        return { opacity: 0 };
      }

      const opacity = mapValue(
          this.co2, 1, GameData.MAX_CO2, 0, 1
      );

      return { opacity };
    },

    gloomStyle() {
      if (!this.isGameView) {
        return { opacity: 0 };
      }

      const opacity = mapValue(
          GameData.MAX_CO2 - this.co2, 1, GameData.MAX_CO2, 0, 0.4
      );

      return { opacity };
    },
    currentRouterView() {
      return this.$route.name;
    },
    isGameView() {
      return this.currentRouterView === ROUTER_NAME_GAME;
    },
    isHomeView() {
      return this.currentRouterView === ROUTER_NAME_HOME;
    },
    cloudClass() {
      if (!this.isGameView) {
        return 'show-sun';
      }
      return '';
    }
  },
  methods: {
    onClickButton() {
      if (this.disabled) {
        return;
      }

      this.$emit('click');
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/variables.scss";

.weather-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  overflow: hidden;

  .gloom {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #CAFFE3;
    pointer-events: none;
    transition: opacity 0.2s $bounceTimingFunction;
  }

  .clouds-wrapper {
    position: absolute;
    width: 100%;

    .cloud {
      position: absolute;
      transition: width 2s ease-in-out, top 2s ease-in-out, left 2s ease-in-out, right 2s ease-in-out;

      img {
        width: 100%;
      }
    }

    .cloud-left-back {
      width: 38vw;
      height: 100vh;
      left: -8vw;
      top: -5vw;

      &.show-sun {
        top: 1vw !important;
        left: -13vw !important;
        width: 22vw !important;
      }
    }

    .sun {
      width: 22vw;
      left: 8vw;
      top: -22vw;

      &.show-sun {
        top: 0 !important;
      }
    }

    .cloud-right-back {
      width: 60vw;
      height: 100vh;
      right: -12vw;
      top: -5vw;

      &.show-sun {
        top: -3vw !important;
        right: 2vw !important;
        width: 55vw !important;
      }
    }

    .cloud-left-front {
      width: 25vw;
      height: 100vh;
      left: 12vw;
      top: -7vw;

      &.show-sun {
        top: 2vw !important;
        left: 5vw !important;
        width: 20vw !important;
      }
    }

    .cloud-right-front {
      width: 25vw;
      height: 100vh;
      right: 2vw;
      top: -6vw;

      &.show-sun {
        top: -1.5vw !important;
        right: 12vw !important;
      }
    }
  }

  .highlight-wrapper {
    position: absolute;
    width: 110%;
    height: 110%;
    top: 40%;
    left: 48%;
    transform: translate(-50%, -50%);

    .highlight-new {
      opacity: 0;
    }

    .highlight {
      position: absolute;
      transition: opacity 0.2s $bounceTimingFunction;
      img {
        width: 100%;
      }
    }
  }
}
</style>
