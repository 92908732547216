// These are placements from left to right

export const ROOF = 'ROOF';
export const GARAGE_ROOF = 'GARAGE_ROOF';
export const INSULATION_FACADE_1 = 'INSULATION_FACADE_1';
export const INSULATION_FACADE_2 = 'INSULATION_FACADE_2';
export const INSULATION_FACADE_3 = 'INSULATION_FACADE_3';
export const INSULATION_FACADE_4 = 'INSULATION_FACADE_4';
export const INSULATION_ROOF = 'INSULATION_ROOF';
export const INSULATION_GARAGE_ROOF = 'INSULATION_GARAGE_ROOF';
export const INSULATION_GARAGE_FACADE_1 = 'INSULATION_GARAGE_FACADE_1';
export const INSULATION_GARAGE_FACADE_2 = 'INSULATION_GARAGE_FACADE_2';
export const GUTTER = 'GUTTER';
export const FACADE_WALL_1 = 'FACADE_WALL_1';
export const FACADE_WALL_2 = 'FACADE_WALL_2';
export const FACADE_WALL_3 = 'FACADE_WALL_3';
export const FACADE_WALL_4 = 'FACADE_WALL_4';
export const FACADE_GARAGE_WALL_1 = 'FACADE_GARAGE_WALL_1';
export const FACADE_GARAGE_WALL_2 = 'FACADE_GARAGE_WALL_2';
export const FACADE_CLADDING_1 = 'FACADE_CLADDING_1';
export const FACADE_CLADDING_2 = 'FACADE_CLADDING_2';
export const FACADE_CLADDING_3 = 'FACADE_CLADDING_3';
export const DORMER_1 = 'DORMER_1';
export const DORMER_2 = 'DORMER_2';
