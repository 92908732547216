<template>
  <div
    class="outline-wrapper"
    :class="outlineComputedClass"
    ref="outline"
  >
    <component :is="outlineComponent" class="outline"></component>
    <component :is="clickAreaComponent"
               class="click-shape"
               @shapeClicked="shapeClicked"
               @shapeHovered="shapeHovered"
               @shapeLeft="shapeLeft">

    </component>


    <div v-if="isShown" class="modifier-outer">
      <modifier-view :type="modifier" :multiplier="modifierMultiplier" :modifier-style="fixedStyle"/>
    </div>
  </div>
</template>
<script>

import NoDragImage from "@/components/common/NoDragImage";
import { markRaw } from 'vue';
import {mapState} from "vuex";
import {MODIFIER_TYPE_MONEY} from "@/enum/ModifierType";
import ModifierView from "../common/ModifierView";
import HouseOutlinePartData from "@/data/HouseOutlinePartData";
import {FACADE_CLADDING_1, GARAGE_ROOF, GUTTER, ROOF} from "@/enum/HousePartIds";

export default {
  name: 'HousePartOutline',
  components: {ModifierView, NoDragImage},
  data() {
    return {
      outlineComponent: null,
      clickAreaComponent: null,
      fixedStyle: {},
    };
  },
  props: {
    outlineName: {
      type: String,
      default: 'RoofOutline',
    },
    outlineClass: {
      type: String,
      default: 'roof',
    },
    selected: {
      type: Boolean,
      default: false
    },
    highlighted: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    highlightCategory: {
      type: String,
      default: '',
    },
    modifier: {
      type: String,
      default: MODIFIER_TYPE_MONEY,
    },
    modifierMultiplier: {
      type: Number,
      default: 2,
    },
    hasHouseTask: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['click', 'shapeHovered', 'shapeLeft'],
  computed: {
    ...mapState([
      'highlightedCategory',
      'showRoundSummary',
      'gameActionsDisabled',
    ]),
    isHighlighted() {
      return this.highlighted || this.highlightedCategory === this.highlightCategory;
    },
    outlineComputedClass() {
      const outlineComputedClass = [];
      outlineComputedClass.push(this.outlineName);
      outlineComputedClass.push(this.outlineClass);

      if (this.selected) {
        outlineComputedClass.push('selected');
      }

      if (this.showRoundSummary && this.hasHouseTask) {
        outlineComputedClass.push('selected');
      }

      if (!this.disabled && (this.highlighted || this.highlightedCategory === this.highlightCategory)) {
        outlineComputedClass.push('highlighted');
      }

      if (this.disabled) {
        outlineComputedClass.push('disabled');
      }

      return outlineComputedClass;
    },
    isShown() {
      if (this.showRoundSummary && this.hasHouseTask) {
          return true;
      }

      return !this.disabled && (this.selected || this.isHighlighted);
    }
  },
  mounted() {
    this.loadComponent();
    // Update the position when the component is mounted
    this.$nextTick(() => {
      this.updateFixedPosition('outline');
    });
    // Update the position when the window is resized
    window.addEventListener('resize', () => this.updateFixedPosition('outline'));
  },
  beforeDestroy() {
    // Clean up the event listener
    window.removeEventListener('resize', this.updateFixedPosition);
  },
  methods: {
    shapeClicked() {
      if (this.disabled || this.gameActionsDisabled) {
        return;
      }

      this.$emit('click');
    },
    shapeHovered() {
      if (this.disabled || this.gameActionsDisabled) {
        return;
      }

      this.$emit('shapeHovered');
    },
    shapeLeft() {
      if (this.disabled || this.gameActionsDisabled) {
        return;
      }

      this.$emit('shapeLeft');
    },
    async loadComponent() {
      try {
        const component = await import(`./IndicatorOutlineSvgTemplates/${this.outlineName}Svg.vue`);
        this.outlineComponent = markRaw(component.default);
      } catch (error) {
        console.error(`Failed to load component: ${this.outlineName}Svg.vue`, error);
        this.outlineComponent = null;
      }
      try {
        const component = await import(`./IndicatorOutlineSvgTemplates/${this.outlineName}FilledSvg.vue`);
        this.clickAreaComponent = markRaw(component.default);
      } catch (error) {
        console.error(`Failed to load component: `, error);
        this.clickAreaComponent = null;
      }
    },
    updateFixedPosition(refName) {
      const element = this.$refs[refName];

      if (element) {
        const rect = element.getBoundingClientRect();

        this.fixedStyle = {
          position: 'fixed',
          top: `${rect.top + rect.height * 0.3}px`,
          left: `${rect.left + rect.width / 2}px`,
          zIndex: 110000000,
        };

        if (this.outlineName === HouseOutlinePartData[ROOF].outlineName) {
          this.fixedStyle.top = `${rect.top + rect.height * 0.6}px`;
        } else if (this.outlineName === HouseOutlinePartData[GARAGE_ROOF].outlineName) {
          this.fixedStyle.top = `${rect.top + rect.height * 0.6}px`;
        } else if (this.outlineName === HouseOutlinePartData[FACADE_CLADDING_1].outlineName) {
          this.fixedStyle.left = `${rect.left + rect.width * 0.35}px`;
        } else if (this.outlineName === HouseOutlinePartData[GUTTER].outlineName) {
          this.fixedStyle.left = `${rect.left + rect.width * 0.3}px`;
          this.fixedStyle.top = `${rect.top + rect.height * 0.2}px`;
        }


      }
    },
  },
};
</script>
<style lang="scss" scoped>

.outline-wrapper {
  // cursor: pointer;
  position: absolute;
  z-index: 99999;
  top: -1%;
  left: -1%;
  width: 102%;
  height: 102%;
  opacity: 0;

  &.disabled {
    cursor: default !important;
    pointer-events: none;

    svg {
      pointer-events: none
    }
    path {
      pointer-events: none
    }
    :not(svg) {
      pointer-events: none
    }
  }

  &.selected {
    opacity: 1 !important;
    color: #B5F3A6 !important;
  }

  &.highlighted {
    opacity: 1 !important;
    color: #00F6FF;
  }

  &.placement-door {
    top: -3% !important;
    left: 19% !important;
    width: 85% !important;
    height: 85% !important;
  }

  &.placement-garage-door {
    top: 0 !important;
    left: 12% !important;
    width: 88% !important;
    height: 88% !important;
  }

  &.placement-roof {
    top: 0 !important;
    left: 5.6% !important;
    width: 57.5% !important;
    height: 57.5% !important;
  }

  &.placement-garage-roof {
    top: 12.55% !important;
    left: 53.45% !important;
    width: 34.67% !important;
    height: 34.67% !important;
  }

  &.placement-gutter {
    top: 49% !important;
    left: 14% !important;
    width: 41% !important;
    height: 41% !important;
  }

  &.placement-facade-1 {
    top: 33.9% !important;
    left: -11.8% !important;
    width: 52% !important;
    height: 52% !important;
  }

  &.placement-facade-2 {
    top: 52.5% !important;
    left: 15.6% !important;
    width: 31% !important;
    height: 31% !important;
  }

  &.placement-facade-3 {
    top: 52.5% !important;
    left: 22.5% !important;
    width: 32% !important;
    height: 32% !important;
  }

  &.placement-facade-4 {
    top: 33.9% !important;
    left: 28.1% !important;
    width: 52% !important;
    height: 52% !important;
  }

  &.placement-facade-5 {
    top: 39.1% !important;
    left: 54.7% !important;
    width: 30.5% !important;
    height: 30.5% !important;
  }

  &.placement-facade-6 {
    top: 35% !important;
    left: 61.6% !important;
    width: 37.6% !important;
    height: 37.6% !important;
  }

  &.placement-dormer-1 {
    top: 22.4% !important;
    left: -2.9% !important;
    width: 31% !important;
    height: 31% !important;
  }

  &.placement-dormer-2 {
    top: 22.4% !important;
    left: 40.5% !important;
    width: 31% !important;
    height: 31% !important;
    transform: scaleX(-1);
  }

  &.placement-cladding-3 {
    top: 39.1% !important;
    left: 54.7% !important;
    width: 30.5% !important;
    height: 30.5% !important;
  }

  .click-shape {
    position: absolute;
    top: 0;
    left: 0;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  &:active {
    img {
      filter: brightness(90%);
      color: #ffffff;
    }
  }
}
</style>
