<template>
  <div class="high-score-view">
    <div class="back-button">
      <app-button
          class='vidha-button vidha-button-large'
          @click="onBackButtonClicked">
        <div class="d-flex align-self-center justify-content-around">
          <no-drag-image src="@/assets/ui/arrow_white.png"/>
        Tilbage
        </div>
      </app-button>
    </div>
    <div class="high-score-inner-wrapper">
      <action-box :titles="['Highscore']">
        <action-box-content-high-score :highscore-data="localHighscoreData" :highLight-score-indexes="highLightScoreIndexes"/>
      </action-box>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import ActionBox from "@/components/common/ActionBox";
import ActionBoxContentHighScore, {LIST_TYPE_DAY, LIST_TYPE_WEEK, LIST_TYPE_MONTH} from "@/components/common/ActionBoxContentHighScore";
import AppButton from "@/components/common/AppButton";
import NoDragImage from "@/components/common/NoDragImage";
import Weather from "@/components/house/Weather";

export default {
  name: 'HighScoreView',
  components: {
    Weather,
    NoDragImage,
    AppButton,
    ActionBoxContentHighScore,
    ActionBox,
  },
  data() {
    return {
      highLightScoreIndexes: {},
      localHighscoreData: {},
    }
  },
  watch: {
    highscoreData: {
      immediate: true,
      handler(newVal) {
        this.localHighscoreData = newVal;
      },
      deep: true,
    },
    highscorePlacementData: {
      immediate: true,
      handler(newVal) {
        this.highLightScoreIndexes = this.calculateHighLightScoreIndexes();
      },
      deep: true,
    },
    highscores: {
      immediate: true,
      handler(newVal) {
        this.highLightScoreIndexes = this.calculateHighLightScoreIndexes();
      },
      deep: true,
    },
  },
  mounted() {
    this.$store.commit('refreshHighscores', this.$apiClient);

    this.localHighscoreData = this.highscores;

  },
  computed: {
    ...mapState([
      'highscorePlacementData',
      'highscores'
    ]),
  },
  methods: {
    onBackButtonClicked() {
      this.$router.push('/game')
    },
    calculateHighLightScoreIndexes() {
      console.log("calculateHighLightScoreIndexes 1");
      if (
          Object.keys(this.highscorePlacementData).length === 0 ||
          Object.keys(this.highscores).length === 0
      ) {
        return { ranks: {} };
      }

      console.log("calculateHighLightScoreIndexes 2");

      const periods = [LIST_TYPE_DAY, LIST_TYPE_WEEK, LIST_TYPE_MONTH];
      const correctRanks = {};

      const highscoreData = this.highscorePlacementData?.ranks || {};
      const { name, score } = this.highscorePlacementData?.metaData || {};

      periods.forEach((period) => {
        const periodData = this.highscores[period];

        // Get the provided rank from highscoreData and adjust for zero-based indexing
        const providedRank = highscoreData[period] - 1;

        if (periodData.length < providedRank) {
          correctRanks[period] = providedRank;
        } else {
          // First, check if the entry at the provided rank matches the name and points
          const entryAtProvidedRank = periodData[providedRank];

          if (
              entryAtProvidedRank &&
              entryAtProvidedRank.name === name &&
              entryAtProvidedRank.score === score
          ) {
            correctRanks[period] = providedRank;
          } else {
            let found = false;
            for (let i = providedRank + 1; i < periodData.length; i++) {
              const entry = periodData[i];
              if (entry.name === name && entry.score === score) {
                correctRanks[period] = i;
                found = true;
                break;
              }
            }
            if (!found) {
              correctRanks[period] = -1;
            }
          }
        }

      });
      console.log("calculateHighLightScoreIndexes 3", correctRanks);

      return { ranks: correctRanks };
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables.scss";
@import "@/sass/mixins.scss";

$exampleVariable: 20px;


.high-score-view {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;

  .high-score-inner-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 10px;
  }

  .back-button {
    width: 185px;
    margin-top: 30px;
    margin-left: 20px;

    img {
      margin-top: -2px;
      margin-left: -5px;
    }
  }
}

</style>
