import { createStore } from 'vuex';
import categories from '@/store/categories'

const state = {};
const getters = {};
const mutations = {};

const stateKeys = [];
const getterKeys = [];
const mutationKeys = [];

// Create the store in the standard Vue fashion but give an error if the state/getter/mutation already exists
for (const category of categories) {
  for (const key in category.state) {
    if (stateKeys.includes(key)) {
      throw new Error(`State key ${key} was already defined (when importing ${category}`);
    }

    state[key] = category.state[key];

    stateKeys.push(key);
  }

  for (const key in category.getters) {
    if (getterKeys.includes(key)) {
      throw new Error(`Getter ${key} was already defined (when importing ${category}`);
    }

    getters[key] = category.getters[key];

    getterKeys.push(key);
  }

  for (const key in category.mutations) {
    if (mutationKeys.includes(key)) {
      throw new Error(`Mutation ${key} was already defined (when importing ${category}`);
    }

    mutations[key] = category.mutations[key];

    mutationKeys.push(key);
  }
}

const actions = {
  initAllCategories({ commit }) {
    categories.forEach((category) => {
      const initiateMutation = `initiate${category.name}`;
      if (mutations[initiateMutation]) {
        commit(initiateMutation);
      }
    });
  }
};

const initStore = {
  state,
  getters,
  mutations,
  actions,
};

export default createStore(initStore);
