<template>
  <div class="container">

    <Weather></Weather>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import simpleClick from '@/assets/sounds/UI_Simple_Click.mp3';
import recycleClick from '@/assets/sounds/Recylcle_Btn.mp3';
import renovateClick from '@/assets/sounds/Renovate_Btn.mp3';
import buyClick from '@/assets/sounds/BuyNew_btn.mp3';
import cardsAppear from '@/assets/sounds/Cards_Apear.mp3';
import cardsDisappear from '@/assets/sounds/Cards_Disapear.mp3';
import trash from '@/assets/sounds/Trash.mp3';
import countDown from '@/assets/sounds/Countdown12SecoundsLeft.mp3';
import music from '@/assets/sounds/Background_Music.wav';
import ambientSounds from '@/assets/sounds/SoftWindsAndBirds_Ambience_menu.mp3';
import soundManager from "@/utils/SoundManager";
import Weather from "@/components/house/Weather";

export default {
  name: 'App',
  components: {Weather},
  data() {
    return {
      devClickIterator: 0,
      devClickTimeout: null,

      devPanelOpen: false,
      devMode: false,
      stateViewerOpen: false,
    }
  },
  mounted() {
    if (parseInt(process.env.VUE_APP_DEBUG_API_CLIENT, 10) > 0) {
      this.$apiClient.setUseDebugConsole(true, 'Enabled debugging due to VUE_APP_DEBUG_API_CLIENT', 'white', '#000088');
    }

    this.$apiClient.setErrorHandler(this.apiErrorHandler);

    this.$apiClient.setBaseUrl(process.env.VUE_APP_BASE_API_URL);

    soundManager.loadSound('simpleClick', simpleClick, false);
    soundManager.loadSound('recycle', recycleClick, false);
    soundManager.loadSound('renovate', renovateClick, false);
    soundManager.loadSound('buy', buyClick, false);
    soundManager.loadSound('cardsAppear', cardsAppear, false);
    soundManager.loadSound('cardsDisappear', cardsDisappear, false);
    soundManager.loadSound('trash', trash, false);
    soundManager.loadSound('countDown', countDown, false);
    soundManager.loadSound('music', music, true);
    soundManager.loadSound('ambientSounds', ambientSounds, true);
  },
  methods: {
    playSound(key) {
      soundManager.playSound(key);
    },
    stopSound(key) {
      soundManager.stopSound(key);
    },
    playAmbientMusic() {
      soundManager.stopSound('ambientSounds', 1000);
      soundManager.playSound('music');
    },
    playAmbientSound() {
      soundManager.stopSound('music', 1000);
      soundManager.playSound('ambientSounds');
    },
    stopAllAmbient() {
      soundManager.stopSound('music');
      soundManager.stopSound('ambientSounds');
    }
  }
}
</script>

<style lang="scss">
@import "@/sass/variables.scss";
@import "@/sass/animations.scss";
@import "@/sass/fonts.scss";
@import "@/sass/main.scss";
</style>
