import {
    LEVEL_1_ROUND_1,
    LEVEL_1_ROUND_2,
    LEVEL_2_ROUND_1,
    LEVEL_2_ROUND_2,
    LEVEL_3_ROUND_1,
    LEVEL_3_ROUND_2,
    LEVEL_4_ROUND_1,
    LEVEL_4_ROUND_2,
    LEVEL_5_ROUND_1,
    LEVEL_5_ROUND_2,
    LEVEL_6_ROUND_1,
    LEVEL_6_ROUND_2,
} from "@/enum/LevelIds";

import {
    COLOR_YELLOW,
    COLOR_GREEN,
    COLOR_RED,
    COLOR_PINK,
    COLOR_PEACH,
    COLOR_LIGHT_GREEN
} from "@/components/common/AppButton"

export default {
    1: {
        rounds: [LEVEL_1_ROUND_1, LEVEL_1_ROUND_2],
        color: COLOR_YELLOW,
        name: 'Level 1'
    },
    2: {
        rounds: [LEVEL_2_ROUND_1, LEVEL_2_ROUND_2],
        color: COLOR_LIGHT_GREEN,
        name: 'Level 2'
    },
    3: {
        rounds: [LEVEL_3_ROUND_1, LEVEL_3_ROUND_2],
        color: COLOR_GREEN,
        name: 'Level 3'
    },
    4: {
        rounds: [LEVEL_4_ROUND_1, LEVEL_4_ROUND_2],
        color: COLOR_PEACH,
        name: 'Level 4'
    },
    5: {
        rounds: [LEVEL_5_ROUND_1, LEVEL_5_ROUND_2],
        color: COLOR_PINK,
        name: 'Level 5'
    },
    6: {
        rounds: [LEVEL_6_ROUND_1, LEVEL_6_ROUND_2],
        color: COLOR_RED,
        name: 'Level 6'
    }
}
