import {TRASH_DANGEROUS, TRASH_METAL, TRASH_WOOD} from "@/enum/TrashType";
import {getRandomTrashType} from "@/utils/dataUtil";
import {getLocalStorageValue} from "@/utils/storeUtil";

export default {
  name: 'TrashMinigame',
  state: {
    currentTrash: [],
    selectedTrashId: null,
    currentTrashIdCounter: 1,
  },
  mutations: {
    initiateTrashMinigame: (state, value) => {
      const keysToRetrieve = [
      ];

      keysToRetrieve.forEach(({ key, type }) => {
        const { hasValue, returnValue } = getLocalStorageValue(key, type);

        if (hasValue) {
          if (hasValue) state[key] = returnValue;
        }
      });
    },
    setCurrentTrashAmount: (state, value) => {
      const newAmount = Math.max(value, 0);

      state.currentTrash = [];

      for (let i = 0; i < newAmount; i++) {
        const randomTrashType = getRandomTrashType();
        const newTrashItem = {
          id: state.currentTrashIdCounter++,
          type: randomTrashType,
        };
        state.currentTrash.push(newTrashItem);
      }
    },
    addCurrentTrashAmount: (state, value) => {
      const newAmount = state.currentTrash.length + value > 0 ? state.currentTrash.length + value : 0;

      if (value < 0) {
        state.currentTrash.length = newAmount;
      } else {
        for (let i = 0; i < Math.abs(value); i++) {
          const randomTrashType = getRandomTrashType();

          const newTrashItem = {
            id: state.currentTrashIdCounter++,
            type: randomTrashType,
          };

          state.currentTrash.push(newTrashItem);
        }
      }
    },
    selectTrashId: (state, value) => {
      state.selectedTrashId = value;
    },
    attemptThrowout: (state, trashcanType) => {
      const selectedIndex = state.currentTrash.findIndex(
          (item) => item.id === state.selectedTrashId
      );
      if (
          selectedIndex !== -1 &&
          state.currentTrash[selectedIndex].type === trashcanType
      ) {
        // Correct trashcan, remove the item
        state.currentTrash.splice(selectedIndex, 1);
        state.selectedTrashId = null;
      }
    },
  },
  getters: {
    currentTrash (state) {
      return state.currentTrash;
    },
    selectedTrashId (state) {
      return state.selectedTrashId;
    },
  },
}
