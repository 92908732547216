<template>
  <section id="Flexbox" class="Flexbox">
    <article class="BlockLayout BlockLayout--typeFlex d-flex">
      <block-component v-for="block in blocks" :key="block.id" :id="block.id" :index="block.index" :draggable="block.draggable" />
    </article>
    <div id="Dropzone" class="Dropzone">Dropzone</div>
  </section>
</template>

<script>
import { Swappable, Plugins } from '@shopify/draggable';
import BlockComponent from './BlockComponent.vue';

export default {
  name: 'Flexbox',
  components: {
    BlockComponent
  },
  data() {
    return {
      blocks: [
        { id: 'one', index: 1, draggable: true },
        { id: 'two', index: 2 },
        { id: 'three', index: 3, draggable: true },
        { id: 'four', index: 4, draggable: true },
        { id: 'five', index: 5, draggable: true },
        { id: 'six', index: 6 },
        { id: 'seven', index: 7, draggable: true },
      ]
    };
  },
  mounted() {
    const containers = this.$el.querySelectorAll('.BlockLayout');
    const dropzone = this.$el.querySelector('#Dropzone');

    console.log("is the dropzone defined)", dropzone);

    if (containers.length === 0) {
      return;
    }

    const swappable = new Swappable(containers, {
      draggable: '.Block--isDraggable',
      mirror: {
        constrainDimensions: true,
      },
      plugins: [Plugins.ResizeMirror],
    });

    swappable.on('drag:start', () => {
      containers.forEach(container => container.classList.add('dragging'));
    });

    swappable.on('drag:stop', () => {
      containers.forEach(container => container.classList.remove('dragging'));
    });

    swappable.on('swappable:swapped', (event) => {
      const sourceElement = event.source;

      if (sourceElement) {
        if (dropzone.contains(sourceElement)) {
          sourceElement.style.backgroundColor = 'green';
        } else {
          sourceElement.style.backgroundColor = '';
        }
      }
    });

    return swappable;
  }
};
</script>

<style scoped lang="scss">
@import 'sass/draggableFunctions';
@import 'sass/draggableLayout';

$flexbox-block-name: unquote('Block');

.Flexbox {
  @include draggable-source-layout($flexbox-block-name, 1, 2, 4, 5) {
    flex-basis: 50%;
  }

  @include draggable-source-layout($flexbox-block-name, 3, 6, 7) {
    flex-basis: 100%;
  }

  @include draggable-source-layout($flexbox-block-name, 1, 2, 4, 5, 7) {
    .BlockContent {
      height: rows(2);
    }
  }

  @include draggable-source-layout($flexbox-block-name, 3, 6) {
    .BlockContent {
      height: rows(3);
    }
  }

  .Dropzone {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    margin: 20px 0;
  }
}
</style>
