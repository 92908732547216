<template>
  <div class="accordion" :style="style">
    <div ref="accordionInner" class="accordion-inner">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    open: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      fullHeight: 0,
    };
  },
  computed: {
    style() {
      const calcHeight = this.open ? this.fullHeight : 0;

      return {
        height: `${ calcHeight }px`,
      };
    },
  },
  watch: {
    open() {
      if (this.open) {
        // Just opened
        this.recalculateHeight(); // Maybe ???
      }
    },
  },
  mounted() {
      this.recalculateHeight();
  },
  methods: {
    recalculateHeight() {

      this.$nextTick(() => {
        this.fullHeight = this.$refs.accordionInner.clientHeight;
      });
    },
  },
};
</script>

<style lang="scss">
.accordion {
  //transition-duration: 200ms;
  transition-property: height;
  transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
  overflow: hidden;
  height: 0;
}

</style>
