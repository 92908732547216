<template>
  <div
      class="co2-wrapper d-flex justify-content-center"
  >
    <div class="co2-level-square"
         :class="indicatorSquareClass(number)"
         v-for="number in maxCo2"
         :key="number"
    />

    <div class="co2-indicator" :style="indicatorStyle">
      <div class="co2-indicator-inner" :class="indicatorSquareClass(co2)"/>
      <p>CO<sub>2</sub></p>
    </div>
  </div>
</template>
<script>

import NoDragImage from "@/components/common/NoDragImage";
import {mapState} from "vuex";
import GameData from "@/data/GameData";
export default {
  name: 'Co2View',
  components: {NoDragImage},
  data() {
    return {
      maxCo2: GameData.MAX_CO2,
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState ([
      'co2'
    ]),
    indicatorPosition() {
      const value = this.co2;
      const min_source = 1;
      const max_source = this.maxCo2;

      // map between these two values to ensure that it looks right
      const min_target = 1.8;
      const max_target = 97.7;

      const mapped_value = min_target + ((value - min_source) / (max_source - min_source)) * (max_target - min_target)
      return mapped_value
    },
    indicatorStyle() {
      const indicatorStyle = {};

      indicatorStyle.left = this.indicatorPosition + '%';

      return indicatorStyle;
    }
  },
  methods: {
    indicatorSquareClass(number) {
      if (number > this.maxCo2 * 2/3) {
        return 'co2-level-good';
      }
      else if (number > this.maxCo2 / 3) {
        return 'co2-level-medium';
      }
      else {
        return 'co2-level-bad';
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/sass/variables.scss";

.co2-wrapper {
  cursor: pointer;
  background-color: $colorUiDarkBlue;
  font-weight: 700;
  border-radius: 10px;
  font-size: 23px;
  border: solid 3px #fff;
  line-height: 0.9;
  pointer-events: none;
  box-shadow: 0 4px 8px rgba(60, 70, 82, 0.7), 0 4px 6px rgba(119, 197, 222, 0.7) inset;
  padding: 0 3px;
  color: #455B69;
  margin-top: 10px;

  p {
    padding-top: 18px;
    position: relative;
    z-index: 999;
  }

  .co2-level-square {
    position: relative;
    width: 0.6vw;
    height: 33.7px;

    border: solid 1px #fff;
    border-radius: 4px;
    margin: 5px 1.5px;

    &.co2-level-good {
      background-color: #B5F3A6;
    }

    &.co2-level-medium {
      background-color: #F0F3A6;
    }

    &.co2-level-bad {
      background-color: #CE8A8B;
    }
  }

  .co2-indicator {
    position: absolute;
    background-image: url('../../assets/ui/icon_co2_cloud_outer.png');
    background-repeat: no-repeat;
    top: -40px;
    height: 60px;
    width: 79px;
    background-position: center;
    background-size: cover;
    left: 97.7%;
    transform: translateX(-50%);
    transition: left 0.25s cubic-bezier(0.175, 0.985, 0.32, 1.175);

    .co2-indicator-inner {
      width: 90%;
      height: 90%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -49%);
      position: absolute;
      background-image: url('../../assets/ui/icon_co2_cloud_inner.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      &.co2-level-good {
        filter: brightness(0%) invert(89%) sepia(4%) saturate(2734%) hue-rotate(59deg) brightness(94%) contrast(111%);
      }

      &.co2-level-medium {
        filter: brightness(0%) invert(90%) sepia(46%) saturate(286%) hue-rotate(3deg) brightness(98%) contrast(95%);
      }

      &.co2-level-bad {
        filter: brightness(0%) invert(66%) sepia(35%) saturate(439%) hue-rotate(309deg) brightness(87%) contrast(83%);
      }
    }
  }

  img {
    height: 80%;
    margin-right: 10px;
  }
}
</style>
