
import {
    CATEGORY_CLADDING,
    CATEGORY_DOOR, CATEGORY_DORMER,
    CATEGORY_GUTTER, CATEGORY_INSULATION,
    CATEGORY_OUTER_WALL, CATEGORY_ROOF,
    CATEGORY_WINDOW
} from "@/enum/Categories";
export default {
    [CATEGORY_WINDOW]: {
        categoryName: 'Vinduer',
    },
    [CATEGORY_DOOR]: {
        categoryName: 'Døre',
    },
    [CATEGORY_CLADDING]: {
        categoryName: 'Beklædning',
    },
    [CATEGORY_GUTTER]: {
        categoryName: 'Tagrende',
    },
    [CATEGORY_OUTER_WALL]: {
        categoryName: 'Ydervæg',
    },
    [CATEGORY_ROOF]: {
        categoryName: 'Tag',
    },
    [CATEGORY_INSULATION]: {
        categoryName: 'Isolering',
    },
    [CATEGORY_DORMER]: {
        categoryName: 'Gavl',
    },
}
