<template>
    <div class="level-button-wrapper" :class="stars > 4 ? 'five-stars' : ''">
      <div class="star-wrapper d-flex justify-content-center">
        <div class="mx-2" v-for="number in numberOfStars" :key="number">
          <no-drag-image :src="getStarImgSrc(number <= stars)" />

        </div>
      </div>
      <div class="content-wrapper p-2">
        <div class="house-wrapper">
          <no-drag-image :src="getLevelImgSrc()" />
        </div>

        <div class="app-button-wrapper">
          <app-button :color="color" size="large" @click="onClickButton">{{name}} </app-button>
        </div>
      </div>
    </div>
</template>
<script>

import AppButton from "@/components/common/AppButton";
import NoDragImage from "@/components/common/NoDragImage";
import pointData from "@/data/PointData";
import {getLevelStarsFromId} from "@/utils/dataUtil";
import {mapState} from "vuex";
export const COLOR_GREEN = 'green';
export const COLOR_YELLOW = 'yellow';
export const COLOR_RED = 'red';
export const COLOR_PURPLE = 'purple';
export const COLOR_PINK = 'pink';
export const COLOR_PEACH = 'peach';
export const COLOR_LIGHT_GREEN = 'light-green';

export const SIZE_SMALL = 'small';
export const SIZE_MEDIUM = 'medium';

export default {
  name: 'LevelButton',
  components: {NoDragImage, AppButton},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: COLOR_GREEN,
      required: false,
    },
    levelId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      numberOfStars: pointData.numberOfStars,
    }
  },
  emits: ['click'],
  computed: {
    ...mapState([
      'currentHighestStarLevels',
    ]),
    stars() {
      return getLevelStarsFromId(this.levelId, this.currentHighestStarLevels);
    }
  },
  methods: {
    onClickButton() {
      if (this.disabled) {
        return;
      }

      this.$emit('click');
    },
    getStarImgSrc(filled) {
      const imageName = filled ? 'star_filled' : 'star';
      const requireImage = require.context('@/assets/ui', true, /\.png$/);
      const imagePath = `./${imageName}.png`;
      return requireImage(imagePath);
    },
    getLevelImgSrc(filled) {
      const imagePath = `./level${this.levelId}.png`;
      const requireImage = require.context('@/assets/ui/levelSelect', true, /\.png$/);
      return requireImage(imagePath);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/variables.scss";
$buttonHeight: 38px;
$borderRadius: 30px;
$borderRadiusMedium: 50px;

.level-button-wrapper {
  width: 310px;
  height: 195px;
  margin: 40px 12px;

  background-color: #9BDEDF;
  border: 4.37px solid #FFFFFF;

  &.five-stars {
    border: 4.37px solid #F0D77D
  }

  .star-wrapper {
    width: 100%;
    height: 34px;
    padding: 4px;
    background-color: $colorUiDarkBlue;
    color: white;
    position: relative;
    z-index: 33;

    img {
      width: 20px;
    }
  }

  .content-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    .house-wrapper {
      position: absolute;
      width: 100%;
      left: 50%;
      top: calc(47% - 12px);
      transform: translate(-50%, -50%);
      overflow: hidden;
      img {
        width: 110%;
      }
    }


    .ground {
      position: absolute;
      top: 25px;

      img {
        width: 80%;
      }
    }

    .app-button-wrapper {
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(calc(-50% - 10px));
      width: 80%;
    }
  }
}
</style>
