<template>
  <div class="action-box-content-info">
    Vi har i mange år fokuseret på at isolere bygninger for at spare energi, men nu er det essentielt at flytte fokus mod CO2-udledningen fra byggematerialer og affaldshåndtering.
<br><br>
    Fremtidens byggeri kræver en balance mellem ressourceforbrug
    og CO2-udledning.
<br><br>
    Vores nye online spil til erhvervsskolelærlinge er designet til at skabe
    en grundlæggende forståelse for et grønt mindset.
  </div>
</template>
<script>

export default {
  name: 'ActionBoxContentInfo',
};
</script>
<style lang="scss" scoped>
@import "@/sass/variables.scss";

$videoMargin: 30px;

.action-box-content-info {
  margin: $videoMargin/2 $videoMargin $videoMargin $videoMargin;
  text-align: left;
  font-size: 20px;
}


</style>
