import simulation from './simulation'
import ui from './ui'
import trashMinigame from "./trashMinigame";
import highscore from "./highscore";

export default [
  simulation,
  ui,
  trashMinigame,
  highscore,
]
