<template>
  <div
    class="money-wrapper d-flex vidha-ui-small-info"
    :class="moneyClass"
  >
    <no-drag-image src="@/assets/ui/icon_coin.png" />
    {{money}}
  </div>
</template>
<script>

import NoDragImage from "@/components/common/NoDragImage";
import {mapState} from "vuex";
export default {
  name: 'MoneyView',
  components: {NoDragImage},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState ([
        'money'
    ]),
    moneyClass() {
      return this.money <= 0 ? 'warning-state' : '';
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/variables.scss";

.money-wrapper {
  padding-left: 15px;
  pointer-events: none;

  &.warning-state {
    border-color: $colorUiRed;
  }
}
</style>
