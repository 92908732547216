<template>
  <h1>AppButtons</h1>

  <dev-panel>
    <div class="row">
      <label class="form-label">Color</label>
      <bootstrap-select v-model="color" :options="options.color" />
    </div>
    <div class="row mt-4">
      <label class="form-label">Size</label>
      <bootstrap-select v-model="size" :options="options.size" />
    </div>
    <div class="row mt-4">
      <label class="form-label">White border</label>
      <bootstrap-select v-model="hasWhiteBorder" :options="options.hasWhiteBorder" />
    </div>
    <div class="row mt-4">
      <label class="form-label">Disabled</label>
      <bootstrap-select v-model="disabled" :options="options.disabled" />
    </div>
  </dev-panel>
  <div class="output-panel row mt-5">
    <div class="container">
      <div class="row">
        <app-button
          :disabled="disabled"
          :position="position"
          :color="color"
          :size="size"
          :has-white-border="hasWhiteBorder"
        >
          {{ buttonText }}
        </app-button>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from '../AppButton.vue'
import { COLOR_GREEN, COLOR_RED, SIZE_MEDIUM, SIZE_SMALL } from '../AppButton.vue';
import ButtonContainer from '../../DevComponent/ButtonContainer';
import EventTicks from '../../DevComponent/EventTicks';
import DevComponentContainer from '../../DevComponent/DevComponentContainer';
import BootstrapSelect from "@/components/bootstrap/BootstrapSelect";
import DevPanel from "@/components/DevComponent/DevPanel";

export default {
  name: 'AppButtonDev',
  components: {DevPanel, BootstrapSelect, AppButton, EventTicks, ButtonContainer },
  extends: DevComponentContainer,
  data() {
    return {
      disabled: false,

      hasWhiteBorder: true,
      color: COLOR_GREEN,
      size: SIZE_SMALL,

      buttonText: 'My Text',

      options: {
        disabled: [
          {
            value: false,
            label: 'FALSE',
          },
          {
            value: true,
            label: 'TRUE',
          },
        ],
        hasWhiteBorder: [
          {
            value: false,
            label: 'FALSE',
          },
          {
            value: true,
            label: 'TRUE',
          },
        ],
        color: [
          {
            value: COLOR_GREEN,
            label: COLOR_GREEN,
          },
          {
            value: COLOR_RED,
            label: COLOR_RED,
          },
        ],
        size: [
          {
            value: SIZE_SMALL,
            label: SIZE_SMALL,
          },
          {
            value: SIZE_MEDIUM,
            label: SIZE_MEDIUM,
          },
        ],
      }
    };
  },
};
</script>
