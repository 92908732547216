<template>
  <div
      class="window-wrapper"
      :class="windowClass"
      :style="windowStyle"
  >
    <div class="window-wrapper-inner" :class="[{ 'bounce-animation': isBouncing }]">
      <no-drag-image :src="imageSrc" />
    </div>
    <div ref="outline">
      <house-part-outline
          @click="onClickButton"
          @shapeLeft="shapeLeft"
          @shapeHovered="shapeHovered"
          :outline-name="windowOutlineName"
          :outline-class="windowOutlineClass"
          :selected="isSelected"
          :highlighted="isHighlighted"
          :highlight-category="category"
          :disabled="notAvailable"
          :modifier="modifier"
          :modifier-multiplier="modifierMultiplier"
          :has-house-task="hasHousePartHouseTask"
      />
    </div>

      <!--<div v-if="hasHousePartHouseTask" class="modifier-outer">
        <modifier-view :type="modifier" :multiplier="modifierMultiplier" :flipped="flipped" :modifier-style="fixedStyle"/>
      </div>-->
  </div>
</template>

<script>
import NoDragImage from "@/components/common/NoDragImage";
import HousePartOutline from "@/components/house/HousePartOutline";
import ModifierView from "../common/ModifierView";
import { mapState } from "vuex";
import { TOP_2, TOP_3 } from "@/enum/WDPlacementIds";
import { DOOR, DOOR_GARAGE, WINDOW_DOUBLE_LARGE, WINDOW_DOUBLE_SMALL, WINDOW_LARGE, WINDOW_SMALL } from "@/enum/WDType";
import { STATE_HIDDEN, STATE_NEW } from "@/enum/HouseState";
import { CATEGORY_DOOR, CATEGORY_WINDOW } from "../../enum/Categories";
import HousePartMixin from "../../mixins/house/HousePartMixin";

export default {
  name: 'WindowDoor',
  components: { ModifierView, HousePartOutline, NoDragImage },
  props: {
    housePartId: {
      type: String,
      required: true,
    },
    housePartData: {
      type: Object,
      required: true,
    },
    housePartState: {
      type: String,
      default: STATE_NEW
    },
    housePartSpriteState: {
      type: String,
      default: ''
    }
  },
  emits: ['click'],
  mixins: [HousePartMixin],
  computed: {
    ...mapState([
      'selectedHouseTask',
      'highlightedHouseParts',
      'selectedHouseTaskIndex',
      'houseTasks'
    ]),
    windowStyle() {
      const style = {};
      style.left = `${this.posX}%`;
      style.top = `${this.posY}%`;
      style.transform = this.flipped ? 'scaleX(-1)' : '';
      return style;
    },
    windowClass() {
      const assetsMap = {
        [WINDOW_DOUBLE_SMALL]: 'window-double-small',
        [WINDOW_DOUBLE_LARGE]: `window-double-large`,
        [DOOR]: `door`,
        [DOOR_GARAGE]: `door-garage`,
        [WINDOW_SMALL]: `window-small`,
        [WINDOW_LARGE]: `window-large`,
      };
      return [assetsMap[this.wdType]];
    },
    windowOutlineName() {
      if (this.housePartId === TOP_2.toString() || this.housePartId === TOP_3.toString()) {
        return 'WindowCut';
      }
      const assetsMap = {
        [WINDOW_DOUBLE_SMALL]: 'WindowDoubleSmall',
        [WINDOW_DOUBLE_LARGE]: `WindowDouble`,
        [DOOR]: `Door`,
        [DOOR_GARAGE]: `Door`,
        [WINDOW_SMALL]: `WindowSingle`,
        [WINDOW_LARGE]: `WindowSingle`,
      };
      return assetsMap[this.wdType];
    },
    windowOutlineClass() {
      if (this.wdType === DOOR) {
        return 'placement-door';
      } else if (this.wdType === DOOR_GARAGE) {
        return 'placement-garage-door';
      }
      return '';
    },
    category() {
      if (this.wdType === DOOR || this.wdType === DOOR_GARAGE) {
        return CATEGORY_DOOR;
      }
      return CATEGORY_WINDOW;
    },
    wdType() {
      return this.housePartData?.wdType || WINDOW_DOUBLE_SMALL;
    },
    posX() {
      return this.housePartData?.x || 0;
    },
    posY() {
      return this.housePartData?.y || 0;
    },
    flipped() {
      return this.housePartData?.flipped || false;
    },
    imageSrc() {
      const isNew = this.housePartState === STATE_NEW;
      let housePartStateParsed = isNew ? 'new' : 'old';

      if (this.housePartSpriteState !== '' && this.housePartSpriteState !== 'default') {
        housePartStateParsed = this.housePartSpriteState;
      }

      // Unfinished from last round should always be shown as old
      if (this.levelUnfinishedRenovationId.includes(this.housePartId)) {
        housePartStateParsed = 'old';
      }

      // Define a require context for windows and doors
      const requireImage = require.context('@/assets/house', true, /\.svg$/);
      const assetsMap = {
        [WINDOW_DOUBLE_SMALL]: `./windows/window_double_small_${housePartStateParsed}.svg`,
        [WINDOW_DOUBLE_LARGE]: `./windows/window_double_large_${housePartStateParsed}.svg`,
        [DOOR]: `./doors/door_${housePartStateParsed}.svg`,
        [DOOR_GARAGE]: `./doors/garage_door_${housePartStateParsed}.svg`,
        [WINDOW_SMALL]: `./windows/window_small_${housePartStateParsed}.svg`,
        [WINDOW_LARGE]: `./windows/window_large_${housePartStateParsed}.svg`,
      };
      return requireImage(assetsMap[this.wdType]) || require('@/assets/house/garage_facade_old.svg');
    },
  },
  methods: {
    // All shared methods are in the mixin
  },
};
</script>

<style lang="scss" scoped>
@keyframes gelowiggle {
  0%, 100% {
    transform: rotate(0deg) scale(1);
  }
  25% {
    transform: rotate(1deg) scale(1.05);
  }
  50% {
    transform: rotate(-1deg) scale(0.95);
  }
  75% {
    transform: rotate(0deg) scale(1.02);
  }
}

.bounce-animation {
  animation: gelowiggle 0.7s ease-in-out;
}

.window-wrapper-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.window-wrapper {
  position: absolute;
  width: 57px;
  height: 40px;

  &.window-double-small {
    height: 13%;
    width: 6.15%;
  }

  &.window-double-large {
    height: 20.3%;
    width: 6.7%;
  }

  &.door {
    height: 29.9%;
    width: 12%;
  }

  &.door-garage {
    height: 27.7%;
    width: 12%;
  }

  &.window-small {
    height: 17.3%;
    width: 3.6%;
  }

  &.window-large {
    height: 19.7%;
    width: 4%;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .modifier-outer {
    position: absolute;
    z-index: 10000000;
    top: 0;
    right: -30px;
  }
}
</style>
