
import HouseOutlinePartData from "@/data/HouseOutlinePartData";
import PointData from "../data/PointData";
import {STATE_HIDDEN, STATE_NEW, STATE_OLD} from "@/enum/HouseState";
import {
  CATEGORY_CLADDING,
  CATEGORY_DOOR,
  CATEGORY_GUTTER,
  CATEGORY_INSULATION,
  CATEGORY_WINDOW
} from "@/enum/Categories";
import {MODIFIER_TYPE_CO2, MODIFIER_TYPE_MONEY, MODIFIER_TYPE_TRASH} from "@/enum/ModifierType";
import {TRASH_DANGEROUS, TRASH_METAL, TRASH_WOOD} from "@/enum/TrashType";
import GameData from "@/data/GameData";
import LevelMapData from "@/data/LevelMapData";
import levelData from "@/data/LevelData";
import pointData from "../data/PointData";

export function getLevelStarsFromId(levelId, currentHighestStarLevels) {
  if (!currentHighestStarLevels.hasOwnProperty(levelId)) {
    return 0;
  }

  const levelStars = currentHighestStarLevels?.[levelId];

  let total = 0;
  for(const roundKey in levelStars) {
    if (!levelStars.hasOwnProperty(roundKey)) {
      continue;
    }
    total += levelStars[roundKey];
  }

  let avg = total / Object.keys(levelStars).length;

  avg = Math.round(avg);

  return avg;
}
export function getInitialHouseState(levelData) {

  const initialHouseState = {};

  const housePartData = levelData.houseParts;

  for (const housePart of housePartData) {

    const id = housePart.id;
    const visible = housePart.visible;
    const available = housePart.available;
    const category = housePart.category;
    const spriteMaterial = housePart.spriteMaterial;
    const spriteState = housePart.spriteState;

    // see if category can be hidden
    const possibleHiddenCategories = [CATEGORY_CLADDING, CATEGORY_WINDOW, CATEGORY_DOOR, CATEGORY_GUTTER];

    initialHouseState[id] = {}

    if (!available) {
      if (!visible && possibleHiddenCategories.includes(category)) {
        initialHouseState[id].state = STATE_HIDDEN;
      } else {
        initialHouseState[id].state = STATE_NEW;
      }
    } else {
      initialHouseState[id].state = STATE_OLD;
    }

    initialHouseState[id].spriteMaterial = spriteMaterial;
    initialHouseState[id].spriteState = spriteState;
  }

  // We go through it again because we need to find the active sprite (the true house part id) for that house part.
  for (const housePart of housePartData) {

    const id = housePart.id;
    const available = housePart.available;
    const spriteMaterial = housePart.spriteMaterial;
    const spriteState = housePart.spriteState;

    if (HouseOutlinePartData.hasOwnProperty(id)) {
      const trueHousePartId = HouseOutlinePartData[id].graphicsHousePartReference;
      if (trueHousePartId && initialHouseState.hasOwnProperty(trueHousePartId)) {
        if (available) {
          initialHouseState[trueHousePartId].state = STATE_OLD;
        }

        if (spriteMaterial !== '') {
          initialHouseState[trueHousePartId].spriteMaterial = spriteMaterial;
        }

        if (spriteState !== '' && spriteState !== 'default') {
          initialHouseState[trueHousePartId].spriteState = spriteState;
        }
      }
    }
  }

  return initialHouseState;
}

export function createEndRoundDataObject(timeRemaining, co2, moneyLeft, trashRemaining, houseTasks) {
  const endRoundData = {};

  endRoundData.time = {};
  endRoundData.time.value = timeRemaining;
  endRoundData.time.points = timeRemaining * PointData.timeRemainingPointsMultiplier;

  endRoundData.money = {};
  endRoundData.money.value = moneyLeft;
  endRoundData.money.points = moneyLeft * PointData.moneyPointsMultiplier;

  endRoundData.co2 = {};
  endRoundData.co2.value = co2;
  endRoundData.co2.points = co2 * PointData.co2PointsMultiplier;

  let value = '';
  let points = 0;

  if (co2 > (GameData.MAX_CO2 * 2/3)) {
    value = 'Grøn';
    points = PointData.co2ColorValue.green;
  }
  else if (co2 > (GameData.MAX_CO2 / 3)) {
    value = 'Gul';
    points = PointData.co2ColorValue.yellow;
  }
  else {
    value = 'Rød';
    points = PointData.co2ColorValue.red;
  }

  endRoundData.co2Color = {};
  endRoundData.co2Color.value = value;
  endRoundData.co2Color.points = points;

  endRoundData.trash = {};
  endRoundData.trash.value = trashRemaining;
  endRoundData.trash.points = trashRemaining * PointData.remainingTrashPointsMultiplier;

  const missingHousePartIds = [];

  let houseTaskMissingCount = 0;
  for (const houseTask of houseTasks) {

    if (!houseTask.done) {
      houseTaskMissingCount++;

      // don't include isolation because they don't look different
      if (houseTask.category !== CATEGORY_INSULATION) {
        missingHousePartIds.push(houseTask.id);
      }
    }
  }

  endRoundData.missingRenovations = {};
  endRoundData.missingRenovations.value = houseTaskMissingCount;
  endRoundData.missingRenovations.points = houseTaskMissingCount * PointData.missingRenovationsPointsMultiplier;

  endRoundData.missingHousePartIds = missingHousePartIds;

  endRoundData.roundPoints = endRoundData.time.points + endRoundData.money.points + endRoundData.co2.points + endRoundData.co2Color.points + endRoundData.missingRenovations.points + endRoundData.trash.points;

  return endRoundData;
}

export function createStartRoundDataObject(houseTasks) {
  const startRoundData = {};

  houseTasks.forEach(task => {
    const category = task.category;
    const modifier = task.modifier;

    if (!startRoundData[category]) {
      startRoundData[category] = {
        amount: 0,
        modifiers: {
          [MODIFIER_TYPE_MONEY]: 0,
          [MODIFIER_TYPE_TRASH]: 0,
          [MODIFIER_TYPE_CO2]: 0,
        }
      };
    }

    startRoundData[category].amount += 1;

    if (modifier) {
      if (!startRoundData[category].modifiers[modifier]) {
        startRoundData[category].modifiers[modifier] = 0;
      }
      startRoundData[category].modifiers[modifier] += 1;
    }
  });

  return startRoundData;
}

export function getStarLevelsFromAllLevels() {
  const starLevelObject = {};

  for (const level in LevelMapData) {
    starLevelObject[level] = getStarLevelsFromLevel(LevelMapData[level].levelData);
  }

  return starLevelObject;
}

export function getStarLevelsFromLevel(levelData) {
  // in this method we're going to assume a lot of things!
  let maxPoints = 0;
  let minPoints = 0;
  const formattedHouseTasks = getFormattedHouseTasks(levelData);

  // CO2 color score: Assumption, all levels can reach red and green level
  maxPoints += PointData.co2ColorValue.green;
  minPoints += PointData.co2ColorValue.red;

  let minTotalPrice = 0;
  let maxTotalPrice = 0;
  let minTotalCO2 = 0;
  let maxTotalCO2 = 0;
  let maxTotalTrash = 0;

  for (const houseTask of formattedHouseTasks) {
    const cards = houseTask.cards;
    const modifier = houseTask.modifier;
    const modifierMultiplier = parseInt(houseTask.modifierMultiplier) || 2;

    let minPrice = Infinity;
    let maxPrice = -Infinity;
    let minCO2 = Infinity;
    let maxCO2 = -Infinity;
    let maxTrash = -Infinity;

    for (const card of cards) {

      const price = parseFloat(card.price);
      const co2 = parseFloat(card.co2);
      const trash = parseFloat(card.trash);

      // filter away invalid cards
      if (price === 0 && co2 === 0 && trash === 0) {
        continue;
      }

      if (price < minPrice) minPrice = price;
      if (price > maxPrice) maxPrice = price;

      if (co2 < minCO2) minCO2 = co2;
      if (co2 > maxCO2) maxCO2 = co2;

      if (trash > maxTrash) maxTrash = trash;
    }

    if (modifier === MODIFIER_TYPE_MONEY) {
      minPrice *= modifierMultiplier;
      maxPrice *= modifierMultiplier;
    } else if (modifier === MODIFIER_TYPE_CO2) {

      minCO2 *= modifierMultiplier;

      maxCO2 *= modifierMultiplier;
    } else if (modifier === MODIFIER_TYPE_TRASH) {
      maxTrash *= modifierMultiplier;
    }

    minTotalPrice += minPrice;
    maxTotalPrice += maxPrice;
    minTotalCO2 += minCO2;
    maxTotalCO2 += maxCO2;
    maxTotalTrash += maxTrash;
  }

  // CO2 value: Assumption everyone can get to mid green or mid red
  const startCO2 = parseInt(levelData.startCo2Min);

  minTotalCO2 = Math.max(0, minTotalCO2);
  maxTotalCO2 = Math.min(GameData.MAX_CO2, minTotalCO2);

  maxPoints += (startCO2 + maxTotalCO2) * pointData.co2PointsMultiplier;
  minPoints += (startCO2 + minTotalCO2) * pointData.co2PointsMultiplier;

  // Time: Worst assumption is running out of time, best is that every task takes 8 seconds.
  // best assumption builds on the start money min value
  const numberOfHouseTasks = formattedHouseTasks.length;
  const time = parseInt(levelData.time);
  maxPoints += Math.max(0, time - (numberOfHouseTasks * 8) - maxTotalTrash * 2);

  // Budget: Worst is below zero, best is the exact amount of the cheapest choice.
  // (remember to remove 0 cost cards)
  // max points is reached by using the least amount of money
  const startMoney = parseInt(levelData.startMoneyMin);
  maxPoints += (startMoney - minTotalPrice) * pointData.moneyPointsMultiplier;
  minPoints += (startMoney - maxTotalPrice) * pointData.moneyPointsMultiplier;

  // Missing renovations: best zero, worst all are missing
  maxPoints += 0;
  minPoints += numberOfHouseTasks * pointData.missingRenovationsPointsMultiplier;

  // Leftover trash: Best zero, worst all
  // because trash is such a giant post then we'll remove some of it so people get to experience one or two stars more
  maxPoints += 0;
  minPoints += (maxTotalTrash * pointData.remainingTrashPointsMultiplier) / 6;

  // starlevels!
  const starLevels = {};
  const co2BaselineCounterPoints = 5000;
  const numberOfStars = PointData.numberOfStars;
  const acceptableImperfectionValue = (maxPoints - minPoints) * 0.3 - co2BaselineCounterPoints;

  for (let i = 1; i <= numberOfStars; i++) {
    const threshold = minPoints + ((maxPoints - minPoints) * (i / numberOfStars));
    starLevels[`star${i}`] = Math.round(threshold - acceptableImperfectionValue);
  }

  return { minPoints, maxPoints, starLevels };
}

export function getFormattedHouseTasks(levelData) {
  const formattedHouseTasks = [];

  const housePartData = levelData.houseParts;

  for (const housePart of housePartData) {
    const available = housePart.available;

    if (available) {

      let taskDetails = {};

      const id = housePart.id;
      const category = housePart.category;
      const cards = housePart.cards;
      const modifier = housePart.modifier;
      const modifierMultiplier = housePart.modifierMultiplier;
      const done = false;

      taskDetails = {
        category, cards, modifier, modifierMultiplier, done
      };

      // now we find the actual id that we need to use.
      // The house has overlapping images and some images covers more than one id
      // not on the windows and doors

      if (HouseOutlinePartData.hasOwnProperty(id)) {
        const trueHousePartId = HouseOutlinePartData[id].graphicsHousePartReference;
        if (trueHousePartId) {
          taskDetails.id = trueHousePartId;
        }
      }

      if (!taskDetails?.id) {
        taskDetails.id = id;
      }

      formattedHouseTasks.push(taskDetails);
    }
  }

  return formattedHouseTasks;
}

export function getHouseTaskById(formattedHouseTasks, id) {
  for (const task of formattedHouseTasks) {
    if (task.id === id) {
      return task;
    }
  }

  return null;
}

export function getFormattedTime(timeInSeconds) {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
}

export function getHouseTaskIndexById(formattedHouseTasks, id) {

  for (let i = 0; i < formattedHouseTasks.length; i++) {
    if (formattedHouseTasks[i].id === id) {
      return i;
    }
  }

  throw new Error(`No task found with ID: ${id}`);

  // return -1;
}

export function categoryCount(houseTasks) {
  const categoryCount = {};

  for (const houseTask of houseTasks) {
    const category = houseTask.category;
    const isDone = houseTask.done;

    if (!categoryCount[category]) {
      categoryCount[category] = {
        total: 1,
        done: isDone ? 1 : 0
      };
    } else {
      categoryCount[category].total += 1;
      if (isDone) {
        categoryCount[category].done += 1;
      }
    }
  }

  return categoryCount;
}

export function getRandomTrashType() {
  const trashTypes = [TRASH_WOOD, TRASH_METAL, TRASH_DANGEROUS];
  const randomIndex = Math.floor(Math.random() * trashTypes.length);
  return trashTypes[randomIndex];
}

export function deepClone(value) {
  if (Array.isArray(value)) {
    return value.map(deepClone);
  } else if (value && typeof value === 'object') {
    const clonedObj = {};
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        clonedObj[key] = deepClone(value[key]);
      }
    }
    return clonedObj;
  } else {
    return value;
  }
}
