<template>
  <teleport to="#modifier-target">
    <modifier-icon
        :type="type"
        :multiplier="multiplier"
        :modifier-style="modifierStyle"
    />
  </teleport>
</template>

<script>
import ModifierIcon from './ModifierIcon.vue';
import {MODIFIER_TYPE_MONEY} from "@/enum/ModifierType";

export default {
  name: 'ModifierView',
  components: { ModifierIcon },
  props: {
    type: {
      type: String,
      default: MODIFIER_TYPE_MONEY,
    },
    multiplier: {
      type: Number,
      default: 2,
    },
    modifierStyle: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/variables.scss";

.modifier-wrapper {
  pointer-events: none;
  z-index: 9997;
  position: absolute;
  width: 69px;
  height: 35px;
  padding: 3px 5px;
  border-radius: 9px;
  font-size: 18px;


  .hidden {
    display: none;
  }

  &.modifier-trash {
    background-color: $colorModifierTrash;
    color: #3D4853;
  }

  &.modifier-co2 {
    background-color: $colorModifierCo2;
    color: #fff;

    .icon {
      width: 42% !important;
      margin-left: 3px !important;
    }
  }

  &.modifier-money {
    background-color: $colorModifierMoney;
    color: #fff;
  }

  .multiplier {
    font-weight: 700;
    font-size: 24px;
    margin-left: 4px;
    line-height: 1.25;
  }

  .icon {
    width: 32%;
    margin-right: 5px;
    margin-left: 3px;
  }

  img {
    width: 100%;
    height: 100%;
  }

}
</style>
