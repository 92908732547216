<template>
  <div class="house-part-wrapper">
    <div :class="[{ 'bounce-animation': isBouncing }]">
      <no-drag-image :src="imageSrc" />
    </div>
    <div ref="outline">
      <house-part-outline
          @click="onClickButton"
          @shapeLeft="shapeLeft"
          @shapeHovered="shapeHovered"
          :outline-name="outlineName"
          :outline-class="outlineClass"
          :selected="isSelected"
          :highlighted="isHighlighted"
          :disabled="notAvailable"
          :highlight-category="currentHouseTaskCategory"
          :modifier="modifier"
          :modifier-multiplier="modifierMultiplier"
          :has-house-task="hasHousePartHouseTask"
      />
    </div>

    <!--<div v-if="hasHousePartHouseTask" class="modifier-outer">
      <modifier-view :type="modifier" :multiplier="modifierMultiplier" :modifier-style="fixedStyle"/>
    </div>-->
  </div>
</template>

<script>
import NoDragImage from "@/components/common/NoDragImage";
import HousePartOutline from "@/components/house/HousePartOutline";
import ModifierView from "../common/ModifierView";
import { mapState } from "vuex";
import {STATE_HIDDEN, STATE_NEW} from "@/enum/HouseState";
import HousePartMixin from "../../mixins/house/HousePartMixin";
import {CATEGORY_INSULATION} from "@/enum/Categories";

export default {
  name: 'HousePart',
  components: { ModifierView, HousePartOutline, NoDragImage },
  props: {
    housePartId: {
      type: String,
      required: true,
    },
    housePartData: {
      type: Object,
      required: true,
    },
    housePartState: {
      type: String,
      default: STATE_NEW
    },
    housePartSpriteMaterial: {
      type: String,
      default: ''
    },
    housePartSpriteState: {
      type: String,
      default: ''
    }
  },
  emits: ['click'],
  mixins: [HousePartMixin],
  computed: {
    ...mapState([
      'co2',
      'selectedHouseTaskIndex',
      'houseTasks',
      'highlightedHouseParts'
    ]),
    outlineClass() {
      return this.housePartData?.outlineClass || '';
    },
    outlineName() {
      return this.housePartData?.outlineName || '';
    },
    outlineCategory() {
      return this.housePartData?.category || null;
    },
    imageNameBase() {
      return this.housePartData?.imageNameBase || '';
    },
    zIndex() {
      return this.housePartData?.zIndex || 0;
    },
    imageSrc() {
      // default behavior
      let housePartStateParsed = this.housePartState === STATE_NEW ? 'new' : 'old';

      // excel sheet overwrite state
      if (this.housePartSpriteState !== '' && this.housePartSpriteState !== 'default') {
        housePartStateParsed = this.housePartSpriteState;
      }

      // Unfinished from last round should always be shown as old
      if (this.levelUnfinishedRenovationId.includes(this.housePartId)) {
        housePartStateParsed = 'old';
      }

      const requireImage = require.context('@/assets/house', true, /\.svg$/);

      let imagePath = `./${this.imageNameBase}_${housePartStateParsed}.svg`;

      if (this.housePartSpriteMaterial !== '') {
        imagePath = `./${this.imageNameBase}_${this.housePartSpriteMaterial}_${housePartStateParsed}.svg`;
      }

      return requireImage(imagePath);
    },
    currentHouseTaskCategory() {
      return this.housePartHouseTask?.category || null;
    }
  },
  methods: {
    // All shared methods are in the mixin
  },
};
</script>

<style lang="scss" scoped>
@keyframes gelowiggle {
  0%, 100% {
    transform: scale(1);
  }
  25% {
    transform: scale(1, 1.05);
  }
  50% {
    transform: scale(1, 0.95);
  }
  75% {
    transform: scale(1, 1.05);
  }
}

.bounce-animation {
  animation: gelowiggle 0.4s ease-in-out;
}

.house-part-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;

  .disabled {
    cursor: default !important;
  }

  img {
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .modifier-outer {
    position: absolute;
    z-index: 10000000;
  }
}
</style>
