<template>
  <div
    class="accessories-controller"
  >
    <div class="accessory" :class="'accessory-' + value.className" v-for="(value, i) in accessoriesData" :key="i">
      <Accessory
          :image-name="value.imageName"
          :accessory-state="getImageState(value)"
          :accessory-round="value.roundIndex"
          :animation-delay="(i * 1000) / 4"
      />
    </div>
  </div>
</template>
<script>

import NoDragImage from "@/components/common/NoDragImage";
import Ground from "@/components/house/Ground";
import House from "@/components/house/House";
import {mapState} from "vuex";
import {getHouseTaskById, getHouseTaskIndexById} from "@/utils/dataUtil";
import Accessory from "@/components/house/accessories/Accessory";
import {STATE_HIDDEN, STATE_NEW, STATE_OLD} from "@/enum/HouseState";
import {CONDITIONAL_TYPE_MONEY} from "@/enum/ConditionalType";

export default {
  name: 'AccessoriesController',
  components: {Accessory, House, Ground, NoDragImage},
  props: {
    accessoriesData: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    ...mapState([
        'houseTasks',
        'gameActionsDisabled',
        'showFinalHouse',
        'money',
        'currentRoundIndex',
    ]),
  },
  methods: {
    getImageState(accessoryData) {
      const conditional = accessoryData?.conditional || [];
      const roundIndex = accessoryData.roundIndex;
      let conditionalMet = true;

      if (conditional !== []) {
        if (conditional.type === CONDITIONAL_TYPE_MONEY) {
          const minVal = conditional.minVal;

          if (this.money <= minVal) {
            conditionalMet = false;
          }
        }
      }

      if ((!accessoryData.visibleFromStart && !this.showFinalHouse) || !conditionalMet || (this.currentRoundIndex < roundIndex && !accessoryData.hasOldNewVersion)) {
        return STATE_HIDDEN;
      }

      if (accessoryData.hasOldNewVersion) {
        if (this.showFinalHouse && this.currentRoundIndex >= roundIndex) {
          return STATE_NEW;
        }

        return STATE_OLD;
      }

      return '';
    }
  },
};
</script>
<style lang="scss" scoped>

.accessories-controller {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 1603/968;
  pointer-events: none;

  .accessory {
    position: absolute;
  }

  .accessory-trees-swing {
    width: 25%;
    right: -6%;
    bottom: 54%;
  }

  .accessory-bushes-background {
    width: 52.5%;
    left: 1%;
    top: -7.3%;
  }

  .accessory-bush-1 {
    width: 6%;
    left: 51.5%;
    top: 50.5%;
  }

  .accessory-bush-2 {
    width: 13%;
    left: 22.2%;
    top: 43.6%;
  }

  .accessory-bushes {
    width: 16%;
    left: 43.2%;
    bottom: 19%;
  }

  .accessory-flowers-1 {
    width: 6%;
    left: 42%;
    bottom: 40.7%;
  }

  .accessory-flowers-2 {
    width: 4%;
    left: 63%;
    bottom: 50%;
  }

  .accessory-light-1 {
    width: 1.2%;
    left: 56.8%;
    bottom: 62.2%;
  }

  .accessory-light-2 {
    width: 1.2%;
    left: 61.5%;
    bottom: 66.7%;
  }

  .accessory-light-3 {
    width: 1.2%;
    right: 15.8%;
    top: 22.7%;
  }

  .accessory-hoop {
    width: 3.6%;
    right: 17.8%;
    top: 21.8%;
  }

  .accessory-postbox {
    width: 4.2%;
    right: 27.4%;
    bottom: 30%;
  }
}

</style>
