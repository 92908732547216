import ApiClientCore from './core';

import HighscoreMixins from './mixins/highscore';

export default class ApiClient extends ApiClientCore {
  // eslint-disable-next-line class-methods-use-this
  mixins() {
    return {
      highscore: HighscoreMixins,
    };
  }
}
