<template>
    <div
      class="modifier-wrapper d-flex"
      :class="modifierClass"
      :style="modifierStyle"
    >
      <no-drag-image :class="iconClass('Trash')" class="icon icon-trash" src="@/assets/ui/icon_trash_blue.svg" />
      <no-drag-image :class="iconClass('CO2')" class="icon icon-co2" src="@/assets/ui/icon_co2_white.svg" />
      <no-drag-image :class="iconClass('Money')" class="icon icon-coin" src="@/assets/ui/icon_coin_white.svg" />
      <div>
        x
      </div>
      <div class="multiplier">
        {{multiplier}}
      </div>
    </div>
</template>
<script>

import NoDragImage from "@/components/common/NoDragImage";
import {MODIFIER_TYPE_MONEY} from "../../enum/ModifierType";

export default {
  name: 'ModifierIcon',
  components: {NoDragImage},
  props: {
    type: {
      type: String,
      default: MODIFIER_TYPE_MONEY,
    },
    multiplier: {
      type: Number,
      default: 2,
    },
    modifierStyle: {
      type: Object,
      default: () => {},
    }
  },
  emits: ['click'],
  computed: {
    modifierClass() {
      const modifierClass = [];

      modifierClass.push('modifier-' + this.type.toLowerCase());

      return modifierClass;
    },

  },
  methods: {
    iconClass(type) {
      const modifierClass = [];

      if (type !== this.type) {
        modifierClass.push('hidden');
      }

      return modifierClass;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/variables.scss";

.modifier-wrapper {
  pointer-events: none;
  width: 69px;
  height: 35px;
  padding: 3px 5px;
  border-radius: 9px;
  font-size: 18px;


  .hidden {
    display: none;
  }

  &.modifier-trash {
    background-color: $colorModifierTrash;
    color: #3D4853;
  }

  &.modifier-co2 {
    background-color: $colorModifierCo2;
    color: #fff;

    .icon {
      width: 42% !important;
      margin-left: 3px !important;
    }
  }

  &.modifier-money {
    background-color: $colorModifierMoney;
    color: #fff;
  }

  .multiplier {
    font-weight: 700;
    font-size: 24px;
    margin-left: 4px;
    line-height: 1.25;
  }

  .icon {
    width: 32%;
    margin-right: 5px;
    margin-left: 3px;
  }

  img {
    width: 100%;
    height: 100%;
  }

}
</style>
