<template>
  <div
      class="trash-wrapper"
      :class="trashClass"
  >
    <!--no-drag-image src="@/assets/ui/icon_trash.svg"/>-->
  <div>
    <trash-chute/>
    <trash-minigame-slideshow/>
  </div>
  </div>
</template>
<script>

import NoDragImage from "@/components/common/NoDragImage";
import {mapState} from "vuex";
import TrashMinigameSlideshow from "./TrashMinigameSlideshow";
import TrashChute from "@/components/trashMinigame/TrashChute";
import {nextTick} from "vue";
export default {
  name: 'TrashMinigameController',
  components: {TrashChute, TrashMinigameSlideshow, NoDragImage},
  props: {
    showTrashGame: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFirstRender: true,
    }
  },
  mounted() {
    nextTick( () => {
      this.isFirstRender =  false;
    });
  },
  watch: {
    trashGameVisible: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.$root.playSound('cardsAppear');
        } else {
          this.$root.playSound('cardsDisappear');
        }
      },
    },
  },
  computed: {
    ...mapState ([
      'currentTrash'
    ]),
    trashGameVisible() {
      console.log(this.currentTrash.length, this.showTrashGame);
      return this.currentTrash.length > 0 && this.showTrashGame;
    },
    trashClass() {
      const trashClass = [];

      if (this.trashGameVisible) {
        trashClass.push('visible');
      } else {
        trashClass.push('hidden');
      }

      if (!this.isFirstRender) {
        trashClass.push('glide-transition');
      }

      return trashClass;
    },
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>

.trash-wrapper {
  // cursor: pointer;
  color: #fff;
  font-weight: 700;
  border-radius: 78px;
  font-size: 45px;
  padding: 9px 0 0 0;
  line-height: 0.9;

  &.glide-transition {
    transition: transform 0.9s cubic-bezier(0.175, 0.735, 0.32, 1.175);
  }

  &.hidden {
    transform: translateX(50%);
  }

  &.visible {
    transform: translateX(-110%);
  }

  img {
    height: 80%;
    margin-right: 10px;
  }
}
</style>
