<template>
  <div
    class="close-button-wrapper"
    @click="onClickButton"
  >
    <no-drag-image src="@/assets/ui/icon-close-x.png" />
  </div>
</template>
<script>

import NoDragImage from "@/components/common/NoDragImage";
export default {
  name: 'CloseButton',
  components: {NoDragImage},
  props: {
  },
  emits: ['click'],
  methods: {
    onClickButton() {
      if (this.disabled) {
        return;
      }

      this.$root.playSound('simpleClick');
      this.$emit('click');
    },
  },
};
</script>
<style lang="scss" scoped>

.close-button-wrapper {
  position: relative;
  width: 38px;
  height: 38px;
  cursor: pointer;
  background-color: #85595A;
  box-shadow: 0px -3.05px 0px 0px #00000066 inset, 0.52px 2.01px 2.27px 0px #FFFFFFB8 inset, 0px 1.09px 2.17px 1px #000000;

  border: 1.57px solid #FFFFFF;
  border-radius: 6px;
  padding: 7px;

  img {
    width: 65%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    img {
      filter: brightness(90%);
    }
  }

  &:active {
    img {
      filter: brightness(80%);
    }
    box-shadow: 0.58px 4px 1.54px 0 #0000004D inset !important;
  }
}
</style>
