import {STATE_HIDDEN, STATE_NEW, STATE_OLD} from "../../enum/HouseState";
import {getLocalStorageValue} from "@/utils/storeUtil";

export default {
  name: 'Simulation',
  state: {
    houseState:{},
    houseTasks: [],
    selectedHouseTaskIndex: -1,
    highlightedHouseParts: [],
    highlightedCategory: '',
  },
  mutations: {
    initiateSimulation: (state, value) => {
      const keysToRetrieve = [
      ];

      keysToRetrieve.forEach(({ key, type }) => {
        const { hasValue, returnValue } = getLocalStorageValue(key, type);

        if (hasValue) {
          if (hasValue) state[key] = returnValue;
        }
      });
    },
    setHouseTasks: (state, houseTasks) => {
      state.houseTasks = houseTasks;
    },
    setSelectedHouseTaskIndex: (state, houseTask) => {
      state.selectedHouseTaskIndex = houseTask;
      state.highlightedCategory = '';
      state.highlightedHouseParts = [];
    },
    setHouseState: (state, value) => {
      state.houseState = value;
    },
    setHighlightedCategory: (state, value) => {
      state.highlightedCategory = value;
      state.selectedHouseTaskIndex = -1;
    },
    upgradeHousePart: (state, housePart) => {
      if (state.houseState.hasOwnProperty(housePart)) {
        state.houseState[housePart].state = STATE_NEW;
      }
    },
    finishCurrentTask: (state) => {
      const housePart = state.houseTasks[state.selectedHouseTaskIndex].id;
      state.houseState[housePart].state = STATE_NEW;

      state.houseTasks[state.selectedHouseTaskIndex].done = true;

      state.selectedHouseTaskIndex = -1;
    },
    setHighlightedHouseParts: (state, houseParts) => {
      state.highlightedHouseParts = houseParts;
    },
    addHighlightedHousePart: (state, housePart) => {
      if (!state.highlightedHouseParts.includes(housePart)) {
        state.highlightedHouseParts.push(housePart);
      }
    },
    removeHighlightedHousePart: (state, housePart) => {
      const index = state.highlightedHouseParts.indexOf(housePart);
      if (index !== -1) {
        state.highlightedHouseParts.splice(index, 1);
      }
    },
  },
  getters: {
    houseState (state) {
      return state.houseState;
    },
    highlightedHouseParts (state) {
      return state.highlightedHouseParts;
    },
    highlightedCategory (state) {
      return state.highlightedCategory;
    },
    houseTasks (state) {
      return state.houseTasks;
    },
    selectedHouseTaskIndex (state) {
      return state.selectedHouseTaskIndex;
    },
  },
}
