<template>
  <div v-if="visible" class="fireworks-container">
    <img
        v-for="(image, index) in images"
        :key="index"
        :src="image"
        :class="['firework', `firework-${index}`]"
    />
  </div>
</template>

<script>
import {randomBetween} from "@/utils/mathUtil";

export default {
  name: "FireworksVFX",
  data() {
    return {
      imagePaths: [
        require('@/assets/ui/vfx/dust1.png'),
        require('@/assets/ui/vfx/dust1.png'),
        require('@/assets/ui/vfx/dust2.png'),
        require('@/assets/ui/vfx/dust3.png')
      ],
      images: [],
      visible: true,
    };
  },
  methods: {
    playAnimation() {
      // reset firework
      this.hideFireworks();
      if (this.animationTimeout) {
        clearTimeout(this.animationTimeout);
      }

      setTimeout(() => {
        this.randomizeImages();
        this.visible = true;
        this.animationTimeout = setTimeout(() => {
          this.visible = false;
        }, 800);
      }, 50);
    },

    hideFireworks() {
      this.visible = false;
    },
    randomizeImages() {
      const amountOfImages = Math.floor(randomBetween(1, 4,0)) + 2;


      this.images = Array(amountOfImages)
          .fill(null)
          .map(() => this.imagePaths[Math.floor(Math.random() * this.imagePaths.length)]);
    },
  },
  hideFireworks() {
    this.visible = false;
  },
  mounted() {
    // this.playAnimation();
  },
};
</script>

<style scoped>
.fireworks-container {
  position: absolute;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.firework {
  position: absolute;
  width: 50px; /* Adjust size as needed */
  height: 50px;
  opacity: 0;
  animation: explode 0.5s forwards;
}

.firework-0 { animation-delay: 0s; }
.firework-1 { animation-delay: 0.07s; }
.firework-2 { animation-delay: 0.12s; }
.firework-3 { animation-delay: 0.2s; }
.firework-4 { animation-delay: 0.27s; }
.firework-5 { animation-delay: 0.34s; }

@keyframes explode {
  0% {
    transform: translate(0, 0) scale(0.1) rotate(0deg); /* Starts without rotation */
    opacity: 1;

    -webkit-animation-timing-function: ease-out;
  }
  100% {
    /* Combines final translate, scale, and rotation */
    transform: translate(calc(20px * var(--x)), calc(15px * var(--y))) scale(0.8) rotate(calc(var(--alpha) * 1deg));
    opacity: 0;

    -webkit-animation-timing-function: ease-in;
  }
}

/* Define directions for each firework */
.firework-0 {
  --x: 1;
  --y: -1;
  --alpha: 66;
}
.firework-1 {
  --x: -1;
  --y: -1;
  --alpha: -66;
}
.firework-2 {
  --x: 1;
  --y: 1;
  --alpha: 122
}
.firework-3 {
  --x: -1;
  --y: 1;
  --alpha: 96;
}
.firework-4 {
  --x: 0;
  --y: -1.5;
  --alpha: 300;
}
.firework-5 {
  --x: 0;
  --y: 1.5;
  --alpha: -68;
}
</style>
