<template>
  <div class="home-view">
    <div class="highlight-wrapper">
      <no-drag-image src="@/assets/house/highlight_new.svg" />
    </div>
    <div class="logo-wrapper">
      <no-drag-image :class="LogoClass" src="@/assets/ui/logo.png" />
    </div>
    <div class="house-wrapper d-flex flex-wrap align-self-center">
      <no-drag-image :class="houseClass" src="@/assets/ui/front_screen_house.png" />
    </div>

    <div class="button-wrapper d-flex">
      <app-button :class="buttonClass" class='vidha-button vidha-button-large' @click="onStartClicked">Start</app-button>
    </div>
  </div>
</template>

<script>
import NoDragImage from "@/components/common/NoDragImage"
import AppButton from "@/components/common/AppButton";
import {mapState} from "vuex";
import Weather from "@/components/house/Weather";

export default {
  name: 'HomeView',
  components: {
    Weather,
    NoDragImage, AppButton},
  data() {
    return {
      animateLogo: false,
      animateHouse: false,
      animateButton: false,
    }
  },
  watch: {
  },
  mounted() {
    setTimeout(() => {
      this.animateHouse = true;
    }, 350)
    setTimeout(() => {
      this.animateLogo = true;
    }, 800)
    setTimeout(() => {
      this.animateButton = true;
    }, 1250)
  },
  computed: {
    ...mapState([
    ]),
    LogoClass() {
      return this.animateLogo ? 'bounce-in-bck' : 'hidden';
    },
    houseClass() {
      return this.animateHouse ? 'bounce-in-bck' : 'hidden';
    },
    buttonClass() {
      return this.animateButton ? 'bounce-in-bck' : 'hidden';
    },
  },
  methods: {
    onStartClicked() {
      // this.$root.playAmbientSound();
      this.$nextTick( () => {

        this.$router.push('/game')
      })
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables.scss";
@import "@/sass/mixins.scss";

$exampleVariable: 20px;

@include responsive-phone {

}

@include responsive-desktop-small {

}

@include responsive-desktop {

}

@include responsive-desktop-large {

}

@include responsive-desktop-x-large {

}


.home-view {
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 1rem;

  .logo-wrapper {
    width: 35vh;
    position: absolute;
    top: 4vh;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
  }

  .house-wrapper {
    width: 150vh;
    position: absolute;
    top: 9vh;
    left: 50%;
    transform: translateX(-45%);
    z-index: 2;
  }

  .highlight-wrapper {
    width: 220vh;
    position: absolute;
    top: -26vh;
    left: 45%;
    z-index: -1;
    transform: translateX(-50%);
  }

  .button-wrapper {
    width: 260px;
    position: absolute;
    top: 83vh;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
  }

  .hidden {
    opacity: 0;
  }

}

</style>
