<template>
  <div
    class="house-controller house-placement"
  >
    <Ground></Ground>
    <AccessoriesController :accessories-data="backgroundAccessories"/>
    <House @partClick="onPartClicked"></House>
    <AccessoriesController :accessories-data="foregroundAccessories"/>
    <div v-if="disabled || gameActionsDisabled" class="click-wrapper house-placement">

    </div>
  </div>
</template>
<script>

import NoDragImage from "@/components/common/NoDragImage";
import Ground from "@/components/house/Ground";
import House from "@/components/house/House";
import {mapState} from "vuex";
import {getHouseTaskById, getHouseTaskIndexById} from "@/utils/dataUtil";
import houseAccessoriesData from "@/data/HouseAccessoriesData";
import AccessoriesController from "@/components/house/accessories/AccessoriesController";

export default {
  name: 'HouseController',
  components: {AccessoriesController, House, Ground, NoDragImage},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState([
        'houseTasks',
        'gameActionsDisabled'
    ]),
    foregroundAccessories() {
      return houseAccessoriesData.foreground;
    },
    backgroundAccessories() {
      return houseAccessoriesData.background;
    }
  },
  methods: {
    onPartClicked(partId) {

      if (this.disabled) {
        return;
      }


      this.$root.playSound('simpleClick');
      const newHouseTaskIndex = getHouseTaskIndexById(this.houseTasks, partId);
      this.$store.commit('setSelectedHouseTaskIndex', newHouseTaskIndex);


      // this.$store.commit('upgradeHousePart', partId);
    },
  },
};
</script>
<style lang="scss" scoped>

.house-controller {
  width: 80%;
  max-width: 1400px;
  height: 80%;
  position: absolute;
  z-index: 3;
}

.house-placement {
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.click-wrapper {
  width: 200%;
  height: 200%;
  position: absolute;
  z-index: 1000;
}

</style>
