<template>
  <div
    class="slider-wrapper"
  >
      <div class="slider-fill-wrapper" :style="fillStyle"/>
    <div class="slider-text">
      {{donePercentage}}%
    </div>
    </div>
</template>
<script>

import NoDragImage from "@/components/common/NoDragImage";

export default {
  name: 'SliderView',
  components: {NoDragImage},
  props: {
    donePercentage: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    fillStyle() {
      const fillStyle = {};

      fillStyle.width = this.donePercentage + '%';

      return fillStyle;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/variables.scss";

.slider-wrapper {
  pointer-events: none;
  background-color: #fff;
  border-radius: 20px;
  width: 100%;
  border: 4px solid #131B26;
  color: $colorUiDarkBlue;
  font-weight: 800;
  position: relative;
  padding: 0 4px;

  .slider-text {
    position: relative;
  }

  .slider-fill-wrapper {
    border-radius: 20px;
    position: absolute;
    height: 75%;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
    background-color: $colorUiHighlight;
    max-width: calc(100% - 8px);

    transition: width $transitionDuration $tinyBounceTimingFunction;
  }
}

</style>
