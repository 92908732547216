<template>
  <div class="action-box-content-end-round">

    <div class="d-flex justify-content-end modifier-explainer">
      <div class="modifier-explainer-icon"  v-for="(modifierKey) in modifierOrder" :class="'modifier-' + modifierKey.toLowerCase()">
        <modifier-icon :multiplier="2" :type="modifierKey"/>
      </div>
    </div>

    <div class="d-flex mt-2 blue-block justify-content-between metric-wrapper" v-for="(dataItem, key) in taskData">

        <div class="d-flex">
          <no-drag-image :src="getImgSrc(key)"/>
          <div>
            {{getCategoryName(key)}}
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <div class="metric-value"  v-for="(modifierKey) in modifierOrder" :class="'modifier-' + modifierKey.toLowerCase()">
            {{ dataItem.modifiers[modifierKey] }}
          </div>
        </div>
    </div>

    <div class="big-blue-block blue-block d-flex justify-content-between">
      <div class="d-flex">
        <no-drag-image src="@/assets/ui/icon_time.svg"/>
        <div>Tid</div>
      </div>
      <div class="block-value">
        {{ formattedTime }}
      </div>
    </div>

    <div class="big-blue-block blue-block d-flex justify-content-between">
      <div class="d-flex">
        <no-drag-image src="@/assets/ui/icon_coin_white.svg"/>
        <div>Budget</div>
      </div>
      <div class="block-value">
        {{ money }}
      </div>
    </div>
  </div>
</template>
<script>

import NoDragImage from "@/components/common/NoDragImage";
import {getFormattedTime} from "@/utils/dataUtil";
import CategoryData from "@/data/CategoryData";
import {MODIFIER_TYPE_CO2, MODIFIER_TYPE_MONEY, MODIFIER_TYPE_TRASH} from "@/enum/ModifierType";
import ModifierIcon from "@/components/common/ModifierIcon";
export default {
  name: 'ActionBoxContentStartRound',
  components: {ModifierIcon, NoDragImage},
  props: {
    taskData: {
      type: Object,
      required: true,
    },
    money: {
      type: Number,
      required: true,
    },
    time: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      modifierOrder: [
          MODIFIER_TYPE_MONEY,
          MODIFIER_TYPE_TRASH,
          MODIFIER_TYPE_CO2
      ]
    }
  },
  computed: {
    roundPoints() {
      return this.pointData?.roundPoints || {};
    },
    formattedTime() {
      return getFormattedTime(this.time);
    }
  },
  methods: {
    getImgSrc(iconName) {
      const requireImage = require.context('@/assets/ui/categoryIcons', true, /\.png$/);
      const imagePath = `./icon_category_${iconName}.png`;
      return requireImage(imagePath);
    },
    getCategoryName(id) {
      return CategoryData?.[id].categoryName || '';
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/variables.scss";

$textPadding: 24px;
$borderRadius: 4px;
$innerMargin: 4px;

.action-box-content-end-round {
  padding: 10px;
}

.modifier-explainer {
  .modifier-explainer-icon {
    margin-left: 15px;
    margin-right: 20px;
  }
}

.blue-block {
  padding: 3px 10px;
  background-color: $colorUiDarkBlue;
  border-radius: $borderRadius;
}

.big-blue-block {
  font-size: 28px;
  font-weight: 700;
  width: 100%;
  margin: 10px 38px 10px auto;
  padding-right: 7px !important;

  .block-value {
    width: 208px;
    color: $colorUiDarkBlue;
    border-radius: $borderRadius;
    margin: 2px 0;
    line-height: 1.2;
    padding-top: 2px;
    background-color: #fff;
  }

  img {
    width: 40px;
    padding-right: 10px;
  }
}

.metric-wrapper {
  width: 100%;
  padding: 4px 0.5% 4px 2.5%;
  font-size: 18px;
  font-weight: 700;


  .metric-value {
    width: 96px;
    color: $colorUiDarkBlue;
    border-radius: $borderRadius;
    margin: 2px $innerMargin;
    line-height: 1.2;
    padding-top: 3px;

    &.modifier-trash {
      background-color: $colorModifierTrash;
    }

    &.modifier-co2 {
      color: #fff;
      background-color: $colorModifierCo2;
    }

    &.modifier-money {
      color: #fff;
      background-color: $colorModifierMoney;
    }
  }

  img {
    width: 40px;
    padding-right: 10px;
  }

  .metric-points {
    width: 25%;
    margin-left: 9px;
  }

}
</style>
