<template>
  <div class="action-box">
    <div class="action-box-title-wrapper d-flex justify-content-between">
      <div class="action-box-title" v-for="title in titles">
        {{ title }}
      </div>
      <div v-if="showCloseButton" class="close-button">
        <close-button @click="onClickButton"/>
      </div>
    </div>
    <div class="action-box-content">
      <slot/>
    </div>
  </div>
</template>
<script>

import NoDragImage from "@/components/common/NoDragImage";
import CloseButton from "@/components/common/CloseButton";
export default {
  name: 'ActionBox',
  components: {CloseButton, NoDragImage},
  emits: ['click'],
  props: {
    titles: {
      type: Array,
      default: [],
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    onClickButton() {
      this.$emit('click');
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/variables.scss";

$textPadding: 24px;

.action-box {
  border-radius: $globalBorderRadius;
  background-color: $colorUiBlue;
  color: white;
  max-width: 800px;
  margin: auto;

  .close-button {
    position: absolute;
    top: 4px;
    right: 5px;
    transform: scale(0.8);
  }

  .action-box-title-wrapper {
    background-image: linear-gradient($colorUiBlue, $colorUiDarkBlue);
    margin-bottom: 5px;
    font-size: 22px;
    padding: 6px $textPadding;
    border-radius: $globalBorderRadius $globalBorderRadius 0 0;
    text-transform: uppercase;
    font-weight: 800;
  }

  .action-box-title:last-child {
    padding-right: $textPadding * 3;
  }

  .action-box-content {
    padding: 6px 6px 35px;
  }

}
</style>
