// These are placements from left to right

export const TOP_1 = 'TOP_1';
export const TOP_2 = 'TOP_2';
export const TOP_3 = 'TOP_3';
export const TOP_4 = 'TOP_4';
export const BOTTOM_1 = 'BOTTOM_1';
export const BOTTOM_2 = 'BOTTOM_2';
export const BOTTOM_3 = 'BOTTOM_3';
export const BOTTOM_4 = 'BOTTOM_4';
export const BOTTOM_5 = 'BOTTOM_5';
export const BOTTOM_6 = 'BOTTOM_6';
export const BOTTOM_7 = 'BOTTOM_7';
export const BOTTOM_8 = 'BOTTOM_8';
export const GARAGE_1 = 'GARAGE_1';
