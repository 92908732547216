<template>
  <div
    class="category-tasks"
  >
    <ui-box title="" box-type="dark">
      <template #header>
        <div class="p-2">
          <slider-view :done-percentage="donePercentage"/>
        </div>
      </template>
      <template #content>
        <div class="pb-3">
          <category-button :category-id="categoryId"
                           v-for="(category, categoryId) in categoryCount"
                           :total-items="category.total"
                           :finished-items="category.done"
                           :key="categoryId"
                           :disabled="disabled"
                           :selected="highlightedCategory === categoryId || highlightAll"
                           @click="clickCategory(categoryId)"/>
        </div>
      </template>
    </ui-box>


  </div>
</template>
<script>

import NoDragImage from "@/components/common/NoDragImage";
import categoryData from "@/data/CategoryData";
import {mapState} from "vuex";
import CategoryButton from "@/components/common/CategoryButton";
import {categoryCount} from "@/utils/dataUtil";
import UiBox from "@/components/common/UiBox";
import SliderView from "@/components/common/SliderView";

export default {
  name: 'CategoryTasks',
  components: {SliderView, UiBox, CategoryButton, NoDragImage},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    highlightAll: {
      type: Boolean,
      default: false,
    },
    categoryId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      categoryCount: {},
      donePercentage: 0,
    }
  },
  emits: ['click'],
  computed: {
    ...mapState([
        'houseTasks',
        'showRoundSummary',
        'gameActionsDisabled',
        'highlightedCategory'
    ]),
  },
  mounted() {
    this.getCategoryTasks();
  },
  watch: {
    houseTasks: {
      handler(newValue, oldValue) {
        this.getCategoryTasks(newValue);
        this.getDonePercentage();
      },
      deep: true // This makes the watcher deep
    }
  },
  methods: {
    getCategoryTasks() {
      this.categoryCount = categoryCount(this.houseTasks);
    },
    getDonePercentage() {
      let total = 0;
      let doneTotal = 0;

      for (const categoryItemKey in this.categoryCount) {
        const categoryItem = this.categoryCount[categoryItemKey];
        total += categoryItem.total;
        doneTotal += categoryItem.done;
      }

      this.donePercentage = Math.round(doneTotal / total * 100);
    },
    clickCategory(cateGoryId) {
      if (this.disabled || this.gameActionsDisabled) {
        return;
      }

      this.$store.commit('setHighlightedCategory', cateGoryId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/mixins.scss";

@include responsive-phone {

  .category-tasks {
    transform: translate(-10%) scale(0.8);
  }
}

@include responsive-desktop-small {

  .category-tasks {
    transform: translate(-10%) scale(0.8);
  }
}

@include responsive-desktop {
  .category-tasks {
    transform: translate(-10%) scale(0.8);
  }
}

@include responsive-desktop-large {
  .category-tasks {
    transform: scale(1);
  }
}

@include responsive-desktop-x-large {

}

</style>
