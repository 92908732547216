<template>
  <select :class="class" v-model="modelValueInner" :disabled="disabled">
    <option :value="placeholderValue" v-if="placeholderText">{{ placeholderText }}</option>
    <option v-for="option in options" :key="option.value" :value="option.value">{{ option.label }}</option>
  </select>
</template>

<script>
export default {
  name: 'bootstrap-select',
  props: {
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    class: {
      type: [String, Array],
      default: 'form-control',
    },
    modelValue: {
      type: [String, Number, Boolean],
    },
    placeholderValue: {
      default: '',
      type: [String, Number, Boolean],
    },
    placeholderText: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modelValueInner: '',
    };
  },
  emits: ['update:modelValue'],
  mounted () {
    this.setModelValueInnerFromModelValue();
  },
  methods: {
    setModelValueInnerFromModelValue() {
      if (this.modelValue === this.modelValueInner) {
        return;
      }

      if (this.modelValue === undefined || this.modelValue === null) {
        this.modelValueInner = this.placeholderValue;
      } else {
        this.modelValueInner = this.modelValue;
      }
    }
  },
  watch: {
    modelValue() {
      this.setModelValueInnerFromModelValue();
    },
    modelValueInner() {
      if (this.modelValue === this.modelValueInner) {
        return;
      }

      if (this.disabled) {
        this.modelValueInner = this.modelValue;

        return; // Ignore
      }

      this.$emit('update:modelValue', this.modelValueInner);
    },
  },
};
</script>
