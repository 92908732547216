import {
    BOTTOM_1,
    BOTTOM_2,
    BOTTOM_3,
    BOTTOM_4,
    BOTTOM_5,
    BOTTOM_6, BOTTOM_7, BOTTOM_8, GARAGE_1,
    TOP_1,
    TOP_2,
    TOP_3,
    TOP_4
} from "@/enum/WDPlacementIds";
import {DOOR, DOOR_GARAGE, WINDOW_DOUBLE_LARGE, WINDOW_DOUBLE_SMALL, WINDOW_LARGE} from "@/enum/WDType";
export default {
    [TOP_1]: {
        wdType: WINDOW_DOUBLE_SMALL,
        x: 6.24,
        y: 28.6,
        flipped: false
    },
    [TOP_2]: {
        wdType: WINDOW_DOUBLE_SMALL,
        x: 15.5,
        y: 37.6,
        flipped: false
    },
    [TOP_3]: {
        wdType: WINDOW_DOUBLE_SMALL,
        x: 47,
        y: 37.6,
        flipped: true
    },
    [TOP_4]: {
        wdType: WINDOW_DOUBLE_SMALL,
        x: 56.2,
        y: 28.6,
        flipped: true
    },
    [BOTTOM_1]: {
        wdType: WINDOW_DOUBLE_LARGE,
        x: 3.5,
        y: 44,
        flipped: false
    },
    [BOTTOM_2]: {
        wdType: WINDOW_DOUBLE_LARGE,
        x: 11,
        y: 51,
        flipped: false
    },
    [BOTTOM_3]: {
        wdType: WINDOW_DOUBLE_LARGE,
        x: 18.5,
        y: 58,
        flipped: false
    },
    [BOTTOM_4]: {
        wdType: WINDOW_LARGE,
        x: 28.2,
        y: 59,
        flipped: true
    },
    [BOTTOM_5]: {
        wdType: WINDOW_LARGE,
        x: 36.5,
        y: 59,
        flipped: false
    },
    [BOTTOM_6]: {
        wdType: WINDOW_DOUBLE_LARGE,
        x: 43.7,
        y: 58,
        flipped: true
    },
    [BOTTOM_7]: {
        wdType: DOOR,
        x: 49.4,
        y: 53,
        flipped: true
    },
    [BOTTOM_8]: {
        wdType: WINDOW_DOUBLE_LARGE,
        x: 57.9,
        y: 44,
        flipped: true
    },
    [GARAGE_1]: {
        wdType: DOOR_GARAGE,
        x: 63.5,
        y: 44.5,
        flipped: false
    },
}
