import {CONDITIONAL_TYPE_MONEY} from "@/enum/ConditionalType";

export default {
    foreground: [
        {
            imageName: 'bush',
            hasOldNewVersion: false,
            visibleFromStart: false,
            className: 'bush-1',
            roundIndex: 0
        },
        {
            imageName: 'bush',
            hasOldNewVersion: true,
            visibleFromStart: true,
            className: 'bush-2',
            roundIndex: 1
        },
        {
            imageName: 'bushes',
            hasOldNewVersion: true,
            visibleFromStart: true,
            className: 'bushes',
            roundIndex: 0
        },
        {
            imageName: 'flowers',
            hasOldNewVersion: false,
            visibleFromStart: false,
            className: 'flowers-1',
            roundIndex: 0
        },
        {
            imageName: 'flowers',
            hasOldNewVersion: false,
            visibleFromStart: false,
            className: 'flowers-2',
            roundIndex: 1
        },
        {
            imageName: 'postbox',
            hasOldNewVersion: false,
            visibleFromStart: false,
            className: 'postbox',
            roundIndex: 1
        },
        {
            imageName: 'light',
            hasOldNewVersion: false,
            visibleFromStart: false,
            className: 'light-1',
            roundIndex: 0
        },
        {
            imageName: 'light',
            hasOldNewVersion: false,
            visibleFromStart: false,
            className: 'light-2',
            roundIndex: 0
        },
        {
            imageName: 'light',
            hasOldNewVersion: false,
            visibleFromStart: false,
            className: 'light-3',
            conditional: {
                type: CONDITIONAL_TYPE_MONEY,
                minVal: 0,
            },
            roundIndex: 1
        },
        {
            imageName: 'hoop',
            hasOldNewVersion: false,
            visibleFromStart: false,
            className: 'hoop',
            conditional: {
                type: CONDITIONAL_TYPE_MONEY,
                minVal: 0,
            },
            roundIndex: 1
        },
    ],
    background: [
        {
            imageName: 'trees_right',
            hasOldNewVersion: true,
            visibleFromStart: true,
            className: 'trees-swing',
            roundIndex: 1
        },
        {
            imageName: 'background_bushes',
            hasOldNewVersion: true,
            visibleFromStart: true,
            className: 'bushes-background',
            roundIndex: 0
        },
    ],
}
