
export function getLocalStorageValue(storeKey, type = 'string') {
  const item = localStorage.getItem(storeKey);
  if (!item) {
    return {
      hasValue: false,
      returnValue: type === 'object' ? {} : type === 'array' ? [] : type === 'integer' ? 0 : ''
    };
  }

  let returnValue;
  switch (type) {
    case 'object':
      returnValue = JSON.parse(item);
      break;
    case 'array':
      returnValue = JSON.parse(item);
      break;
    case 'integer':
      returnValue = parseInt(item, 10);
      break;
    default:
      returnValue = item;
  }

  return {
    hasValue: true,
    returnValue
  };
}
