// src/mixins/HousePartMixin.js
import { mapState } from "vuex";
import {STATE_HIDDEN, STATE_NEW, STATE_OLD} from "@/enum/HouseState";
import { getHouseTaskById } from "@/utils/dataUtil";
import { MODIFIER_TYPE_MONEY } from "@/enum/ModifierType";
import {nextTick} from "vue";

export default {
    data() {
        return {
            isBouncing: false,
            firstHouseStateChange: true,
        };
    },
    mounted() {
        setTimeout(() => {
            this.firstHouseStateChange = false;
        }, 2200);
    },
    watch: {
        // Define your watcher here
        housePartState(newState, oldState) {
            if (this.firstHouseStateChange) {
                this.firstHouseStateChange = false;
                return;
            }

            if (newState !== oldState) {
                this.triggerBounce();
            }
        },
    },
    computed: {
        ...mapState([
            'selectedHouseTaskIndex',
            'houseTasks',
            'highlightedHouseParts',
            'levelUnfinishedRenovationId'
        ]),
        notAvailable() {
            return this.housePartState === STATE_NEW || this.housePartState === STATE_HIDDEN;
        },
        currentHouseTask() {
            if (this.selectedHouseTaskIndex === -1) {
                return null;
            }
            return this.houseTasks[this.selectedHouseTaskIndex];
        },
        housePartHouseTask() {
            return getHouseTaskById(this.houseTasks, this.housePartId);
        },
        hasHousePartHouseTask() {
            return this.housePartHouseTask !== null;
        },
        modifier() {
            return this.housePartHouseTask?.modifier || MODIFIER_TYPE_MONEY;
        },
        modifierMultiplier() {
            return parseInt(this.housePartHouseTask?.modifierMultiplier) || 2;
        },
        isSelected() {
            if (!this.currentHouseTask) {
                return false;
            }
            return this.currentHouseTask.id === this.housePartId;
        },
        isHighlighted() {
            return this.highlightedHouseParts.includes(this.housePartId) && this.hasHousePartHouseTask;
        },
    },
    methods: {
        onClickButton() {
            if (this.notAvailable) {
                return;
            }
            this.$emit('click', this.housePartId);
        },
        shapeHovered() {
            if (this.notAvailable) {
                return;
            }
            this.$store.commit('addHighlightedHousePart', this.housePartId);
        },
        shapeLeft() {
            if (this.notAvailable) {
                return;
            }
            this.$store.commit('removeHighlightedHousePart', this.housePartId);
        },
        triggerBounce() {
            this.isBouncing = true;
            setTimeout(() => {
                this.isBouncing = false;
            }, 500);
        }
    },
};
