// These are placements from left to right

export const LEVEL_1_ROUND_1 = 'LEVEL_1_ROUND_1';
export const LEVEL_1_ROUND_2 = 'LEVEL_1_ROUND_2';
export const LEVEL_2_ROUND_1 = 'LEVEL_2_ROUND_1';
export const LEVEL_2_ROUND_2 = 'LEVEL_2_ROUND_2';
export const LEVEL_3_ROUND_1 = 'LEVEL_3_ROUND_1';
export const LEVEL_3_ROUND_2 = 'LEVEL_3_ROUND_2';
export const LEVEL_4_ROUND_1 = 'LEVEL_4_ROUND_1';
export const LEVEL_4_ROUND_2 = 'LEVEL_4_ROUND_2';
export const LEVEL_5_ROUND_1 = 'LEVEL_5_ROUND_1';
export const LEVEL_5_ROUND_2 = 'LEVEL_5_ROUND_2';
export const LEVEL_6_ROUND_1 = 'LEVEL_6_ROUND_1';
export const LEVEL_6_ROUND_2 = 'LEVEL_6_ROUND_2';
