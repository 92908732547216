<template>
  <div>
    <div
        class="card-wrapper"
        :class="cardClass"
        @click.prevent.stop="onClickButton"
    >
      <div class="d-flex justify-content-around title-wrapper">
        {{ cardTitle }}
      </div>

      <div class="info-wrapper card-inner">
        {{ text }}
      </div>

      <div class="co2-wrapper d-flex justify-content-between card-inner card-inner-dark">
        <div>
          CO<sub>2</sub>
        </div>

        <div class="d-flex justify-content-end">
          <div class="co2-icon">
            <no-drag-image v-if="co2 < 0" src="@/assets/ui/arrow_red.svg" />
            <no-drag-image v-if="co2 > 0" src="@/assets/ui/arrow_green.svg" />
          </div>
          {{ Math.abs(co2) }}
        </div>

      </div>


        <div class="money-wrapper d-flex justify-content-between card-inner card-inner-dark">
          Pris
          <div class="d-flex justify-content-end">
          <div class="money-icon">
            <no-drag-image src="@/assets/ui/icon_coin.png" />
          </div>
          {{ price }}
        </div>
      </div>

      <div class="trash-wrapper">
        <div class="trash-icons d-flex justify-content-between">
          <div
              class="trash-icon"
              :class="number > trash ? 'empty' : ''"
              v-for="number in 5"
              :key="number"
          />
        </div>
      </div>
    </div>


    <div v-if="cardIndex===currentSelectedIndex" class="card-highlight">
      <no-drag-image src="@/assets/ui/cards/card_highlight.png" />
    </div>

    <div v-if="cardIndex===currentSelectedIndex" class="vidha-button use-button" @click="onClickUseButton">
      {{buttonText}}
    </div>

  </div>

</template>
<script>

import NoDragImage from "@/components/common/NoDragImage";
import {CARD_TYPE_RECYCLE, CARD_TYPE_RENOVATE, CARD_TYPE_BUY} from "../../enum/CardType";
import {mapState} from "vuex";
export default {
  name: 'Card',
  components: {NoDragImage},
  props: {
    currentSelectedIndex: {
      type: Number,
      default: null,
    },
    cardIndex: {
      type: Number,
      required: true,
    },
    cardData: {
      type: Object,
      required: true,
    },
  },
  emits: ['click', 'clickUse'],
  computed: {
    ...mapState([
        'money'
    ]),
    cardType() {
      return this.cardData?.type || CARD_TYPE_RENOVATE;
    },
    price() {
      return parseInt(this.cardData?.price) || 0;
    },
    trash() {
      return parseInt(this.cardData?.trash) || 0;
    },
    co2() {
      return parseInt(this.cardData?.co2) || 0;
    },
    title() {
      return this.cardData?.title || '';
    },
    text() {
      return this.cardData?.text || '';
    },
    notAvailable() {
      return (this.co2 === 0 && this.trash === 0 && this.price === 0) || this.money <= 0;
    },
    cardClass() {
      const cardClass = [];

      if (this.cardType === CARD_TYPE_RENOVATE) {
        cardClass.push('card-renovate');
      } else if (this.cardType === CARD_TYPE_RECYCLE) {
        cardClass.push('card-recycle');
      } else if (this.cardType === CARD_TYPE_BUY) {
        cardClass.push('card-buy');
      }

      if (this.notAvailable) {
        cardClass.push('card-not-available');
      }

      const ua = navigator.userAgent.toLowerCase();
      if (ua.includes('safari') && !ua.includes('chrome')) {
        cardClass.push('safari');
      }

      return cardClass;
    },
    cardTitle() {
      if (this.cardType === CARD_TYPE_RENOVATE) {
        return "RENOVER";
      } else if (this.cardType === CARD_TYPE_RECYCLE) {
        return "GENBRUG";
      } else if (this.cardType === CARD_TYPE_BUY) {
        return "KØB NYT";
      }
    },
    buttonText() {
      if (this.cardType === CARD_TYPE_RENOVATE) {
        return "RENOVER NU";
      } else if (this.cardType === CARD_TYPE_RECYCLE) {
        return "GENBRUG NU";
      } else if (this.cardType === CARD_TYPE_BUY) {
        return "KØB NYT NU";
      }
    },
  },
  methods: {
    onClickButton() {
      if (this.disabled || this.notAvailable) {
        return;
      }

      this.$root.playSound('simpleClick');
      this.$emit('click', this.cardIndex);
    },
    onClickUseButton() {
      if (this.disabled || this.notAvailable) {
        return;
      }

      if (this.cardType === CARD_TYPE_RENOVATE) {
        this.$root.playSound('renovate');
      } else if (this.cardType === CARD_TYPE_RECYCLE) {
        this.$root.playSound('recycle');
      } else if (this.cardType === CARD_TYPE_BUY) {
        this.$root.playSound('buy');
      }
      this.$emit('clickUse', this.cardIndex);
    },
  },
};
</script>
<style lang="scss" scoped>

$smallBorderRadius: 7px;

.use-button {
  width: 100%;
  height: 20%;
  padding-bottom: 3%;
  padding-top: 3%;

  border: solid 2px #fff;
  border-radius: 12px;

  transition: transform 0.15s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
  &:active {
    background-color: #2C3642;
  }
}

.card-highlight {
  position: absolute;
  pointer-events: none;
  width: 127%;
  height: 127%;
  top: 56%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 998;
}

.card-wrapper {
  position: relative;
  aspect-ratio: 190/250;
  cursor: pointer;
  background-size: cover;
  padding: 5% 4.5% 5% 5.6%;
  min-width: 100%;
  width: 100%;
  transition: transform 0.15s ease-in-out;
  z-index: 999;

  &:hover {
    transform: scale(1.02);
  }

  &.card-not-available {
    opacity: 0.5;
    cursor: default !important;

    &:hover {
      transform: scale(1) !important;
    }

    &:active {
      img {
        filter: brightness(100%) !important;
      }
    }
  }

  &.safari {
    .card-inner-dark {
      height: 12% !important;
    }
    .info-wrapper {
      height: 27.8% !important;
    }
  }

  .title-wrapper {
    position: relative;
    width: 100%;
    height: 13%;
    padding-bottom: 3%;
    padding-top: 3%;
    color: #fff;
    font-weight: 800;
    font-size: 16px;

    border-radius: $smallBorderRadius $smallBorderRadius 0 0;
  }

  .info-wrapper {
    height: 32%;
    overflow-y: auto;
    font-size: 16px;
    line-height: 1.2;

    margin: 2.5% 2.3% !important;
  }

  .card-inner-dark {
    height: 13%;
    color: #fff;
    position: relative;
    z-index: 1;
    overflow: hidden;
    font-weight: 700;
    font-size: min(1.7vw, 23px);
    line-height: 1.2;

    .money-icon {
      width: min(3.7vw, 45px);
    }

    img {
      padding: 0 10px 3px;
      margin-top: -1px;
    }
  }

  .card-inner-dark::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(60, 70, 82, 0.28);
    z-index: -1;
    border-radius: inherit;
    mix-blend-mode: soft-light;
  }

  .card-inner {
    margin: 2% 2%;
    border-radius: 4px;
    padding: 1.5% 4%;
  }

  .trash-wrapper {
    position: absolute;
    bottom: 8%;
    width: 89%;
    border-radius: 0 0 $smallBorderRadius $smallBorderRadius;
    height: 16%;
    padding: 1% 0%;

    .trash-icons {
      display: flex;
      justify-content: space-between;
      height: 100%;
    }

    .trash-icon {
      background-position: center;
      background-repeat: no-repeat;
      width: 20%;
      margin: 0px;
      background-size: 105%;

      &.empty {
        background-size: 85%;

        background-position: 50% 65%;
      }
    }

    .opaque {
      opacity: 0.2;
    }
  }

  &.card-renovate {
    background-image: url('../../assets/ui/cards/card_background_renovation.png');

    .title-wrapper {
      background: linear-gradient(180deg, #F4C0FF -3.9%, #593E5F 130.89%);

      .icon {
        background-image: url('../../assets/ui/cards/icon_renovation.svg');
      }
    }

    .info-wrapper {
      background-color: #D5BAD9;
    }

    .trash-wrapper {
      background-color: #AD90B2;

      .trash-icon {
        background-image: url('../../assets/ui/cards/icon_trash_filled_renovation.png');

        &.empty {
          background-image: url('../../assets/ui/cards/icon_trash_renovation.png');
        }
      }
    }
  }

  &.card-recycle {
    background-image: url('../../assets/ui/cards/card_background_recycle.png');

    .title-wrapper {
      background: linear-gradient(180deg, #8ACDAA -3.9%, #3A4E34 130.89%);
      .icon {
        background-image: url('../../assets/ui/cards/icon_recycle.svg');
      }
    }
    .info-wrapper {
      background-color: #B3E2C8;
    }

    .trash-wrapper {
      background-color: #88B096;

      .trash-icon {
        background-image: url('../../assets/ui/cards/icon_trash_filled_recycle.png');

        &.empty {
          background-image: url('../../assets/ui/cards/icon_trash_recycle.png');
        }
      }
    }
  }

  &.card-buy {
    background-image: url('../../assets/ui/cards/card_background_buy.png');

    .title-wrapper {
      background: linear-gradient(180deg, #AFBAF2 -3.9%, #42465F 130.89%);
      .icon {
        background-image: url('../../assets/ui/cards/icon_buy.svg');
      }
    }

    .info-wrapper {
      background-color: #B2BED6;
    }

    .trash-wrapper {
      background-color: #8992AD;
      .trash-icon {
        background-image: url('../../assets/ui/cards/icon_trash_filled_buy.png');

        &.empty {
          background-image: url('../../assets/ui/cards/icon_trash_buy.png');
        }
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
  }

  &:active {
    img {
      filter: brightness(90%);
    }
  }
}
</style>
