import GameData from "@/data/GameData.js";
import {getLevelStarsFromId} from "@/utils/dataUtil";
import {getLocalStorageValue} from "@/utils/storeUtil";


export default {
  name: 'UI',
  state: {
    money: 20,
    co2: 15,
    co2Points: 0,
    levelDataIds: [],
    levelUnfinishedRenovationId: [],
    currentRoundIndex: -1,
    levelCompleted: false,
    showRoundSummary: false,
    showFinalHouse: false,
    gameActionsDisabled: true,
    totalPoints: 0,
    roundPoints: [],
    currentHighestStarLevels: {},
  },
  mutations: {
    initiateUI: (state, value) => {
      const keysToRetrieve = [
        { key: 'currentHighestStarLevels', type: 'object' },
      ];

      keysToRetrieve.forEach(({ key, type }) => {
        const { hasValue, returnValue } = getLocalStorageValue(key, type);

        if (hasValue) {
          if (hasValue) state[key] = returnValue;
        }
      });
    },
    setLevelDataIds: (state, value) => {
      state.levelDataIds = value;
    },
    nextRound: (state, value) => {
      state.currentRoundIndex++;
    },
    addPoints: (state, value) => {
      state.roundPoints.push(value);
      state.totalPoints = state.totalPoints + value;
    },
    addLevelUnfinishedRenovationId: (state, value) => {
      state.levelUnfinishedRenovationId = state.levelUnfinishedRenovationId.concat(value);
    },
    resetRound: (state, value) => {
      state.co2 = GameData.MAX_CO2;
      state.currentRoundIndex = -1;
      state.levelCompleted = false;
      state.totalPoints = 0;
      state.roundPoints = [];
    },
    completeLevel: (state, value) => {
      state.levelCompleted = true;
      state.levelUnfinishedRenovationId = [];
    },
    setShowRoundSummary: (state, value) => {
      state.showRoundSummary = value;
    },
    setShowFinalHouse: (state, value) => {
      state.showFinalHouse = value;
    },
    setGameActionsDisabled: (state, value) => {
      state.gameActionsDisabled = value;
    },
    setMoney: (state, value) => {
      state.money = value;
    },
    setCo2: (state, value) => {
      state.co2 = value;
  },
    setCo2Points: (state, value) => {
      state.co2Points = value;
    },
    addMoney: (state, value) => {
      state.money = state.money + value;
    },
    addCo2: (state, value) => {
      state.co2 = Math.min(GameData.MAX_CO2, Math.max(1, state.co2 + value));
    },
    addCo2Points: (state, value) => {
      state.co2Points = state.co2Points + value;
    },
    clearUI: (state) => {
      state.highlightedHouseParts = [];
      state.highlightedCategory = null;
    },
    addRoundStarRating: (state, value) => {
      const levelId = value.levelId;
      const roundId = value.roundId;
      const starRating = value.starRating;

      state.currentHighestStarLevels[levelId] ??= {};

      state.currentHighestStarLevels[levelId][roundId] = starRating;
    },
    addLevelStarRating: (state, levelId) => {
      const starRating = getLevelStarsFromId(levelId, state.currentHighestStarLevels);

      state.currentHighestStarLevels[levelId] ??= {};

      state.currentHighestStarLevels[levelId].totalStarRating = Math.max(
          state.currentHighestStarLevels[levelId].totalStarRating ?? 0,
          starRating
      );

      localStorage.setItem('currentHighestStarLevels', JSON.stringify(state.currentHighestStarLevels));

    },
  },
  getters: {
    currentHighestStarLevels (state) {
      return state.currentHighestStarLevels;
    },
    money (state) {
      return state.money;
    },
    co2 (state) {
      return state.co2;
    },
    roundPoints (state) {
      return state.roundPoints;
    },
    totalPoints (state) {
      return state.totalPoints;
    },
    co2Points (state) {
      return state.co2Points;
    },
    currentRoundIndex(state) {
      return state.currentRoundIndex;
    },
    currentRoundId(state) {
      return state.levelDataIds[state.currentRoundIndex];
    },
    levelCompleted(state) {
      return state.levelCompleted;
    },
    levelDataIds(state) {
      return state.levelDataIds;
    },
    showRoundSummary(state) {
      return state.showRoundSummary;
    },
    gameActionsDisabled(state) {
      return state.gameActionsDisabled;
    },
    showFinalHouse(state) {
      return state.showFinalHouse;
    },
    levelUnfinishedRenovationId(state) {
      return state.levelUnfinishedRenovationId;
    },
  },
}
