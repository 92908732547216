<template>
  <div :class="blockClass" :style="{ flexBasis }" :draggable="draggable" @dragstart="onDragStart" @dragend="onDragEnd">
    <div class="BlockContent" :style="blockStyle">
      <h3>Block {{ index }}</h3>
      <p v-if="draggable">This block is draggable.</p>
      <p v-else>This block is not draggable.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlockComponent',
  data() {
    return {
      isDragging: false
    };
  },
  props: {
    id: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    draggable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    blockClass() {
      return {
        Block: true,
        'Block--isDraggable': this.draggable,
        'non-draggable': !this.draggable,
      };
    },
    blockStyle() {
      return this.isDragging ? { backgroundColor: 'green' } : {};
    },
    flexBasis() {
      if ([1, 4, 5].includes(this.index)) {
        return '50%';
      } else if ([3, 7].includes(this.index)) {
        return '100%';
      } else {
        return '33.333%';
      }
    }
  },
  methods: {
    onDragStart() {
      this.isDragging = true;
    },
    onDragEnd() {
      this.isDragging = false;
    }
  }
};
</script>

<style scoped>
.Block {
  border: 1px solid #ddd;
  margin: 10px;
  padding: 20px;
  background-color: #f9f9f9;
}

.Block--isDraggable {
  cursor: move;
}

.BlockContent {
  text-align: center;
}

.non-draggable {
  background-color: #999999;
  cursor: not-allowed;
}
</style>
