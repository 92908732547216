
<template>
  <div class="">
    <div class="wind-slider-wrapper">
      <div>{{sliderTitle}}</div>
      <input type="range" :min="minValue" :max="maxValue" ref="slider" v-model="inputValue">

    </div>


  </div>
</template>

<script >

  export default {
    name: 'slider',
    data() {
      return {
      }
    },
    props: {
      sliderValue: {
        type: Number,
        required: true,
      },
      sliderTitle: {
        type: String,
        required: true,
      },
      minValue: {
        type: Number,
        required: true,
      },
      maxValue: {
        type: Number,
        required: true,
      }
    },
		components: {
		},
    methods: {
    },
    computed: {
      inputValue: {
        get() {
          console.log(this.sliderValue);
          return this.sliderValue;
        },
        set(val) {
          this.$emit('update:sliderValue', val);
        }
      }

    },
	}

</script>



<style land="scss" scoped>

img {
  height: 230px;
  padding-bottom: 10px;
}

.wind-slider-wrapper {
  position: relative;
  display: grid;
}

</style>
