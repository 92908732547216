
import level1round1 from "@/data/levelData/level-data-1.json";
import level1round2 from "@/data/levelData/level-data-2.json";
import level2round1 from "@/data/levelData/level-data-3.json";
import level2round2 from "@/data/levelData/level-data-4.json";
import level3round1 from "@/data/levelData/level-data-5.json";
import level3round2 from "@/data/levelData/level-data-6.json";
import level4round1 from "@/data/levelData/level-data-7.json";
import level4round2 from "@/data/levelData/level-data-8.json";
import level5round1 from "@/data/levelData/level-data-9.json";
import level5round2 from "@/data/levelData/level-data-10.json";
import level6round1 from "@/data/levelData/level-data-11.json";
import level6round2 from "@/data/levelData/level-data-12.json";
import {
    LEVEL_1_ROUND_1,
    LEVEL_1_ROUND_2,
    LEVEL_2_ROUND_1,
    LEVEL_2_ROUND_2,
    LEVEL_3_ROUND_1,
    LEVEL_3_ROUND_2,
    LEVEL_4_ROUND_1,
    LEVEL_4_ROUND_2,
    LEVEL_5_ROUND_1,
    LEVEL_5_ROUND_2,
    LEVEL_6_ROUND_1,
    LEVEL_6_ROUND_2,
} from "@/enum/LevelIds";

export default {
    [LEVEL_1_ROUND_1]: {
        levelData: level1round1,
    },
    [LEVEL_1_ROUND_2]: {
        levelData: level1round2,
    },
    [LEVEL_2_ROUND_1]: {
        levelData: level2round1,
    },
    [LEVEL_2_ROUND_2]: {
        levelData: level2round2,
    },
    [LEVEL_3_ROUND_1]: {
        levelData: level3round1,
    },
    [LEVEL_3_ROUND_2]: {
        levelData: level3round2,
    },
    [LEVEL_4_ROUND_1]: {
        levelData: level4round1,
    },
    [LEVEL_4_ROUND_2]: {
        levelData: level4round2,
    },
    [LEVEL_5_ROUND_1]: {
        levelData: level5round1,
    },
    [LEVEL_5_ROUND_2]: {
        levelData: level5round2,
    },
    [LEVEL_6_ROUND_1]: {
        levelData: level6round1,
    },
    [LEVEL_6_ROUND_2]: {
        levelData: level6round2,
    },
}
