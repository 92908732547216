<template>
  <div class="container pt-2" v-if="loading">
    Loading..
  </div>
  <div class="container pt-2" v-else>
    <div class="row">
      <label class="form-label">Component</label>
      <select v-model="selectedDevComponent" class="form-control">
        <option value="">
          Select a dev component
        </option>
        <option v-for="devComponentName in devComponentNames" :key="devComponentName"
                :value="devComponentName"
        >
          {{ devComponentName }}
        </option>
      </select>
    </div>
    <div class="row mt-2 component-container">
      <component :is="selectedDevComponent" v-if="selectedDevComponent"/>
      <div v-else>
        Select a Dev component in the dropdown above
      </div>
    </div>
  </div>
</template>

<script>
import AppButtonDev from "@/components/common/dev/AppButtonDev";

const devComponents = {
  AppButtonDev,
};

export default {
  name: 'DevView',
  components: devComponents,
  data() {
    return {
      devComponentNames: Object.keys(devComponents),
      selectedDevComponent: '',
      loading: true,
    };
  },
  computed: {
    currentDevComponent() {
      return this.$router.currentRoute?.value?.params?.component;
    },
  },
  watch: {
    currentDevComponent() {
      if (this.currentDevComponent === this.selectedDevComponent) {
        return;
      }

      this.selectedDevComponent = this.currentDevComponent || '';
    },
    selectedDevComponent() {
      if (this.currentDevComponent === this.selectedDevComponent) {
        return;
      }

      console.log('Push router', `/dev/${this.selectedDevComponent}`);
      this.$router.push(`/dev/${this.selectedDevComponent}`);
    },
  },
  mounted() {
    this.selectedDevComponent = this.currentDevComponent || '';

    this.loading = true;

    // TODO: Load content required by the component here?

    this.loading = false;

    this.$store.commit('topLogoVisible', false);
    this.$store.commit('bottomLogoVisible', false);
  },
};
</script>

<style lang="scss" scoped>
.component-container {
  border: 1px solid #333333;
  padding: 10px;

  &.component-theme-grey {
    background: #EDF1F4;
  }

  &.component-theme-blue {
    background: #45A4CD;
  }

  &.component-theme-white {
    background: #F8F8F8;
  }
}
</style>
