<template>
  <div class="trash-chute-wrapper" :class="isWarningMode ? 'warning-mode' : ''">
    <div class="trash-chute-title d-flex justify-content-center">
      <no-drag-image src="@/assets/ui/icon_trash.svg"/> Affaldssortering
    </div>
    <fireworks-v-f-x ref="firework" :style="fireworksStyle"/>
    <div class="chutes-container d-flex justify-content-between">
      <div
          class="trash-chute px-2"
          v-for="chuteNumber in chuteIds"
          :key="chuteNumber"
      >
        <transition-group name="trash-fall"
                          tag="div"
                          type="animation"
                          @leave="handleLeave">
          <div
              v-for="(item, index) in getChuteItems(chuteNumber)"
              :key="item.id"
              class="trash-bag-wrapper"
              :style="trashBagStyle(index)"
              :data-item-id="item.id"
              :class="item.id === selectedTrashId ? 'selected' : ''"
              @click="selectTrashPiece(item.id)"
          >
            <no-drag-image :src="getImgSrc(item.type)" />
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NoDragImage from '@/components/common/NoDragImage';
import FireworksVFX from "@/components/common/vfx/FireworksVFX";

export default {
  name: 'TrashChute',
  components: {FireworksVFX, NoDragImage },
  data() {
    return {
      chuteAssignments: {},
      chuteIds: [1, 2, 3],
      previousTrashIds: [],
      fireworksPosition: { x: 0, y: 0 },
    };
  },
  computed: {
    ...mapState([
      'currentTrash',
      'selectedTrashId',
      'gameActionsDisabled',
    ]),
    fireworksStyle() {
      return {
        position: 'absolute',
        left: `${this.fireworksPosition.x}px`,
        top: `${this.fireworksPosition.y}px`,
      };
    },
    isWarningMode() {
      // go into warning mode when every chute has more than 6 pieces of trash
      const chuteCounts = this.chuteIds.map(
          (chuteNumber) => this.getChuteItems(chuteNumber).length
      );

      for (const count of chuteCounts) {
        if (count < 5) {
          return false;
        }
      }

      return true;
    }
  },
  watch: {
    currentTrash: {
      handler(newTrash, oldTrash) {
        this.updateChuteAssignments();

        // Get the current and previous IDs
        const currentIds = newTrash.map((item) => item.id);
        const removedIds = this.previousTrashIds.filter(
            (id) => !currentIds.includes(id)
        );

        if (removedIds.length > 0) {
          this.triggerFireworksAnimation();
        }

        this.previousTrashIds = [...currentIds];
      },
      deep: true,
    },
  },
  methods: {
    updateChuteAssignments() {
      const currentIds = this.currentTrash.map((item) => item.id);

      // Remove entries from chuteAssignments for items no longer in currentTrash
      Object.keys(this.chuteAssignments).forEach((id) => {
        if (!currentIds.includes(Number(id))) {
          delete this.chuteAssignments[id];
        }
      });

      // Assign new items to chutes
      this.currentTrash.forEach((item) => {
        if (!this.chuteAssignments[item.id]) {

          const chuteCounts = this.chuteIds.map(
              (chuteNumber) => this.getChuteItems(chuteNumber).length
          );
          const minCount = Math.min(...chuteCounts);
          const chuteNumber = chuteCounts.indexOf(minCount) + 1; // Chutes are 1-indexed
          this.chuteAssignments[item.id] = chuteNumber;
        }
      });
    },
    handleLeave(el, done) {

      const rect = el.getBoundingClientRect();
      const containerRect = this.$el.getBoundingClientRect();

      const x = rect.left - containerRect.left + rect.width / 2;
      const y = rect.top - containerRect.top + rect.height / 2;

      this.fireworksPosition = { x, y };

      if (this.$refs.firework && this.$refs.firework.playAnimation) {
        this.$refs.firework.playAnimation();
      } else {
        console.error('FireworksVFX component is not properly referenced.');
      }

      // Wait for the animation to end before calling done()
      const onEnd = () => {
        el.removeEventListener('animationend', onEnd);
        done();
      };
      el.addEventListener('animationend', onEnd);
    },
    triggerFireworksAnimation() {
      if (this.$refs.firework && this.$refs.firework.playAnimation) {
        this.$refs.firework.playAnimation();
      } else {
        console.error('FireworksVFX component is not properly referenced.');
      }
    },
    getChuteItems(chuteNumber) {
      return this.currentTrash.filter(
          (item) => this.chuteAssignments[item.id] === chuteNumber
      );
    },
    selectTrashPiece(id) {
      if (this.gameActionsDisabled) {
        return;
      }

      this.$root.playSound('simpleClick');
      this.$store.commit('selectTrashId', id);
    },
    trashBagStyle(index) {
      return {
        bottom: `${index * 80}px`,
      };
    },
    getImgSrc(iconName) {
      const requireImage = require.context(
          '@/assets/ui/trashGame',
          true,
          /\.png$/
      );
      const imagePath = `./trash_bag_${iconName}.png`;
      return requireImage(imagePath);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables.scss";

.trash-chute-wrapper {
  height: 400px;
  width: 210px;
  margin: auto;
  overflow: hidden;
  position: relative;
  border: $whiteBorder;
  border-width: 3px;
  background-color: #ffffff30;
  border-radius: $globalBorderRadius * 1.2 $globalBorderRadius * 1.2 0 0;
  box-shadow: 4px -4px 7.3px 0px #00000040 inset;
  transform: translateY(4px);

  &.warning-mode {
    border-color: #E30521 !important;
    animation-name: pulsating-background;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
  }

  @keyframes pulsating-background {
    0% {
      background-color: rgba(255, 255, 255, 0.2);
    }
    100% {
      background-color: rgba(227, 5, 33, 0.3);
    }
  }

  .trash-chute-title {
    background-color: $colorUiDarkBlue;
    color: white;
    font-size: 22px;
    line-height: 1.7;
    font-weight: 700;
    border-radius: $globalBorderRadius $globalBorderRadius 0 0;
    text-align: center;
    z-index: 101;
    position: relative;

    img {
      width: 20px;
      margin: 7px 7px 7px 0;
    }
  }

  .chutes-container {
    position: relative;
    z-index: 99;
    display: flex;
    height: 100%;
    justify-content: space-between;
    padding: 0 5px;
  }

  .trash-chute {
    position: relative;
    height: 87%;
    width: 30%;
    margin-right: 4px;
    margin-left: -4px;
  }
}

.trash-bag-wrapper {
  position: absolute;
  width: 100%;
  cursor: pointer;
  transition: transform 0.2s $bounceTimingFunction,
  bottom 0.3s cubic-bezier(0.175, 0.935, 0.32, 1.175) 0.2s;

  &:hover {
    transform: scale(1.1) rotate(2deg);
  }

  &.selected {
    transform: scale(1.2) rotate(7deg);
  }
}
.trash-fall-enter-active,
.trash-fall-move {
  transition: all 0.4s ease-in-out;
}

/* Initial state for entering elements */
.trash-fall-enter {
  opacity: 0;
  transform: translateY(-50px);
}

/* Apply enter animation */
.trash-fall-enter-active {
  animation: drop-bounce 0.4s ease-in-out;
}

/* Apply leave animation */
.trash-fall-leave-active {
  animation: disappear 0.4s ease-in-out forwards;
}

/* Initial state for leaving elements */
.trash-fall-leave {
  opacity: 1;
  transform: scale(1);
}

/* Final state for leaving elements */
.trash-fall-leave-to {
  opacity: 0;
  transform: scale(0);
}

/* Keyframes for enter animation */
@keyframes drop-bounce {
  0% {
    opacity: 0;
    transform: translateY(-40px) scaleY(1.5) scaleX(0.5);
  }
  70% {
    opacity: 1;
    transform: translateY(10px) scaleY(0.7) scaleX(1.3);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scaleY(1) scaleX(1);
  }
}

/* Keyframes for leave animation */
@keyframes disappear {
  0% {
    opacity: 1;
    transform:  scaleY(1) scaleX(1);
  }
  100% {
    opacity: 0;
    transform: translateY(-40px) scaleY(0) scaleX(0) !important;
  }
}
</style>
