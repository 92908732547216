import {
    DORMER_1, DORMER_2,
    FACADE_CLADDING_1, FACADE_CLADDING_2, FACADE_CLADDING_3,
    FACADE_GARAGE_WALL_1, FACADE_GARAGE_WALL_2,
    FACADE_WALL_1, FACADE_WALL_2, FACADE_WALL_3, FACADE_WALL_4,
    GARAGE_ROOF, GUTTER,
    INSULATION_FACADE_1,
    INSULATION_FACADE_2,
    INSULATION_FACADE_3,
    INSULATION_FACADE_4, INSULATION_GARAGE_FACADE_1, INSULATION_GARAGE_FACADE_2, INSULATION_GARAGE_ROOF, INSULATION_ROOF,
    ROOF
} from "@/enum/HousePartIds";
import {
    CATEGORY_CLADDING, CATEGORY_DORMER,
    CATEGORY_GUTTER,
    CATEGORY_INSULATION,
    CATEGORY_OUTER_WALL,
    CATEGORY_ROOF
} from "@/enum/Categories";
export default {
    [ROOF]: {
        outlineClass: 'placement-roof',
        outlineName: 'RoofOutline',
        imageNameBase: 'roof',
        visible: true,
        graphicsHousePartReference: null,
        zIndex: 50,
        category: CATEGORY_ROOF
    },
    [GARAGE_ROOF]: {
        outlineClass: 'placement-garage-roof',
        outlineName: 'GarageRoofOutline',
        imageNameBase: 'garage_roof',
        visible: true,
        graphicsHousePartReference: null,
        zIndex: 20,
        category: CATEGORY_ROOF
    },
    [INSULATION_FACADE_1]: {
        visible: false,
        graphicsHousePartReference: FACADE_WALL_1,
        category: CATEGORY_INSULATION
    },
    [INSULATION_FACADE_2]: {
        visible: false,
        graphicsHousePartReference: FACADE_WALL_2,
        category: CATEGORY_INSULATION
    },
    [INSULATION_FACADE_3]: {
        visible: false,
        graphicsHousePartReference: FACADE_WALL_3,
        category: CATEGORY_INSULATION
    },
    [INSULATION_FACADE_4]: {
        visible: false,
        graphicsHousePartReference: FACADE_WALL_4,
        category: CATEGORY_INSULATION
    },
    [INSULATION_ROOF]: {
        visible: false,
        graphicsHousePartReference: ROOF,
        category: CATEGORY_INSULATION
    },
    [INSULATION_GARAGE_ROOF]: {
        visible: false,
        graphicsHousePartReference: GARAGE_ROOF,
        category: CATEGORY_INSULATION
    },
    [INSULATION_GARAGE_FACADE_1]: {
        visible: false,
        graphicsHousePartReference: FACADE_GARAGE_WALL_1,
        category: CATEGORY_INSULATION
    },
    [INSULATION_GARAGE_FACADE_2]: {
        visible: false,
        graphicsHousePartReference: FACADE_GARAGE_WALL_2,
        category: CATEGORY_INSULATION
    },
    [GUTTER]: {
        outlineClass: 'placement-gutter',
        outlineName: 'Gutter',
        imageNameBase: 'gutter',
        visible: true,
        graphicsHousePartReference: null,
        zIndex: 49,
        category: CATEGORY_GUTTER,
        canBeHidden: true
},
    [FACADE_WALL_1]: {
        outlineClass: 'placement-facade-1',
        outlineName: 'Facade1',
        imageNameBase: 'facade_1',
        visible: true,
        graphicsHousePartReference: null,
        zIndex: 30,
        category: CATEGORY_OUTER_WALL
},
    [FACADE_WALL_2]: {
        outlineClass: 'placement-facade-2',
        outlineName: 'Facade2',
        imageNameBase: 'facade_2',
        visible: true,
        graphicsHousePartReference: null,
        zIndex: 31,
        category: CATEGORY_OUTER_WALL
},
    [FACADE_WALL_3]: {
        outlineClass: 'placement-facade-3',
        outlineName: 'Facade3',
        imageNameBase: 'facade_3',
        visible: true,
        graphicsHousePartReference: null,
        zIndex: 30,
        category: CATEGORY_OUTER_WALL
},
    [FACADE_WALL_4]: {
        outlineClass: 'placement-facade-4',
        outlineName: 'Facade4',
        imageNameBase: 'facade_4',
        visible: true,
        graphicsHousePartReference: null,
        zIndex: 31,
        category: CATEGORY_OUTER_WALL
},
    [FACADE_GARAGE_WALL_1]: {
        outlineClass: 'placement-facade-5',
        outlineName: 'Facade5',
        imageNameBase: 'garage_facade_1',
        visible: true,
        graphicsHousePartReference: null,
        zIndex: 21,
        category: CATEGORY_OUTER_WALL
},
    [FACADE_GARAGE_WALL_2]: {
        outlineClass: 'placement-facade-6',
        outlineName: 'Facade6',
        imageNameBase: 'garage_facade_2',
        visible: true,
        graphicsHousePartReference: null,
        zIndex: 22,
        category: CATEGORY_OUTER_WALL
},
    [FACADE_CLADDING_1]: {
        outlineClass: 'placement-dormer-1',
        outlineName: 'Dormer',
        imageNameBase: 'dormer_face_1',
        visible: true,
        graphicsHousePartReference: null,
        zIndex: 33,
        category: CATEGORY_CLADDING,
        canBeHidden: true
},
    [FACADE_CLADDING_2]: {
        outlineClass: 'placement-dormer-2',
        outlineName: 'Dormer',
        imageNameBase: 'dormer_face_2',
        visible: true,
        graphicsHousePartReference: null,
        zIndex: 33,
        category: CATEGORY_CLADDING,
        canBeHidden: true
},
    [FACADE_CLADDING_3]: {
        outlineClass: 'placement-cladding-3',
        outlineName: 'Facade5',
        imageNameBase: 'cladding_3',
        visible: true,
        graphicsHousePartReference: null,
        zIndex: 23,
        category: CATEGORY_CLADDING,
        canBeHidden: true
},
    [DORMER_1]: {
        visible: false,
        graphicsHousePartReference: FACADE_CLADDING_1,
        category: CATEGORY_DORMER
    },
    [DORMER_2]: {
        visible: false,
        graphicsHousePartReference: FACADE_CLADDING_2,
        category: CATEGORY_DORMER
    },
}
