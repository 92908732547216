<template>
  <div class="action-box-content-high-score">

    <div class="d-flex justify-content-between">
      <div class=""  v-for="(button, i) in buttons" key="i">
        <app-button
            size="small"
            :active-state="button.value === currentTab"
            class='vidha-button vidha-button-large'
            @click="changeTab(button.value)">
          {{button.text}}
        </app-button>
      </div>
    </div>

    <div class="title-wrapper d-flex justify-content-between">
      <div class="title"  v-for="(title) in titles">
        {{title}}:
      </div>
    </div>

    <div class="value-wrapper">
      <div class="d-flex mt-3 blue-block justify-content-between metric-wrapper" :class="i === highlightIndex ? 'highlighted' : ''" v-for="(dataItem, i) in displayedData" key="i">
        <div class="data-point data-point-title">
          {{i + 1}}. {{dataItem.name}}
        </div>
        <div class="data-point data-point-date">
          {{formatDate(dataItem.time)}}
        </div>
        <div class="data-point metric-value">
          {{dataItem.score}}
        </div>
      </div>
      <div v-if="showExtra" class="d-flex mt-3 blue-block justify-content-between metric-wrapper highlighted">
        <div class="data-point data-point-title">
          {{highlightIndex + 1}}. {{extraData?.name}}
        </div>
        <div class="data-point data-point-date">
          {{formatDate(extraData?.time)}}
        </div>
        <div class="data-point metric-value">
          {{extraData?.score}}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import NoDragImage from "@/components/common/NoDragImage";
import AppButton from "@/components/common/AppButton";
import { mapState } from "vuex";

export const LIST_TYPE_DAY = "day";
export const LIST_TYPE_WEEK = "week";
export const LIST_TYPE_MONTH = "month";

export default {
  name: "ActionBoxContentHighScore",
  components: { AppButton, NoDragImage },
  props: {
    highscoreData: {
      type: Object,
      default: () => ({}),
    },
    highLightScoreIndexes: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      localHighscoreData: {},
      showExtra: false,
      extraData: {},
      titles: ["Navn", "Dato", "Score"],
      buttons: [
        {
          text: "Dag",
          value: LIST_TYPE_DAY,
        },
        {
          text: "Uge",
          value: LIST_TYPE_WEEK,
        },
        {
          text: "Måned",
          value: LIST_TYPE_MONTH,
        },
      ],
      currentTab: LIST_TYPE_DAY,
    };
  },
  watch: {
    highscoreData: {
      immediate: true,
      handler(newVal) {
        this.localHighscoreData = newVal;
      },
    },
    highscorePlacementData: {
      immediate: true,
      handler(newVal) {
        this.calculateExtraData(newVal);
      },
      deep: true,
    },
    highlightIndex: {
      handler(newVal) {
        this.calculateExtraData(newVal);
      },
    },
  },
  computed: {
    ...mapState([
        "highscorePlacementData"
    ]),
    displayedData() {
      return this.localHighscoreData?.[this.currentTab] || [];
    },
    highlightIndex() {
      const highlightIndex = this.highLightScoreIndexes?.ranks?.[this.currentTab];
      return highlightIndex;
    },
  },
  mounted() {
    this.calculateExtraData(this.highscorePlacementData);
  },
  methods: {
    changeTab(val) {
      this.currentTab = val;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based in JS
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    calculateExtraData(highscorePlacementData) {
      this.showExtra = highscorePlacementData && this.displayedData.length < this.highlightIndex && this.highlightIndex !== -1 && this.highlightIndex + 1;
      this.extraData = highscorePlacementData?.metaData || {};
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables.scss";

$textPadding: 24px;
$borderRadius: 4px;
$innerMargin: 4px;

.action-box-content-high-score {
  padding: 10px;
  height: calc(60vh - 80px);
  max-height: 800px;

  .value-wrapper {
    max-height: calc(60vh - 180px);
    overflow: auto;
  }

  .title-wrapper {
    margin: 20px 00px 0px 10px;

    font-size: 20px;
    font-weight: 800;
    text-transform: uppercase;

    .title {
      width: 33%;
      text-align: left;
    }
  }
}

.blue-block {
  padding: 3px 10px;
  background-color: $colorUiDarkBlue;
  border-radius: $borderRadius;
}

.data-point {
  width: 33%;
  text-align: left;

  &.data-point-title {
    font-weight: 700;
    text-transform: uppercase;
  }

  &.data-point-date {
    font-weight: 800 !important;
    font-size: 14px !important;
    padding: 5px 0.5% 3px 2.5% !important;
  }
}

.metric-wrapper {
  width: 608px;
  padding: 4px 0.5% 4px 2.5%;
  font-size: 18px;
  font-weight: 700;
  margin: 5px;

  &.highlighted {
    background-color: #12381C;
    box-shadow: 0 0 3.6px 3px #5FD37E, 0px 1.34px 2.68px 1px #000000,  0.58px 2.5px 1.54px 0px #77C5DEB8 inset, 0px -3.38px 0px 0px #00000066 inset;
    border: 2px solid #B5F3A6;

  }

  .metric-value {
    color: $colorUiDarkBlue;
    border-radius: $borderRadius;
    margin: 2px $innerMargin;
    line-height: 1.2;
    padding-top: 3px;
    text-align: center;

    background-color: #fff;
  }

  img {
    width: 40px;
    padding-right: 10px;
  }

  .metric-points {
    width: 25%;
    margin-left: 9px;
  }

}
</style>
