<template>
  <div
    class="cards-controller-wrapper"
    :class="selectedHouseTaskIndex === -1 ? 'hidden' : ''"
  >
    <ui-box box-type="dark">
      <template #header>
        <div class="card-shared-title">
          {{cards?.[0]?.title}}
        </div>
      </template>
      <template #content>
        <div class="d-flex justify-content-between">
          <div class="card-outer-wrapper" v-for="(card, i) in cardsCopy" :key="i">
            <Card
                :current-selected-index="selectedCardIndex"
                :card-index="i"
                :card-data="card"
                @click="cardClicked"
                @clickUse="cardUsed"
            />
          </div>
        </div>
      </template>
    </ui-box>

  </div>
</template>
<script>

import NoDragImage from "@/components/common/NoDragImage";
import Card from "./Card";
import {mapState} from "vuex";
import UiBox from "@/components/common/UiBox";
import {deepClone} from "@/utils/dataUtil";
// add index to the card and set it active and make the effect of the active card
export default {
  name: 'CardsController',
  components: {UiBox, Card, NoDragImage},
  data() {
    return {
      selectedCardIndex: -1,
      cardsCopy: [],
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['cardUsed'],
  watch: {
    cards: {
      handler(newValue, oldValue) {

        if (newValue.length === 0) {
          setTimeout(() => {
            if (this.cards.length === 0) {
              this.cardsCopy = newValue;
            }
          }, 600);
        } else {
          this.cardsCopy = JSON.parse(JSON.stringify(newValue));
        }

      },
      deep: true
    },
    selectedHouseTaskIndex: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          if (newValue === -1) {
            this.$root.playSound('cardsDisappear');
          } else {
            this.$root.playSound('cardsAppear');
          }
        }

      },
    }
  },
  computed: {
    ...mapState([
      'selectedHouseTaskIndex',
      'co2',
      'houseTasks',
    ]),
    houseTask() {
      if (this.selectedHouseTaskIndex === -1) {
        this.selectedCardIndex = -1;
        return null;
      }
      return this.houseTasks[this.selectedHouseTaskIndex];
    },
    cards() {
      const cards = [];

      if (!this.houseTask) {
        return cards;
      }

      return this.houseTask.cards;
    }
  },
  methods: {
    cardClicked(i) {
      if (this.cards.length === 0) {
        return;
      }

      this.selectedCardIndex = i;
    },
    cardUsed(i) {
      if (this.cards.length === 0) {
        return;
      }

      const cardUsed = this.cards[i];

      this.selectedCardIndex = -1;
      this.$emit('cardUsed', cardUsed);
    }
  },
};
</script>
<style lang="scss" scoped>

.cards-controller-wrapper {
  position: absolute;
  bottom: 2%;
  width: 100%;
  left: 50%;
  transform: translate(-50%, 0%);
  max-width: 800px;
  margin: auto;
  transition: transform 0.5s cubic-bezier(0.175, 0.735, 0.32, 1.175);

  .card-shared-title {
    text-transform: uppercase;
  }

  &.hidden {
    transform: translate(-50%, 170%);
  }

  .card-outer-wrapper {
    width: 33%;
    position: relative;
    margin: 0 5px;
  }

  img {
    width: 100%;
    height: 100%;
  }

  &:active {
    img {
      filter: brightness(90%);
    }
  }
}
</style>
