<template>
  <div class="ground-wrapper">
    <div class="ground ground-old">
      <no-drag-image src="@/assets/house/ground_old.svg" />
    </div>
    <div class="ground ground-new" :style="groundNewStyle">
      <no-drag-image src="@/assets/house/ground_new.svg" />
    </div>
    <div class="ground" :class="showFinalHouse ? '' : 'faded'">
      <no-drag-image src="@/assets/house/driveway.svg" />
    </div>
  </div>

</template>

<script>

import NoDragImage from "@/components/common/NoDragImage";
import {mapState} from "vuex";
import GameData from "@/data/GameData";

export default {
  name: 'Ground',
  components: {NoDragImage},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  computed: {
    ...mapState ([
      'co2',
      'showFinalHouse'
    ]),
    groundNewStyle() {
      const style = {};

      const min_source = 1;
      const max_source = GameData.MAX_CO2 - GameData.MAX_CO2 / 6;

      const value = this.co2;

      // map between these two values to ensure that it looks right
      const min_target = 0;
      const max_target = 1;

      const mapped_value = min_target + ((value - min_source) / (max_source - min_source)) * (max_target - min_target)
      style.opacity = mapped_value
      style.zIndex = 3;

      return style;
    }
  },
  methods: {
    onClickButton() {
      if (this.disabled) {
        return;
      }

      this.$emit('click');
    },
  },
};
</script>
<style lang="scss" scoped>

.ground-wrapper {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  aspect-ratio: 1603/968;

  .ground {
    position: absolute;
    pointer-events: none;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.5s cubic-bezier(0.175, 0.735, 0.32, 1.175);
  }

  img {
    width: 100%;
  }

  .faded {
    opacity: 0.2;
  }

}
</style>
