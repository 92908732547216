<template>
  <div class="category-button-wrapper">
    <div
        class="vidha-button category-button d-flex justify-content-between align-items-center"
        :class="buttonClass"
        @click.prevent.stop="onClickButton"
    >
      <div class="d-flex align-items-center">
        <no-drag-image :src="iconSrc" />
        {{categoryName}}
      </div>

      <div class="item-count-wrapper">
        {{finishedItems}} / {{totalItems}}
      </div>
    </div>
  </div>

</template>
<script>

import NoDragImage from "@/components/common/NoDragImage";
import categoryData from "@/data/CategoryData";

export default {
  name: 'CategoryButton',
  components: {NoDragImage},
  props: {
    categoryId: {
      type: String,
      default: '',
    },
    finishedItems: {
      type: Number,
      default: 0,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      categoryData
    }
  },
  emits: ['click'],
  computed: {
    categoryName() {
      return this.categoryData?.[this.categoryId].categoryName || 'test';
    },
    iconSrc() {
      const requireImage = require.context('@/assets/ui/categoryIcons', true, /\.png$/);
      const imagePath = `./icon_category_${this.categoryId}.png`;
      return requireImage(imagePath);
    },
    buttonClass() {
      const buttonClass = [];

      if (this.selected) {
        buttonClass.push('selected');
      }

      if (this.disabled) {
        buttonClass.push('disabled');
      }

      if (this.totalItems === this.finishedItems) {
        buttonClass.push('done');
      }

      return buttonClass;
    }
  },
  methods: {
    onClickButton() {
      if (this.disabled) {
        return;
      }

      this.$root.playSound('simpleClick');
      this.$emit('click');
    },
  },
};
</script>
<style lang="scss" scoped>

@import "@/sass/variables.scss";
$height: 61px;
$width: 285px;
$paddingSize: 4px;


.category-button-wrapper {
  width: $width;
  height: $height;
}

.category-button {
  max-width: $width;
  height: $height;
  cursor: pointer;
  padding: $paddingSize;
  margin: 10px 10px 10px 10px;

  transition: transform $shortTransitionDuration $tinyBounceTimingFunction;

  &.disabled {
    cursor: default !important;
    pointer-events: none;
  }

  &.done {
    cursor: default !important;
    pointer-events: none;

    .item-count-wrapper {
      background-color: $colorUiHighlight;
    }
  }

  &.selected {
    transform: scale(1.06) !important;
    border: solid 3px $colorUiHighlight;
    background-color: $colorUiDarkGreen;

    box-shadow: 0 0 7.6px 5px #5FD37E,  0px -3.78px 0px 0px #00000066 inset,  0.58px 2.5px 2.54px 0px #77C5DE68 inset;

    img {
      filter: brightness(90%);
    }
  }

  .item-count-wrapper {
    background-color: #fff;
    padding: 3px;
    min-width: 65px;
    color: $colorUiDarkBlue;
    margin-right: $paddingSize;
    margin-left: $paddingSize * 2;
    border-radius: 4px;
    font-weight: 800;
  }

  img {
    width: 100%;
    max-width: 45px;
    max-height: $height * 0.8;
    margin-right: $paddingSize;
  }

  &:hover {
    transform: scale(1.03);

    img {
      filter: brightness(90%);
    }
  }

  &:active {
    transform: scale(1.02);

    img {
      filter: brightness(90%);
    }
  }
}
</style>
