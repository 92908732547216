import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';

import { createI18n } from 'vue-i18n';
import localMessages from './lib/i18n/index.js';

import ApiClient from "@/plugins/ApiClient";

const app = createApp(App);

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: localMessages,
});

if (process.env.VUE_APP_SENTRY_DSN) {
  console.log(`Enabling sentry.io integration..`);

  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          'localhost',
          process.env.VUE_APP_SENTRY_ORIGIN || '',
          /^\//,
        ],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    logErrors: true,
  });
}

app
  .use(store)
  .use(router)
  .use(i18n)
  .use(ApiClient);

store.dispatch('initAllCategories').then(() => {
  app.mount('#app');
});
