<template>
  <div class="action-box-content-tutorial">
    <div class="video-container" ref="vimeoPlayer"></div>
  </div>
</template>
<script>

import Player from '@vimeo/player';

export default {
  name: 'ActionBoxContentTutorial',
  components: {Player},
  data() {
    return {
      videoId: '1018328514'
    }
  },
  mounted() {
    this.player = new Player(this.$refs.vimeoPlayer, {
      id: this.videoId,
      width: 640,
    });

    // Example: Listen for the play event
    this.player.on('play', () => {
      console.log('Video is playing');
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.destroy();
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/variables.scss";

$videoMargin: 30px;

.action-box-content-tutorial {
  margin: $videoMargin/2 $videoMargin 0 $videoMargin;

}

.video-container {
  width: 100%;
  position: relative;
}

.video-container div,
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - $videoMargin *2);
  height: 100%;
}

</style>
