<template>
  <div class="house-wrapper-outer">
    <div
        class="house-wrapper"
    >


    <div class="house" :style="{zIndex:data.zIndex}" v-for="(data, id) in houseOutlinePartData" :key="id">
      <HousePart v-if="isHousePartVisible(id)"
                 :house-part-id="id"
                 :house-part-data="data"
                 :house-part-state="housePartState(id)"
                 :house-part-sprite-material="housePartSpriteMaterial(id)"
                 :house-part-sprite-state="housePartSpriteState(id)"
                 @click="partClicked"
      />
    </div>

    <div class="windows-wrapper">
      <div class="" v-for="(variable, i) in wdData" :key="i">
        <WindowDoor :house-part-id="i"
                    :house-part-data="variable"
                    :house-part-state="housePartState(i)"
                    :house-part-sprite-state="housePartSpriteState(i)"
                    v-if="!isHousePartHidden(i)"
                    @click="partClicked"
        />
      </div>
      </div>
    </div>
  </div>

</template>
<script>

import NoDragImage from "@/components/common/NoDragImage";
import Ground from "@/components/house/Ground";
import wdData from "@/data/WDData.js";
import houseOutlinePartData from "@/data/HouseOutlinePartData";
import WindowDoor from "@/components/house/WindowDoor";
import {mapState} from "vuex";
import {STATE_HIDDEN, STATE_OLD} from "@/enum/HouseState";
import HousePartOutline from "@/components/house/HousePartOutline";
import HousePart from "@/components/house/HousePart";

export default {
  name: 'House',
  components: {HousePart, HousePartOutline, WindowDoor, Ground, NoDragImage},
  data() {
    return {
      wdData,
      houseOutlinePartData
    }
  },
  props: {
  },
  emits: ['partClick'],
  methods: {
    housePartState(partId) {
      return this.houseState?.[partId]?.state || STATE_OLD;
    },
    housePartSpriteMaterial(partId) {
      return this.houseState?.[partId]?.spriteMaterial || '';
    },
    housePartSpriteState(partId) {
      return this.houseState?.[partId]?.spriteState || '';
    },
    isHousePartHidden(partId) {
      return this.housePartState(partId) === STATE_HIDDEN;
    },
    isHousePartVisible(partId) {
      const partData = this.houseOutlinePartData[partId];
      const canBeHidden = partData.canBeHidden;
      const isVisible = partData.visible;

      // If the part cannot be hidden, visibility depends solely on the `visible` flag
      if (!canBeHidden) {
        return isVisible;
      }

      // If the part can be hidden, check both visibility and if it's not hidden
      return isVisible && !this.isHousePartHidden(partId);
    },
    partClicked(partId) {
      this.$emit('partClick', partId);
    },
    housePartVisible() {

    }
  },
  computed: {
    ...mapState([
      'houseState'
    ]),
  }
};
</script>
<style lang="scss" scoped>

.house-wrapper-outer {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 1603/968;
}

.house-wrapper {
  width: 74.8%;
  position: absolute;
  top: 29.2%;
  left:56.5%;
  transform: translate(-50%, -50%);

  .no-interactions {
    pointer-events: none;
  }

  .windows-wrapper {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    aspect-ratio: 1171/704;
    pointer-events: none;
    z-index: 35
  }

  .house {
    position: absolute;
    pointer-events: none;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    aspect-ratio: 1171/704;
    img {
      width: 100%;
    }
  }
}
</style>
