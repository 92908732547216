
export default {
    timeRemainingPointsMultiplier: 10,
    co2PointsMultiplier: 51,
    moneyPointsMultiplier: 20,
    missingRenovationsPointsMultiplier: -500,
    remainingTrashPointsMultiplier: -1000,
    co2ColorValue: {
        green: 7000,
        yellow: 6000,
        red: 5000
    },
    numberOfStars: 5,
}
