import {TRASH_DANGEROUS, TRASH_METAL, TRASH_WOOD} from "@/enum/TrashType";
import {getRandomTrashType} from "@/utils/dataUtil";
import {getLocalStorageValue} from "@/utils/storeUtil";
import {LIST_TYPE_DAY, LIST_TYPE_MONTH, LIST_TYPE_WEEK} from "@/components/common/ActionBoxContentHighScore";

export default {
  name: 'Highscore',
  state: {
    userName: '',
    highscorePlacementData: {},
    highscores: {},
  },
  mutations: {
    initiateHighscore: (state, value) => {
      const keysToRetrieve = [
          // { key: 'userName', type: 'string' },
      ];

      keysToRetrieve.forEach(({ key, type }) => {
        const { hasValue, returnValue } = getLocalStorageValue(key, type);

        if (hasValue) {
          if (hasValue) state[key] = returnValue;
        }
      });
    },
    userName: (state, value) => {
      let filteredValue = value.replace(/[^a-zA-Z*]/g, '');
      filteredValue = filteredValue.padEnd(3, '*');
      filteredValue = filteredValue.substring(0, 3);

      state.userName = filteredValue;
      // localStorage.setItem('userName', state.userName);
    },
    setHighscorePlacementData: (state, value) => {
      state.highscorePlacementData = value;

      console.log("NEW HIGHSCORE PLACEMENT DATA", value);
    },
    refreshHighscores: (state, apiClient) => {
      apiClient.highscore.getHighscoreListDay().then((highscores) => {
        highscores.length = Math.min(highscores.length, 20);
        state.highscores[LIST_TYPE_DAY] = highscores;
        console.log("REFRESHED SCORES");
      });
      apiClient.highscore.getHighscoreListWeek().then((highscores) => {
        highscores.length = Math.min(highscores.length, 20);
        state.highscores[LIST_TYPE_WEEK] = highscores;
      });
      apiClient.highscore.getHighscoreListMonth().then((highscores) => {
        highscores.length = Math.min(highscores.length, 20);
        state.highscores[LIST_TYPE_MONTH] = highscores;
      });
    }
  },
  getters: {
    userName(state) {
      return state.userName;
    },
    highscores(state) {
      return state.highscores;
    },
    highscorePlacementData: (state, value) => {
      return state.highscorePlacementData;
    }
  },
}
