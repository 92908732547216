import { Howl, Howler } from 'howler';

class SoundManager {
    constructor() {
        this.isMuted = false;
        this.sounds = {};
        this.soundIds = {};
        this.defaultVolume = 1.0;

        const isMuted = parseInt(process.env.MUTE_AUDIO, 10) > 0;
        if (isMuted) {
            this.muteAll();
            this.defaultVolume = 0;
        }

    }

    loadSound(key, src, loop = false, volume = this.defaultVolume) {
        const sound = new Howl({
            src: [src],
            loop: loop,
            volume: volume,
        });
        this.sounds[key] = sound;
    }

    playSound(key, fadeDuration = 0) {
        if (!this.isMuted && this.sounds[key]) {
            const sound = this.sounds[key];
            const targetVolume = sound.volume();

            if (this.soundIds[key]) {
                this.stopSound(key);
            }

            const soundId = sound.play();
            this.soundIds[key] = soundId;

            if (fadeDuration > 0) {
                sound.volume(0, soundId);
                sound.fade(0, targetVolume, fadeDuration, soundId);
            } else {
                sound.volume(targetVolume, soundId);
            }
        }
    }

    stopSound(key, fadeDuration = 0) {
        if (this.sounds[key] && this.soundIds[key]) {
            const sound = this.sounds[key];
            const soundId = this.soundIds[key];
            const currentVolume = sound.volume(soundId);

            if (fadeDuration > 0) {
                sound.fade(currentVolume, 0, fadeDuration, soundId);

                sound.once('fade', soundId, () => {
                    sound.stop(soundId);
                    sound.volume(this.defaultVolume, soundId);
                    delete this.soundIds[key];
                });
            } else {
                sound.stop(soundId);
                delete this.soundIds[key];
            }
        }
    }

    muteAll() {
        this.isMuted = true;
        Howler.mute(true);
    }

    unmuteAll() {
        this.isMuted = false;
        Howler.mute(false);
    }
}

const soundManager = new SoundManager();
export default soundManager;
