<template>
  <div class="game-view">

    <div id="modifier-target"></div>

    <!-- UI -->
    <div class="ui-outer-wrapper" :class="showGame ? '' : 'hidden'">
      <div class="ui ui-bottom-wrapper">
        <cards-controller @cardUsed="cardUsed"/>
      </div>
      <div class="ui ui-top-left-wrapper">
        <ui-box :title="'Level ' + currentLevelId" :subtitle="'Runde ' + formattedRoundIndex" :color="currentLevelData.color">
          <template #content>
            <time-view ref="timeView" @timer-finished="timerDone"/>
            <money-view/>
          </template>
        </ui-box>
      </div>
      <div class="ui ui-top-right-wrapper">
        <close-button @click="gameOver"/>
      </div>
      <div class="ui ui-center-left-wrapper">
        <category-tasks
            :disabled="gameActionsDisabled"
            :highlight-all="showRoundSummary"
        />
      </div>
      <div class="ui ui-top-center-wrapper">
        <co2-view/>
      </div>
      <div class="ui ui-center-right-wrapper">
        <trash-minigame-controller :show-trash-game="isTrashGame"/>
      </div>
    </div>


    <div class="ui ui-center-wrapper ui-panel">

      <!-- Level Not Started -->
      <div v-if="!levelStarted">

        <div class="logo-wrapper">
          <no-drag-image src="@/assets/ui/logo.png" />
        </div>
        <div class="level-container d-flex flex-wrap align-self-center">
          <div v-for="(level, key) in levelData">
            <level-button :name="level.name" :color="level.color" :level-id="key" @click="startLevel(level.rounds, key)"/>
          </div>
        </div>

        <div class="buttons-wrapper d-flex">
          <app-button class='vidha-button vidha-button-medium' @click="toggleShowTutorial">Video Tutorial</app-button>
          <app-button class='vidha-button vidha-button-medium' @click="onHighScoreButtonClicked">Highscore</app-button>
          <app-button class='vidha-button info-button' @click="toggleShowInfo">i</app-button>
        </div>
      </div>

      <!-- Level In Progress -->
      <div v-else>
        <!-- Start Round -->
        <div v-if="showRoundSummary" class="start-button-wrapper">
          <app-button class='vidha-button vidha-button-large' @click="startRound">Start runde</app-button>
        </div>

        <!-- Round Completion Screen -->
        <div v-if="roundCompleted" class="round-complete-screen">
          <action-box :titles="['Runde ' + (formattedRoundIndex - 1), 'point']">
            <action-box-content-end-round :star-level-data="starLevelData" :point-data="endRoundData" :round-number="formattedRoundIndex - 1"/>
            <app-button class='vidha-button vidha-button-large' @click="prepareNextRound">Fortsæt</app-button>
          </action-box>
        </div>

        <!-- Level Completed -->
        <div v-if="levelCompleted && !roundCompleted">
          <action-box :titles="['Level gennemført', 'point']">

          <action-box-content-end-level :level-id="currentLevelId"/>
            <div class="d-flex justify-content-around">
              <app-button class='vidha-button vidha-button-large' @click="onEndGameHighScoreButtonClicked">{{isHighScore ? 'Send til highscore' : 'Highscore'}}</app-button>
              <app-button v-if="!isHighScore" class='vidha-button vidha-button-large' @click="gameOver">Menu</app-button>
              <app-button v-if="!isHighScore" class='vidha-button vidha-button-large' @click="replayLevel">Prøv igen</app-button>
            </div>
          </action-box>

        </div>
      </div>
    </div>

    <!-- Game -->
    <div class="house-controller-outer" :class="houseClass" @click="unselectHousePart">
      <HouseController :disabled="showRoundSummary"/>
    </div>


    <div class="pop-up-wrapper" v-if="tutorialOpen">
      <action-box :titles="['Tutorial']" :show-close-button="true" @click="toggleShowTutorial">
        <action-box-content-tutorial/>
      </action-box>
    </div>


    <div class="pop-up-wrapper" v-if="infoOpen">
      <action-box :titles="['Info']" :show-close-button="true" @click="toggleShowInfo">
        <action-box-content-info/>
      </action-box>
    </div>

    <div v-if="!levelStarted" class="bottom-bar">
      Dette spil er skabt i et tæt samarbejde mellem Mercantec og Serious Games Interactive.
    </div>

    <div v-if="showClickCatcher" class="click-catcher" @click="onClickCatcherClicked"></div>

  </div>
</template>

<script>

import NoDragImage from "@/components/common/NoDragImage"
import AppButton from "@/components/common/AppButton";
import Accordion from "@/components/common/Accordion";
import {mapState} from "vuex";
import ErrorBox from "@/components/common/error-box/ErrorBox";
import Slider from "@/components/common/Slider";
import Flexbox from "@/components/common/draggable/Flexbox";
import HouseController from "@/components/house/HouseController";
import Weather from "@/components/house/Weather";
import CardsController from "../components/card/CardsController";
import MoneyView from "@/components/common/MoneyView";
import TimeView from "@/components/common/TimeView";
import Co2View from "@/components/common/CO2View";
import TrashMinigameController from "@/components/trashMinigame/TrashMinigameController";
import Co2PointView from "@/components/common/CO2PointView";
import CategoryButton from "@/components/common/CategoryButton";
import {
  createStartRoundDataObject,
  getFormattedHouseTasks,
  getInitialHouseState, getLevelStarsFromId,
  getStarLevelsFromAllLevels
} from "@/utils/dataUtil";

import levelMapData from "@/data/LevelMapData";
import levelData from "@/data/LevelData";
import CategoryTasks from "@/components/common/CategoryTasks";
import {MODIFIER_TYPE_CO2, MODIFIER_TYPE_MONEY, MODIFIER_TYPE_TRASH} from "@/enum/ModifierType";
import UiBox from "../components/common/UiBox";
import ActionBox from "../components/common/ActionBox";
import ActionBoxContentEndRound from "../components/common/ActionBoxContentEndRound";
import {createEndRoundDataObject} from "@/utils/dataUtil";
import ActionBoxContentStartRound from "../components/common/ActionBoxContentStartRound";
import ActionBoxContentEndLevel from "@/components/common/ActionBoxContentEndLevel";
import {LEVEL_4_ROUND_1, LEVEL_4_ROUND_2} from "@/enum/LevelIds";
import FireworksVFX from "@/components/common/vfx/FireworksVFX";
import {randomBetween} from "@/utils/mathUtil";
import LevelButton from "@/components/common/LevelButton";
import PointData from "@/data/PointData";
import ActionBoxContentTutorial from "@/components/common/ActionBoxContentTutorial";
import ActionBoxContentInfo from "@/components/common/ActionBoxContentInfo";
import CloseButton from "@/components/common/CloseButton";

export default {
  name: 'GameView',
  components: {
    CloseButton,
    ActionBoxContentInfo,
    ActionBoxContentTutorial,
    LevelButton,
    FireworksVFX,
    ActionBoxContentEndLevel,
    ActionBoxContentStartRound,
    ActionBoxContentEndRound,
    ActionBox,
    UiBox,
    CategoryTasks,
    CategoryButton,
    Co2PointView,
    TrashMinigameController,
    Co2View,
    TimeView,
    MoneyView,
    CardsController,
    Weather,
    HouseController,
    Flexbox,
    Slider,
    ErrorBox, Accordion, NoDragImage, AppButton},
  data() {
    return {
      roundIdsQueue: [],
      roundStarted: false,
      roundCompleted: false,
      endRoundData: {},
      startRoundData: {},
      currentRoundTime: 0,
      isDev: false,
      levelData: levelData,
      starLevelData: {},
      currentLevelId: -1,
      tutorialOpen: false,
      infoOpen: false,
    }
  },
  watch: {
    money(newVal) {
      if (newVal <= 0 && this.roundStarted) {
        if (!this.isTrashGame || (this.isTrashGame && this.currentTrash.length === 0)) {
          this.completeRound();
        }
      }
    },
    currentTrash: {
      handler(newVal, oldVal) {
        if (newVal.length <= 0 && this.roundStarted) {
          let finishedAllTasks = true;
          for (const task of this.houseTasks) {
            if (!task.done) {
              finishedAllTasks = false;
            }
          }

          if (this.money <= 0 || finishedAllTasks) {
            this.completeRound();
          }
        }
      },
      deep: true,
    },
    houseTasks: {
      handler(newValue, oldValue) {
        for (const task of newValue) {
          if (!task.done) {
            return;
          }
        }

        console.log("tasks are all done", this.isTrashGame, this.currentTrash.length === 0);
        if (!this.isTrashGame || (this.isTrashGame && this.currentTrash.length === 0)) {
          console.log("all tasks are done and trash done if applicable");
          this.completeRound()
        }
      },
      deep: true
    },
  },
  mounted() {
    this.starLevelData = getStarLevelsFromAllLevels();
    this.gameOver();

    const isDev = parseInt(process.env.VUE_APP_IS_DEV, 10) > 0;
    this.isDev = isDev;
    if (isDev) {
      // this.startLevel(['LEVEL_1_ROUND_2'], 9);
      // this.startRound();
      // this.completeRound();
    }
  },
  beforeUnmount() {
    this.gameOver();
  },
  computed: {
    ...mapState([
        'co2',
        'levelDataIds',
        'currentRoundId',
        'currentRoundIndex',
        'levelCompleted',
        'showRoundSummary',
        'gameActionsDisabled',
        'money',
        'houseTasks',
        'selectedHouseTaskIndex',
        'totalPoints',
        'roundPoints',
        'currentTrash',
        'totalPoints',
        'userName',
    ]),
    showClickCatcher() {
      return this.tutorialOpen || this.infoOpen;
    },
    levelStarted() {
      return this.currentRoundIndex >= 0;
    },
    currentLevelData() {
      return this.levelData?.[this.currentLevelId] || {};
    },
    isTrashGame() {
      return true;
      // console.log("isTrashGame", this.currentRoundId);
      // return this.currentRoundId === LEVEL_4_ROUND_1 || this.currentRoundId === LEVEL_4_ROUND_2;
    },
    formattedRoundIndex() {
      return this.currentRoundIndex + 1;
    },
    houseClass() {
      const houseClass = [];

      if (this.showGame) {

      } else {
        houseClass.push('house-hidden');
      }

      return houseClass;
    },
    currentRoundId() {
      return this.levelDataIds[this.currentRoundIndex]
    },
    currentHouseTask() {
      if (this.selectedHouseTaskIndex === -1) {
        return null;
      }
      return this.houseTasks[this.selectedHouseTaskIndex];
    },
    isHighScore() {
      return true; //this.totalPoints > 0 || false;
    },
    showGame() {
      const teleportDivExists = document.getElementById('modifier-target') !== null;

      return (this.roundStarted || this.showRoundSummary) && teleportDivExists;
    }
  },
  methods: {
    readRoundData(roundId) {

      const levelData = levelMapData.hasOwnProperty(roundId) ? levelMapData[roundId].levelData : {};

      if (levelData.empty) {
        console.error("stop right there");
      }

      const randomMoney = randomBetween(parseInt(levelData.startMoneyMin), parseInt(levelData.startMoneyMax), 0);
      let randomCo2 = randomBetween(parseInt(levelData.startCo2Min), parseInt(levelData.startCo2Max), 0);
      const randomTrashAmount = randomBetween(parseInt(levelData.startTrashMin), parseInt(levelData.startTrashMax), 0);

      if (randomCo2 < 1) {
        randomCo2 = 1;
      }

      this.$store.commit('setMoney', randomMoney);
      this.$store.commit('setCo2', randomCo2);
      this.$store.commit('setCo2Points', 0);
      this.$store.commit('setCurrentTrashAmount', randomTrashAmount);

      this.$store.commit('setSelectedHouseTaskIndex', -1);
      this.selectedCardIndex = null;
      this.$store.commit('setHighlightedCategory', '');

      // load house parts
      const initialState = getInitialHouseState(levelData);
      this.$store.commit('setHouseState', initialState);

      const currentHouseTasks = getFormattedHouseTasks(levelData);
      this.$store.commit('setHouseTasks', currentHouseTasks);

      // set time and start timer
      this.currentRoundTime = levelData.time;
      if (this.isDev) {
        this.currentRoundTime = 35;
      }

      this.$refs.timeView.setTime(this.currentRoundTime);
    },
    startRoundFlow() {
      // this.$refs.timeView.setTime(this.currentRoundTime);
      this.$refs.timeView.startTimer();

      //this.levelStarted = true;
      this.roundStarted = true;
    },
    unselectHousePart() {
      this.$store.commit('setSelectedHouseTaskIndex', -1);
      this.selectedCardIndex = null;
    },
    cardUsed(cardUsed) {
      let cardPrice = parseInt(cardUsed.price);
      const modifier = this.currentHouseTask?.modifier || MODIFIER_TYPE_MONEY;
      const modifierMultiplier = parseInt(this.currentHouseTask?.modifierMultiplier) || 2;

      // console.log("card used", cardPrice, this.money, this.isTrashGame, this.currentRoundId);

      if (modifier === MODIFIER_TYPE_MONEY) {
        cardPrice *= modifierMultiplier;
      }


      // TODO maybe remove this (depends on if they change the logic so that the amount of money you have cannot go below zero.
      /* if (cardPrice > this.money) {
        return;
      }*/
      if (this.money <= 0) {
        return;
      }

      let cardCO2 = parseInt(cardUsed.co2);
      let cardTrash = parseInt(cardUsed.trash);

      if (modifier === MODIFIER_TYPE_TRASH) {
        cardTrash *= modifierMultiplier;
      }
      if (modifier === MODIFIER_TYPE_CO2) {
        cardCO2 *= modifierMultiplier;
      }

      this.$store.commit('addMoney', -1 * cardPrice);
      this.$store.commit('addCo2', cardCO2);
      this.$store.commit('addCo2Points', (cardCO2 + this.co2) * 10);
      this.$store.commit('addCurrentTrashAmount', cardTrash);

      this.$store.commit('finishCurrentTask');

      this.$store.commit('setSelectedHouseTaskIndex', -1);
      this.selectedCardIndex = null;
    },
    timerDone(){
      console.log("time done");
      this.completeRound();
    },
    gameOver() {
      this.$root.playAmbientSound();

      this.$store.commit('resetRound');
      this.$store.commit('setSelectedHouseTaskIndex', -1);

      // this.levelStarted = false;
      this.roundStarted = false;
      this.roundCompleted = false;

      this.$store.commit('setShowRoundSummary', false);
    },
    replayLevel() {

      const levelId = this.currentLevelId;

      this.$store.commit('resetRound');
      // this.levelStarted = false;
      this.roundStarted = false;
      this.roundCompleted = false;


      this.startLevel(levelData[levelId].rounds, levelId)

    },
    startLevel(levelDataIds, id) {
      this.$root.playAmbientMusic();
      this.$store.commit('setLevelDataIds', levelDataIds);
      this.currentLevelId = id;

      this.loadAndShowRoundInfo();

      this.$store.commit('setShowRoundSummary', true);
    },
    loadAndShowRoundInfo() {
      this.$store.commit('nextRound');
      this.$store.commit('setShowFinalHouse', false);
      this.readRoundData(this.currentRoundId)
      this.startRoundData = createStartRoundDataObject(this.houseTasks);
      this.roundStarted = false;
    },
    startRound() {
      this.$store.commit('setShowRoundSummary', false);
      this.$store.commit('setGameActionsDisabled', false);
      this.roundStarted = true;

      this.startRoundFlow();
    },
    prepareNextRound() {
      this.roundCompleted = false;


      if (!this.levelCompleted) {
        this.$store.commit('setShowRoundSummary', true);
      }
    },
    completeRound() {
      this.$refs.timeView.stopTimer();

      this.$store.commit('setGameActionsDisabled', true);

      this.endRoundData = createEndRoundDataObject(this.$refs.timeView.getTime(), this.co2, this.money, this.currentTrash.length, this.houseTasks);

      // set unfinished ids
      const missingHousePartIds = this.endRoundData.missingHousePartIds;
      this.$store.commit('addLevelUnfinishedRenovationId', missingHousePartIds);

      // get starLevel
      const roundPoints = this.endRoundData.roundPoints;
      const currentStars = this.getStarsEarned(roundPoints);

      this.endRoundData.starsEarned = currentStars;
      this.$store.commit('addRoundStarRating', {levelId: this.currentLevelId, roundId: this.currentRoundId, starRating: currentStars});

      if (!(this.currentRoundIndex < this.levelDataIds.length - 1)) {
        this.$store.commit('addLevelStarRating', this.currentLevelId);
      }

      this.$store.commit('setHighlightedCategory', '');
      this.$store.commit('addPoints', this.endRoundData.roundPoints);

      this.$store.commit('setShowFinalHouse', true);

      let timeOut = 5000;

      if (this.isDev) {
        timeOut = 4000;
      }

      setTimeout( () => {
        this.roundStarted = false;
        this.roundCompleted = true;

        if (this.currentRoundIndex < this.levelDataIds.length - 1) {
          this.loadAndShowRoundInfo();

        } else {
          this.$store.commit('completeLevel');
          this.levelCompleted = true;
        }
      }, timeOut);
    },
    getStarsEarned(totalPoints) {
      const starLevels = this.starLevelData[this.currentRoundId].starLevels;

      let starsEarned = 0;
      const numberOfStars = Object.keys(starLevels).length;

      for (let i = 1; i <= numberOfStars; i++) {
        const threshold = starLevels[`star${i}`];
        if (totalPoints >= threshold) {
          starsEarned = i + 1;
        } else {
          break;
        }
      }

      // missing hard criteria?
      let missingCriteria = false;

      if (this.endRoundData.missingRenovations.value > 0) {
        missingCriteria = true;
      } else if (this.endRoundData.time.value < 0) {
        missingCriteria = true;
      } else if (this.endRoundData.money.value < 0) {
        missingCriteria = true;
      } else if (this.endRoundData.trash.value < 0) {
        missingCriteria = true;
      } else if (this.endRoundData.co2Color.points === PointData.co2ColorValue.red) {
        missingCriteria = true;
      }

      if (missingCriteria) {
        starsEarned = Math.max(1, starsEarned - 1);
      } else {
        starsEarned = Math.min(5, starsEarned + 1);
      }

      return starsEarned;
    },
    onEndGameHighScoreButtonClicked() {

      this.$store.commit('setHighscorePlacementData', {});

      const name = this.userName;
      const data = { name: name, score: this.totalPoints, time: Date.now() }

      this.$apiClient.highscore.submitHighscore(data)
          .then((response) => {
            console.log('High score submitted successfully:', response);

            response.metaData = data;

            this.$store.commit('setHighscorePlacementData', response);
            this.$store.commit('refreshHighscores', this.$apiClient);
          })
          .catch((error) => {
            console.error('Error submitting high score:', error);
          });

      this.$router.push('/highscore');
    },
    onHighScoreButtonClicked() {
      this.$store.commit('setHighscorePlacementData', {});
      this.$router.push('/highscore');
    },
    toggleShowTutorial() {
      if (this.tutorialOpen) {
        this.$root.playAmbientSound();
      } else {
        this.$root.stopAllAmbient();
      }
      this.tutorialOpen = !this.tutorialOpen;
    },
    toggleShowInfo() {
      this.infoOpen = !this.infoOpen;
    },
    onClickCatcherClicked() {
      if (this.tutorialOpen) {
        this.$root.playAmbientSound();
      }

      this.tutorialOpen = false;
      this.infoOpen = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables.scss";
@import "@/sass/mixins.scss";

$exampleVariable: 20px;


@include responsive-phone {
  .ui-center-wrapper {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    transition: opacity 0.25s ease-in-out;

    .logo-wrapper {
      position: absolute;
      top: -220px !important;
      left: 50%;
      transform: translate(-50%, 0);

      img {
        width: 120px !important;
      }
    }

    .buttons-wrapper {
      top: 130px !important;
      transform: translate(-50%, 0) scale(0.5) !important;
    }

    .level-container {
      width: 1032px;
      min-width: 1032px;
      max-width: 1032px;

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -52%) scale(0.5) !important;
    }
  }
}


@include responsive-desktop-small {

  .ui-center-wrapper {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    transition: opacity 0.25s ease-in-out;

    .logo-wrapper {
      position: absolute;
      top: -220px !important;
      left: 50%;
      transform: translate(-50%, 0);

      img {
        width: 120px !important;
      }
    }

    .buttons-wrapper {
      top: 130px !important;
      transform: translate(-50%, 0) scale(0.5) !important;
    }

    .level-container {
      width: 1032px;
      min-width: 1032px;
      max-width: 1032px;

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -52%) scale(0.5) !important;
    }
  }
}

@include responsive-desktop {
  .ui-center-wrapper {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    transition: opacity 0.25s ease-in-out;

    .logo-wrapper {
      position: absolute;
      top: -220px !important;
      left: 50%;
      transform: translate(-50%, 0);

      img {
        width: 120px !important;
      }
    }

    .buttons-wrapper {
      top: 140px !important;
      transform: translate(-50%, 0) scale(0.6) !important;
    }

    .level-container {
      width: 1032px;
      min-width: 1032px;
      max-width: 1032px;

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -52%) scale(0.5) !important;
    }
  }

}

@include responsive-desktop-large {
  .ui-center-wrapper {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    transition: opacity 0.25s ease-in-out;

    .logo-wrapper {
      position: absolute;
      top: -300px !important;
      left: 50%;
      transform: translate(-50%, 0);

      img {
        width: 190px !important;
      }
    }

    .buttons-wrapper {
      top: 170px !important;
      transform: translate(-50%, 0) scale(0.7) !important;
    }

    .level-container {
      width: 1032px;
      min-width: 1032px;
      max-width: 1032px;

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -52%) scale(0.6) !important;
    }
  }
}

@include responsive-desktop-x-large {
  .ui-center-wrapper {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    transition: opacity 0.25s ease-in-out;

    .logo-wrapper {
      position: absolute;
      top: -350px !important;
      left: 50%;
      transform: translate(-50%, 0);

      img {
        width: 200px !important;
      }
    }

    .buttons-wrapper {
      position: absolute;
      top: 215px !important;
      transform: translate(-50%, 0) scale(0.9) !important;
    }

    .level-container {
      width: 1032px;
      min-width: 1032px;
      max-width: 1032px;

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -52%) scale(0.8) !important;
    }
  }
}

@include responsive-desktop-xx-large {
  .ui-center-wrapper {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    transition: opacity 0.25s ease-in-out;

    .logo-wrapper {
      position: absolute;
      top: -450px !important;
      left: 50%;
      transform: translate(-50%, 0);

      img {
        width: 260px !important;
      }
    }

    .buttons-wrapper {
      top: 290px !important;
      transform: translate(-50%, 0) scale(1) !important;
    }

    .level-container {
      width: 1032px;
      min-width: 1032px;
      max-width: 1032px;

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -52%) scale(1) !important;
    }
  }
}


.game-view {
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 1rem;


  .pop-up-wrapper {
    z-index: 10999;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .bottom-bar {
    position: absolute;
    bottom: 0;
    background-color: $colorUiDarkBlue;
    color: white;
    width: 100%;
    text-align: center;
    padding: 7px 0;
  }

  .house-controller-outer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: opacity 0.5s cubic-bezier(0.175, 0.985, 0.32, 1.175);
    z-index: 7999;
  }

  .house-hidden {
    opacity: 0;
    pointer-events: none;
    z-index: 7999;
  }

  .ui-outer-wrapper {
    transition: opacity 0.25s ease-in-out;
    z-index: 9999;
    opacity: 1;

    &.hidden {
      .ui {
        opacity: 0 !important;
      }
    }

    .ui {
      transition: opacity 0.25s ease-in-out;
      opacity: 1;
    }
  }

  .ui {
    position: absolute;
    z-index: 9999;
    margin: 15px;
  }

  .ui-top-right-wrapper {
    right: 0;
  }

  .ui-bottom-wrapper {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
  }

  .ui-center-wrapper {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    transition: opacity 0.25s ease-in-out;

    &.house-hidden {
      opacity: 0;
    }

    .logo-wrapper {
      position: absolute;
      top: -410px;
      left: 50%;
      transform: translate(-50%, 0);

      img {
        width: 260px;
      }
    }

    .buttons-wrapper {
      position: absolute;
      top: 290px;
      left: 50%;
      transform: translate(-50%, 0);
    }

    .level-container {
      width: 1032px;
      min-width: 1032px;
      max-width: 1032px;

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -52%);
    }

    .start-button-wrapper {
      margin-top: 45%;
    }
  }

  .ui-top-center-wrapper {
    left: 50%;
    transform: translateX(-50%);
    top: 3%;
    pointer-events: none;
  }

  .ui-center-left-wrapper {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .ui-center-right-wrapper {
    right: 0;
    transform: translateY(-50%) translateX(100%);
    top: 50%;
  }

}

#modifier-target {
  position: relative;
  z-index: 9998;
}

</style>
