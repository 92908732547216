<template>
  <div class="action-box-content-end-round">


    <div class="point-total blue-block d-flex justify-content-between"
         v-for="(points, i) in roundPoints">
      <div>Runde {{ i + 1 }} point</div>

      <div class="block-value"  :class="points > 0 ? 'green-block' : 'red-block'">
        {{ points }}
      </div>
    </div>

    <div class="big-blue-block blue-block d-flex justify-content-between">
      <div class="d-flex">
        <div>SAMLET score</div>
      </div>
      <div class="block-value"  :class="totalPoints > 0 ? 'green-block' : 'red-block'">
        {{ totalPoints }}
      </div>
    </div>

    <div class="star-wrapper blue-block d-flex justify-content-center">
      <div class="mx-2" v-for="number in numberOfStars" :key="number">
        <no-drag-image :src="getStarImgSrc(number <= stars)" />
      </div>
    </div>

    <div v-if="isHighScore">
      <div class="point-total blue-block d-flex justify-content-between">
        <div>Du er kommet på highscoren!</div>
      </div>

      <div class="vue3-2fa-code">
        <ven-code-input
            v-model="vvalue"
            :upper="true"
            :lower="false"
            :length="3"
            :numbersOnly="false"
            :error="''"
            :inFocus="true"
            :disallow="/[^a-zA-Z]/g"
            :update-immediately="true"
            @changed="changed"/>
      </div>
    </div>

  </div>
</template>
<script>

import {mapState} from "vuex";
import pointData from "@/data/PointData";
import NoDragImage from "@/components/common/NoDragImage";
import venCodeInput from "@venegrad/vue3-code-input"
import {getLevelStarsFromId} from "@/utils/dataUtil";
export default {
  name: 'ActionBoxContentEndLevel',
  components: {NoDragImage, venCodeInput},
  props: {
    levelId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      numberOfStars: pointData.numberOfStars,
      vvalue: ''
    }
  },
  computed: {
    ...mapState([
      'totalPoints',
      'roundPoints',
      'currentHighestStarLevels',
      'userName',
    ]),
    stars() {
      return getLevelStarsFromId(this.levelId, this.currentHighestStarLevels);
    },
    isHighScore() {
      return this.totalPoints > 0;
    }
  },
  mounted() {
  },
  methods: {
    getStarImgSrc(filled) {
      const imageName = filled ? 'star_filled' : 'star';
      const requireImage = require.context('@/assets/ui', true, /\.png$/);
      const imagePath = `./${imageName}.png`;
      return requireImage(imagePath);
    },
    changed() {
      this.$store.commit('userName', this.vvalue);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/variables.scss";

$textPadding: 24px;
$borderRadius: 4px;
$innerMargin: 4px;



.action-box-content-end-round {
  padding: 10px;
}

.green-block {
  background-color: $colorUiGreen;
  color: $colorUiDarkBlue;
}

.red-block {
  background-color: $colorUiRed;
  color: $colorUiDarkBlue;
}

.point-total {
  font-size: 22px;
  font-weight: 700;
  width: 100%;
  margin: 10px auto 5px auto;
}

.blue-block {
  padding: 3px 7px 3px 10px;
  background-color: $colorUiDarkBlue;
  border-radius: $borderRadius;

  .block-value {
    width: 198px;
    color: $colorUiDarkBlue;
    border-radius: $borderRadius;
    margin: 2px 0;
    line-height: 1.2;
    padding-top: 2px;
    // background-color: #fff;
  }
}


.star-wrapper {
  width: 82%;
  height: 80px;
  padding: 15px;
  margin: 0 auto 40px auto;
}

.big-blue-block {
  font-size: 35px;
  font-weight: 700;
  width: 100%;
  margin: 10px 38px 10px auto;
  padding-right: 7px !important;

  .block-value {
    width: 200px;
    color: $colorUiDarkBlue;
    border-radius: $borderRadius;
    margin: 2px 0;
    line-height: 1.2;
    padding-top: 5px;
    // background-color: #fff;
  }

  img {
    width: 40px;
    padding-right: 10px;
  }
}



.vue3-2fa-code {
  margin-top: 20px;
  margin-bottom: 40px;
  text-transform: uppercase;
}

/* Use :deep() to target input elements directly */
.vue3-2fa-code :deep(input) {
  // display: block;
  width: 3.5rem; /* Adjust width as needed */
  border-radius: 0.375rem;
  border: 1px solid #E5E7EB;
  background-color: white;
  padding: 0.3rem;
  text-align: center;
  color: #111827;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  font-size: 1.5rem;
  margin-right: 0.5rem;
  display: flex;
}

.vue3-2fa-code :deep(.ven-code-input-wrapper) {
  display: flex;
  justify-content: center;
}

.vue3-2fa-code :deep(.code-field__list) {
  display: flex;
  justify-content: center;
}

.vue3-2fa-code :deep(.code-field__item) {
  margin: 0 0.3rem 0 0.3rem;
}

/* Remove margin from the last input field */
.vue3-2fa-code :deep(input:last-child) {
  margin-right: 0;
}

/* Focus-visible styles */
.vue3-2fa-code :deep(input:focus-visible) {
  outline: 2px solid $colorUiDarkBlue;
  outline-offset: 2px;
}

/* Dark mode styles */
@media (prefers-color-scheme: dark) {
  .vue3-2fa-code :deep(input) {
    border-color: #F9FAFB;
    font-size: 35px;
    font-weight: 800;
    text-transform: uppercase;
    // color: #FFFFFF;
  }
}

</style>
