<template>
  <div class="slideshow-wrapper">
    <div class="arrow left vidha-button" @click="prevSlide">
      <no-drag-image src="@/assets/ui/icon_arrow_left.png" />
    </div>
    <div class="slideshow">
      <div class="slideshow-ground"/>
      <div class="images">
        <div
            v-for="(image, index) in images"
            :key="index"
            class="image-container"
            :class="{
          active: currentIndex === index,
          prev: currentIndex === index + 1 || (currentIndex === 0 && index === images.length - 1),
          next: currentIndex === index - 1 || (currentIndex === images.length - 1 && index === 0)
        }"
        >
          <no-drag-image :src="getImgSrc(image, (currentIndex === index))"
                         @click="attemptThrowout(image)"
          />
        </div>
      </div>
    </div>

    <div class="arrow right vidha-button" @click="nextSlide">
      <no-drag-image src="@/assets/ui/icon_arrow_left.png" />
    </div>
  </div>

</template>

<script>
import {TRASH_DANGEROUS, TRASH_METAL, TRASH_WOOD} from "../../enum/TrashType";
import NoDragImage from "../common/NoDragImage";
import {mapState} from "vuex";

export default {
  name: 'TrashMinigameController',
  components: {NoDragImage},
  data() {
    return {
      images: [
        TRASH_WOOD,
        TRASH_METAL,
        TRASH_DANGEROUS
      ],
      currentIndex: 0,
    };
  },
  computed: {
    ...mapState([
        'selectedTrashId',
        'gameActionsDisabled',
        'currentTrash'
    ]),
  },
  methods: {
    prevSlide() {
      if (this.gameActionsDisabled) {
        return;
      }

      this.$root.playSound('cardsDisappear');
      this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
    },
    nextSlide() {
      if (this.gameActionsDisabled) {
        return;
      }

      this.$root.playSound('cardsAppear');
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    },
    attemptThrowout(trashCanType) {
      if (this.gameActionsDisabled) {
        return;
      }

      const selectedIndex = this.currentTrash.findIndex(
          (item) => item.id === this.selectedTrashId
      );
      if (
          selectedIndex !== -1 &&
          this.currentTrash[selectedIndex].type === trashCanType
      ) {
        this.$store.commit('attemptThrowout', trashCanType);

        this.$root.playSound('trash');

        const activeImage = this.$el.querySelector('.image-container.active');
        if (activeImage) {
          activeImage.classList.add('wiggle');
          setTimeout(() => {
            activeImage.classList.remove('wiggle');
          }, 500);
        }
      }

    },
    getImgSrc(trashCategory, isOpen) {
      const imageName = `./trash_can_${trashCategory}${isOpen ? '_open' : ''}.png`;

      const requireImage = require.context('@/assets/ui/trashGame', true, /\.png$/);
      const imagePath = imageName;
      return requireImage(imagePath);
    },

  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables.scss";

$slideShowWidth: 225px;

.slideshow-wrapper {
  position: relative;
}

.slideshow {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: $slideShowWidth;
  height: $slideShowWidth * 1.05;
  border: $whiteBorder;
  overflow: hidden;
  background-color: $colorTransparentWhite;
  box-shadow: 0 4px -8px rgba(60, 70, 82, 0.7);
  border-radius: $globalBorderRadius;
}

@keyframes wiggle {
  0%, 100% {
    transform: translateX(0) translateY(0) rotate(0) scale(1.2);
  }
  25% {
    transform: translateX(-5px) translateY(-5px) rotate(-5deg) scale(1.25);
  }
  50% {
    transform: translateX(3px) translateY(5px) rotate(1deg) scale(1.3);
  }
  75% {
    transform: translateX(-5px) translateY(-5px) rotate(5deg) scale(1.2);
  }
}

.image-container.wiggle {
  animation: wiggle 0.4s ease-in-out;
}

.arrow {
  font-size: 1.3em;
  line-height: 0.3;
  cursor: pointer;
  user-select: none;
  z-index: 10;
  width: $slideShowWidth * 0.2;
  height: $slideShowWidth * 0.2;
  padding: 5px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 7px;

  img {
    width: 47%;
    height: 73%;
    padding-right: 4px;
  }

}

.left {
  position: absolute;
  left: -20px;
}

.right {
  position: absolute;
  right: -20px;

  img {
    transform: scaleX(-1);
  }
}
.slideshow-ground {
  width: 100%;
  height: 35%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(200.06deg, #AEAEAE -5.5%, #3D3D3D 102.49%);

}

.images {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 90%;
  margin-top: auto;
}

.image-container {
  position: absolute;
  transition: transform 0.25s ease-in-out;
  width: 60%;
  opacity: 0.9;
  pointer-events: none;

  img {
    width: 100%;
    cursor: pointer;
    transform: scale(0.8);
  }

  &.active {
    transform: translateX(0) scale(1.2);
    opacity: 1;
    z-index: 1;
    pointer-events: auto;
  }

  &.prev {
    transform: translateX(-85%) scale(0.9);
    z-index: 0;
  }

  &.next {
    transform: translateX(85%) scale(0.9);
    z-index: 0;
  }
}

</style>
