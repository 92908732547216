<template>
  <button
    :class="buttonClasses"
    class="vidha-button  app-button"
    :disabled="disabled"
    @click.prevent.stop="onClickButton"
  >
    <span v-if="hasWhiteBorder" class="background"></span>
    <span class="content">
      <slot/>
    </span>
  </button>
</template>
<script>

export const COLOR_GREEN = 'green';
export const COLOR_YELLOW = 'yellow';
export const COLOR_RED = 'red';
export const COLOR_PEACH = 'peach';
export const COLOR_PINK = 'pink';
export const COLOR_LIGHT_GREEN = 'light-green';
export const COLOR_DARK_BLUE = 'dark-blue';

export const SIZE_SMALL = 'small';
export const SIZE_MEDIUM = 'medium';
export const SIZE_LARGE = 'large';

export default {
  name: 'AppButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    hasWhiteBorder: {
      type: Boolean,
      required: false,
      default: true,
    },
    color: {
      type: String,
      default: COLOR_DARK_BLUE,
      required: false,
    },
    size: {
      type: String,
      default: SIZE_MEDIUM,
      required: false,
    },
    activeState: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['click'],
  mounted() {
  },
  computed: {
    buttonClasses() {
      const buttonClasses = [
        `app-button-color-${this.color || COLOR_DARK_BLUE}`,
        `app-button-size-${this.size || SIZE_MEDIUM}`,
      ];

      if (this.disabled) {
        buttonClasses.push('app-button-disabled');
      }

      if (this.activeState) {
        buttonClasses.push('app-button-active-state');
      }


      return buttonClasses;
    },
  },
  methods: {
    onClickButton() {
      if (this.disabled) {
        return;
      }

      if (this.activeState) {
        return;
      }

      this.$root.playSound('simpleClick');
      this.$emit('click');
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/variables.scss";
$buttonHeight: 38px;
$borderRadius: 30px;
$borderRadiusMedium: 50px;

.app-button {
  text-transform: uppercase;
  height: $buttonHeight;
  line-height: 100%; // $buttonHeight does not look correct on firefox android
  cursor: pointer;
  outline: 0;
  font-weight: 700;
  min-width: 70px;
  max-width: 100%;
  margin: 0 10px;

  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;

  width: 100%;
  text-align: center;

  position: relative;
  padding: 10px 15px;

  &.app-button-color-yellow {
    text-shadow: 1px 1px 2px #00000060, 0 0 1em #00000060, 0 0 0.2em #00000060;
    background-color: $colorYellow !important;
    box-shadow: 0px -5px 0px 0px #00000066 inset, 0.3px 3px 1.55px 0px #FFFFFFB8 inset, 0px 2.8px 5.61px 2.1px #000000;
  }

  &.app-button-color-light-green {
    text-shadow: 1px 1px 2px #00000060, 0 0 1em #00000060, 0 0 0.2em #00000060;
    background-color: $colorLightGreen !important;
    box-shadow: 0px -5px 0px 0px #00000066 inset, 0.3px 3px 1.55px 0px #FFFFFFB8 inset, 0px 2.8px 5.61px 2.1px #000000;
  }

  &.app-button-color-green {
    text-shadow: 1px 1px 2px #00000060, 0 0 1em #00000060, 0 0 0.2em #00000060;
    background-color: $colorGreen !important;
    box-shadow: 0px -5px 0px 0px #00000066 inset, 0.3px 3px 1.55px 0px #FFFFFFB8 inset, 0px 2.8px 5.61px 2.1px #000000;
  }

  &.app-button-color-peach {
    text-shadow: 1px 1px 2px #00000060, 0 0 1em #00000060, 0 0 0.2em #00000060;
    background-color: $colorPeach !important;
    box-shadow: 0px -5px 0px 0px #00000066 inset, 0.3px 3px 1.55px 0px #FFFFFFB8 inset, 0px 2.8px 5.61px 2.1px #000000;
  }

  &.app-button-color-pink {
    text-shadow: 1px 1px 2px #00000060, 0 0 1em #00000060, 0 0 0.2em #00000060;
    background-color: $colorPink !important;
    box-shadow: 0px -5px 0px 0px #00000066 inset, 0.3px 3px 1.55px 0px #FFFFFFB8 inset, 0px 2.8px 5.61px 2.1px #000000;
  }

  &.app-button-color-red {
    text-shadow: 1px 1px 2px #00000060, 0 0 1em #00000060, 0 0 0.2em #00000060;
    background-color: $colorRed !important;
    box-shadow: 0px -5px 0px 0px #00000066 inset, 0.3px 3px 1.55px 0px #FFFFFFB8 inset, 0px 2.8px 5.61px 2.1px #000000;
  }

  &.app-button-active-state {

    background-color: #637488 !important;
    box-shadow: 0.58px 9px 2.54px 0 #0000004D inset !important;

  }

  &:hover {
      transform: scale(1.02);
  }

  &:active {
      transform: scale(1.04);
  }

  &.app-button-disabled {
    opacity: .8;
    cursor: not-allowed;
    box-shadow: none;

    .background {
      display: none;
    }
  }

  transition: bottom 800ms cubic-bezier(0.860, 0.000, 0.070, 1.000),
    height 800ms cubic-bezier(0.860, 0.000, 0.070, 1.000),
    color 200ms ease-in-out,
    background-color 200ms ease-in-out,
    box-shadow 200ms ease-in-out;

  // Sizes
  &.app-button-size-small {
    width: 180px;
    min-width: 150px;
    font-size: 0.9rem;
    height: 46px;
    border-radius: 6px !important;
    .content {
      padding: 14px 10px;
    }
  }

  &.app-button-size-medium {
    width: 330px;
    max-width: 100%;
    font-size: 1.5rem;
    height: 52px;
    .content {
      padding: 12px 10px;
    }
  }

  &.app-button-size-large {
    width: 330px;
    font-size: 1.5rem;
    height: 60px;
    .content {
      padding: 16px 10px;
    }
  }

  &.app-button-size-block {
    font-size: 12pt;
    width: 100% !important;
    max-width: auto !important;
  }

  > span {
    display: block;
    width: 100%;
    height: 100%;
  }

  .content {
    width: 100%;
    height: 100%;
    font-weight: 800;

  }

  .content, .background {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: $borderRadius;
  }

}
</style>
