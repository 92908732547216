<template>
  <div
    class="money-wrapper d-flex justify-content-center"
  >
    <no-drag-image src="@/assets/ui/icon_co2_cloud.svg" />
    {{co2Points}}
  </div>
</template>
<script>

import NoDragImage from "@/components/common/NoDragImage";
import {mapState} from "vuex";
export default {
  name: 'Co2PointView',
  components: {NoDragImage},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState ([
        'co2Points'
    ])
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/variables.scss";

.money-wrapper {
  width: 240px;
  height: 65px;
  cursor: pointer;
  background-color: $colorUiDarkBlue;
  color: #fff;
  font-weight: 700;
  border-radius: 78px;
  font-size: 45px;
  padding: 11px 0 0 0;
  line-height: 0.9;
  pointer-events: none;
  box-shadow: 0 4px 8px rgba(60, 70, 82, 0.7);

  img {
    height: 80%;
    margin-right: 10px;
  }
}
</style>
