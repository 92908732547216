<template>
  <div class="action-box-content-end-round">
    <div class="d-flex metric-wrapper" v-for="dataItem in endRoundData">

      <div class="metric-details blue-block d-flex justify-content-between">
        <div class="d-flex">
          <no-drag-image :src="getImgSrc(dataItem.icon)"/>
          <div v-html="dataItem.title"/>
        </div>
        <div class="metric-value" :class="getValueClass(pointData?.[dataItem.key])">
          {{pointData?.[dataItem.key].value}}
        </div>

      </div>
      <div class="metric-points blue-block">
        {{pointData?.[dataItem.key].points > 0 ? '+' : ''}}{{ pointData?.[dataItem.key].points }}
      </div>
    </div>

    <div class="point-total blue-block d-flex justify-content-between">
      <div>Runde {{ roundNumber }} point</div>
      <div class="points" :class="roundPoints >= 0 ? 'green-block' : 'red-block'">
        {{ roundPoints }}
      </div>
    </div>

    <div class="star-wrapper blue-block d-flex justify-content-center">
      <div class="mx-2" v-for="number in numberOfStars" :key="number">
        <no-drag-image :src="getStarImgSrc(number <= stars)" />
      </div>
    </div>
  </div>
</template>
<script>

import NoDragImage from "@/components/common/NoDragImage";
import pointData from "@/data/PointData";
import {mapState} from "vuex";

export default {
  name: 'ActionBoxContentEndRound',
  components: {NoDragImage},
  props: {
    pointData: {
      type: Object,
      required: true,
    },
    roundNumber: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      numberOfStars: pointData.numberOfStars,
      endRoundData: [
        {
          title: 'CO<sub>2</sub> Farve',
          icon: 'co2_white',
          key: 'co2Color'
        },
        {
          title: 'CO<sub>2</sub> Værdi',
          icon: 'co2_white',
          key: 'co2'
        },
        {
          title: 'Tid',
          icon: 'time',
          key: 'time'
        },
        {
          title: 'Budget',
          icon: 'coin_white',
          key: 'money'
        },
        {
          title: 'Manglende udskiftninger',
          icon: 'tools',
          key: 'missingRenovations'
        },
        {
          title: 'Resterende affald',
          icon: 'trash',
          key: 'trash'
        },
      ],
    }
  },
  computed: {
    ...mapState([
        'levelDataIds',
        'currentRoundIndex'
    ]),
    roundPoints() {
      return this.pointData?.roundPoints || {};
    },
    stars() {
      return this.pointData.starsEarned;
    }
  },
  methods: {
    getImgSrc(iconName) {
      const requireImage = require.context('@/assets/ui', true, /\.svg$/);
      const imagePath = `./icon_${iconName}.svg`;
      return requireImage(imagePath);
    },
    getStarImgSrc(filled) {
      const imageName = filled ? 'star_filled' : 'star';
      const requireImage = require.context('@/assets/ui', true, /\.png$/);
      const imagePath = `./${imageName}.png`;
      return requireImage(imagePath);
    },
    getValueClass(value) {
      const valueClass = [];

      const points = value.points;
      const labelValue = value.value;

      if (labelValue === 'Grøn') {
        valueClass.push('green-block');
      } else if (labelValue === 'Gul') {
        valueClass.push('yellow-block');
      } else if (labelValue === 'Rød') {
        valueClass.push('red-block');
      } else if (points >= 0) {
        valueClass.push('green-block');
      } else {
        valueClass.push('red-block');
      }

      return valueClass;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/variables.scss";

$textPadding: 24px;
$borderRadius: 4px;

.blue-block {
  padding: 3px 10px;
  background-color: $colorUiDarkBlue;
  border-radius: $borderRadius;
}

.point-total {
  font-size: 32px;
  font-weight: 700;
  width: 95%;
  margin: 11px auto 15px auto;

  .points {
    width: 28%;
    color: $colorUiDarkBlue;
    border-radius: $borderRadius;
    margin: 2px 0;
    line-height: 0.9;
    padding-top: 9px;
  }
}

.star-wrapper {
  width: 95%;
  height: 80px;
  padding: 15px;
  margin: 0 auto 40px auto;
}


.green-block {
  background-color: $colorUiGreen;
}

.yellow-block {
  background-color: $colorBrightYellow;
}

.red-block {
  background-color: $colorUiRed;
}

.metric-wrapper {
  width: 100%;
  padding: 5px 2.5%;
  font-size: 18px;
  font-weight: 700;

  .metric-details {
    width: 120%;

    .metric-value {
      width: 15%;
      color: $colorUiDarkBlue;
      border-radius: $borderRadius;
      margin: 2px 0;
      line-height: 0.9;
      padding-top: 3px;

    }

    img {
      height: 20px;
      width: 40px;
      padding-right: 10px;
      margin-top: 3px;
    }
  }

  .metric-points {
    min-width: 210px;
    width: 25%;
    margin-left: 9px;
  }
}

.action-box {
  border-radius: $globalBorderRadius;
  background-color: $colorUiBlue;
  color: white;

  .action-box-title-wrapper {
    background-image: linear-gradient($colorUiBlue, $colorUiDarkBlue);
    margin-bottom: 5px;
    font-size: 22px;
    padding: 6px $textPadding;
    border-radius: $globalBorderRadius $globalBorderRadius 0 0;
    text-transform: uppercase;
    font-weight: 800;
  }

  .action-box-content {
    padding: 6px;
  }

}
</style>
