<template>
  <div
      :class="['accessory-wrapper', wobbleClass, { hidden: isHidden }]"
      @animationstart="onAnimationStart"
      @animationend="onAnimationEnd"
      :style="wobbleClass ? { '--animation-delay': animationDelay + 'ms' } : {}"
  >
    <no-drag-image :src="imgSrc" />
  </div>
</template>

<script>
import NoDragImage from "@/components/common/NoDragImage";
import { STATE_HIDDEN, STATE_NEW, STATE_OLD } from "@/enum/HouseState";
import {mapState} from "vuex";

export default {
  name: "Accessory",
  components: { NoDragImage },
  props: {
    imageName: {
      type: String,
      default: "",
    },
    accessoryState: {
      type: String,
      default: "",
    },
    animationDelay: {
      type: Number,
      default: 0,
    },
    accessoryRound: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      wobbleClass: "",
      currentAccessoryState: this.accessoryState,
    };
  },
  computed: {
    ...mapState([
       'currentRoundIndex'
    ]),
    isHidden() {
      return this.currentAccessoryState === STATE_HIDDEN;
    },
    imgSrc() {
      let fullImageName = this.imageName;

      if (
          this.currentAccessoryState === STATE_NEW ||
          this.currentAccessoryState === STATE_OLD
      ) {
        const parsedState =
            this.currentAccessoryState === STATE_NEW ? "new" : "old";
        fullImageName += `_${parsedState}`;
      }

      const requireImage = require.context(
          "@/assets/house/accessories",
          true,
          /\.png$/
      );
      const imagePath = `./${fullImageName}.png`;
      return requireImage(imagePath);
    },
  },
  watch: {
    accessoryState(newVal, oldVal) {
      if (newVal !== oldVal && this.currentRoundIndex <= this.accessoryRound) {
        if (newVal === STATE_HIDDEN) {
          this.currentAccessoryState = STATE_HIDDEN;
          this.wobbleClass = "";
        } else {
          this.triggerWobble();
        }
      }
    },
  },
  methods: {
    triggerWobble() {
      this.wobbleClass = "";

      this.$nextTick(() => {
        this.wobbleClass = "jello-vertical";
      });
    },
    onAnimationStart() {
      this.currentAccessoryState = this.accessoryState;
    },
    onAnimationEnd() {
      this.wobbleClass = "";
    },
  },
};
</script>


<style lang="scss" scoped>
.accessory-wrapper {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  &.jello-vertical {
    animation: jello-vertical 1s ease forwards;
    animation-delay: var(--animation-delay, 0ms)
  }
}
</style>
