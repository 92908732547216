import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import DevView from '@/views/000-DevView';

import HomeView from '../views/010-HomeView.vue'
import GameView from '../views/020-GameView.vue'
import HighScoreView from '../views/030-HighScoreView.vue'
import {ROUTER_NAME_GAME, ROUTER_NAME_HIGHSCORE, ROUTER_NAME_HOME} from "@/enum/RouterViewNames";

const routes = [
  {
    path: '/',
    name: ROUTER_NAME_HOME,
    component: HomeView,
  },
  {
    path: '/game',
    name: ROUTER_NAME_GAME,
    component: GameView,
  },
  {
    path: '/highscore',
    name: ROUTER_NAME_HIGHSCORE,
    component: HighScoreView,
  },
]

const isDev = parseInt(process.env.VUE_APP_IS_DEV, 10) > 0;

if (isDev) {
  routes.push({
    path: '/dev',
    name: 'DevMain',
    component: DevView,
    children: [
      {
        path: ':component',
        name: 'DevSub',
        component: DevView,
      },
    ],
  });
}

const router = createRouter({
  history: createWebHashHistory(),
  // history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
