import axios from 'axios';
import {getConsole, getMockConsole} from '@/utils/getConsole';

export default class {
  errorHandler;

  console;

  http;

  lastResponse;

  constructor(baseUrl = null, useDebugConsole = false) {
    this.errorHandler = null;

    this.setUseDebugConsole(useDebugConsole);

    this.http = axios.create({
      timeout: 15000,
      baseURL: baseUrl || process.env.VUE_APP_BASE_API_URL,
      validateStatus: false,
    });

    this.lastResponse = null;

    const mixins = this.mixins();

    for (const mixinGroup in mixins) {
      if (Object.prototype.hasOwnProperty.call(mixins, mixinGroup)) {
        const mixin = mixins[mixinGroup];

        if (typeof (mixin) === 'function') {
          // It's a standalone call without a group
          this[mixinGroup] = mixin.bind(this);
        } else {
          // mixin is actually a group of mixins, let's merge the entire group¨¨
          const groupMixins = { ...this[mixinGroup], ...mixin };

          this[mixinGroup] = {};

          for (const name in groupMixins) {
            if (Object.prototype.hasOwnProperty.call(groupMixins, name)) {
              this[mixinGroup][name] = groupMixins[name].bind(this);
            }
          }
        }
      }
    }
  }

  setUseDebugConsole(useDebugConsole, introMessage = null, textColor = 'white', backgroundColor = '#0000aa') {
    if (useDebugConsole) {
      this.console = getConsole('ApiClient', textColor, backgroundColor);

      if (introMessage) {
        this.console.log(introMessage);
      }
    } else {
      this.console = getMockConsole();
    }
  }

  setBaseUrl(baseUrl) {
    this.http.baseURL = baseUrl;
  }

  // eslint-disable-next-line class-methods-use-this
  mixins() {
    return [];
  }

  addQueryParams(url, queryParams, allowEmptyValues = false) {
    const urlParts = [];
    const queryParamNames = Object.keys(queryParams);

    for (const queryParam of queryParamNames) {
      let queryValue = queryParams[queryParam];

      if (queryValue === true) {
        queryValue = 1;
      } else if (queryValue === false) {
        queryValue = 0;
      }

      const queryParamEncoded = encodeURIComponent(queryParam);

      if (queryValue === null) {
        if (!allowEmptyValues) {
          continue;
        }

        urlParts.push(`${queryParamEncoded}`);
      } else {
        const queryValueEncoded = encodeURIComponent(queryValue);

        urlParts.push(`${queryParamEncoded}=${queryValueEncoded}`);
      }
    }

    // If there's no question mark in the URL, then add a questionmark, otherwise use an ampersand
    const urlGlue = url.indexOf('?') < 0 ? '?' : '&';

    return `${url}${urlGlue}${urlParts.join('&')}`;
  }

  getFullUrl(affix) {
    const baseUrl = this.http.defaults.baseURL;

    if (!affix) {
      return baseUrl;
    }

    // Make sure there's only a single slash
    return `${baseUrl.replace(/\/+$/, '')}/${affix.replace(/^\/+/, '')}`;
  }

  get(endPoint) {
    return this.request('get', endPoint, {});
  }

  post(endPoint, data, config = {}) {
    return this.request('post', endPoint, data, config);
  }

  put(endPoint, data) {
    return this.request('put', endPoint, data);
  }

  delete(endPoint, data) {
    return this.request('delete', endPoint, data);
  }

  request(type, endPoint, data, config = {}) {
    const typePrint = type.toUpperCase();

    this.console.log(`${typePrint} ${endPoint}`);

    return this.http[type](endPoint, data, config).then((response) => {
      this.lastResponse = response;

      const responseKeys = response ? Object.keys(response) : [];

      if (response && responseKeys.includes('data')) {
        const responseData = response.data;

        const responseDataKeys = responseData ? Object.keys(responseData) : [];

        let httpCode = 200;

        if (responseData) {
          if (responseDataKeys.includes('httpCode')) {
            httpCode = responseData.httpCode;
          } else if (responseDataKeys.includes('statusCode')) {
            httpCode = responseData.statusCode;
          }
        }

        if (httpCode === 200) {
          this.console.log(`${typePrint} ${endPoint} | SUCCESS`);

          console.log('responseData', responseData);

          if (responseDataKeys.includes('data')) {
            return responseData.data;
          } else if (responseData) {
            return responseData;
          }

          return null;
        }

        let error = 'Unknown';

        if (responseDataKeys.includes('error')) {
          if (typeof responseData.error === 'string') {
            error = responseData.error;
          } else {
            const responseDataErrorKeys = Object.keys(responseData.error);

            if (responseDataErrorKeys.includes('message')) {
              error = responseData.error.message;
            }
          }
        }

        this.console.error(`${typePrint} ${endPoint} | ERROR | ${error}`);

        return Promise.reject(new Error(error));
      }

      this.console.error(`${typePrint} ${endPoint} | ERROR | Could not find any data in response`);

      return Promise.reject(new Error('Could not find any data in response'));
    }).catch((error) => {
      if (error.response) {
        this.lastResponse = error.response;
      } else {
        this.lastResponse = null;
      }

      this.console.error(`${typePrint} ${endPoint} | ERROR | Could not execute request: ${error}`);

      if (this.errorHandler) {
        this.errorHandler(error);
      }

      throw error;
    });
  }

  setBearerToken(token) {
    this.setDefaultHeader('Authorization', token ? `Bearer ${btoa(token)}` : null);
  }

  setDefaultHeader(header, value) {
    if (value) {
      this.console.log(`Set default header "${header}" to value "${value}"`);

      this.http.defaults.headers.common[header] = value;
    } else {
      this.console.log(`Removed default header "${header}"`);

      delete this.http.defaults.headers.common[header];
    }
  }

  setErrorHandler(errorHandler) {
    this.errorHandler = errorHandler;
  }
}
