<template>
  <div class="error-box-container" :class="dismissing ? 'flip-out-hor-top' : ''">
    <div class="error-box">
      <button class="btn-dismiss" @click.prevent.stop="onClickDismiss">&times;</button>

      <div class="error-box-content">
        <strong>FEJL:</strong><br />
        <br />
        <pre class="error-message">{{ message }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'error-box',
  props: {
    message: {
      type: String,
    },
  },
  watch: {
    message() {
      this.dismissing = false;
    },
  },
  emits: ['dismiss'],
  data() {
    return {
      dismissing: false,
    };
  },
  methods: {
    onClickDismiss() {
      this.dismissing = true;

      console.log('dismissing..');

      setTimeout(() => {
        this.$emit('dismiss');
      }, 500);
    }
  }
}
</script>

<style lang="scss" scoped>
.error-box-container {
  max-width: 100%;
  overflow: hidden;

  &.error-box-container-dismissing {
    .error-box {
      margin-left: 100px;
    }
  }
}

.error-box {
  background: #A44200;
  border-radius: 5px;
  padding: 3px 14px;
  position: relative;
  z-index: 1;
  display: inline-block;
  margin: 0 auto;
  min-width: 400px;
  color: #ffffff;
  transition: all 500ms ease-in-out;

  strong {
    font-size: 18pt;
    text-align: center;
  }

  pre {
    font-size: 14pt;
    font-family: Consolas, monaco, monospace;
    white-space: pre;
  }

  .btn-dismiss {
    position: absolute;
    top: 0px;
    right: 0px;
    border: 0;
    background: none;
    color: #ffffff;
    font-size: 20pt;
    padding: 0;

    &:hover {
      color: darken(#ffffff, 15%);
    }

    &:focus {
      border: 0;
    }
  }
}
</style>
